import { Alert } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { useFullName } from '../../../../hooks/useUsers';
import { CaseType } from '../../../../utils/constants';
import ReportExcel, { IReportExcelRow } from '../../../../utils/exports/ReportExcel';
import getFormat from '../../../../utils/Lang';
import { FullUserProps } from '../../../../utils/objects/withFullName';
import { EventForProject } from '../../../../utils/types/planningTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { formatProjectTitle, getCaseAndPlural, multiLevelGroupBy, roundDecimals } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import { AbstractReportsGrouped, AbstractReportsGroupedProps } from './abstractReportsGrouped';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps, AbstractReportsGroupedProps, FullUserProps {
}

interface State {
    pagination: {
        currentPage: number;
        pageSize: number;
    }
}

class ReportsGroupedByProjectAndUserCore extends AbstractReportsGrouped<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            pagination: {
                currentPage: 1,
                pageSize: 8,
            }
        };
    }

    handleTableChange = (page: number, pageSize: number) => this.setState({ pagination: { currentPage: page, pageSize } });

    downloadProjectEvents = async () => {
        const { eventsForProjects } = this.props;

        if (eventsForProjects) {
            const { intl, company, getFullName, displayDetails, fromDate, toDate } = this.props;
            const sortedEventsForProjects = this.sortData(eventsForProjects, [this.compareProject, this.compareUser, this.compareDate], getFullName);
            const parsedData = this.parseData(sortedEventsForProjects, 0, eventsForProjects.length);
            const projectName = company?.projectDisplayText ? getCaseAndPlural(company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : intl.formatMessage({ defaultMessage: 'Project' });

            //! Take care to update the columns in the antd table
            let columns: IReportExcelRow<EventForProjectByProjectByUser> = [
                {
                    name: projectName,
                    width: displayDetails ? 25 : 40,
                    key: 'projectTitle',
                    filterButton: true,
                    totalsRowLabel: intl.formatMessage({ defaultMessage: 'Totals' }),
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => formatProjectTitle(e.projectTitle, e.projectCustomId),
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'User' }),
                    width: displayDetails ? 40 : 50,
                    key: 'userFullName',
                    filterButton: true,
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => getFullName({ id: e.userId, first_name: e.userFirstName ?? '', last_name: e.userLastName ?? '', code: e.userCode })
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Event' }),
                    width: 25,
                    key: 'title',
                    filterButton: true,
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => e.title,
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Date' }),
                    width: 13,
                    key: 'startDate',
                    filterButton: true,
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => {
                        const c = e.startDate;
                        return c && moment.utc(c).format(getFormat('DATE'));
                    },
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Start' }),
                    width: 8,
                    key: 'startTime',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => moment.utc(e.startDate).format(getFormat('TIME_SHORT'))
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'End' }),
                    width: 8,
                    key: 'endTime',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => moment.utc(e.endDate).format(getFormat('TIME_SHORT'))
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Paid breaks' }),
                    width: 8,
                    key: 'paidBreaktimeSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.paidBreaktimeSec) / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Unpaid breaks' }),
                    width: 12,
                    key: 'unpaidBreaktimeSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.unpaidBreaktimeSec) / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Over' }),
                    width: 8,
                    key: 'overtimes',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.overtimeSec) / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Effectives' }),
                    width: 10,
                    key: 'effectiveSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals(e.effectiveSec / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Increased hours' }),
                    width: 11,
                    key: 'increasedHoursSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals(e.increasedHoursSec / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Increased vacation' }),
                    width: 11,
                    key: 'increasedVacationSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals(e.increasedVacationSec / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Effectives (increased)' }),
                    width: 13,
                    key: 'effectiveWithIncreasedSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals(e.effectiveWithIncreasedSec / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Comment' }),
                    width: 40,
                    key: 'userNote',
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => e.userNote
                },
                {
                    name: `${intl.formatMessage({ defaultMessage: 'Totals' })} ${intl.formatMessage({ defaultMessage: 'User' })}`,
                    width: 14,
                    key: 'userEffectiveSeconds',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.userEffectiveSeconds ?? 0) / 3600)
                },
                {
                    name: `${intl.formatMessage({ defaultMessage: 'Totals' })} ${projectName}`,
                    width: 12,
                    key: 'projectEffectiveSeconds',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.projectEffectiveSeconds ?? 0) / 3600)
                },
                {
                    name: `${intl.formatMessage({ defaultMessage: 'Totals (increased)' })} ${intl.formatMessage({ defaultMessage: 'User' })}`,
                    width: 14,
                    key: 'userEffectiveWithIncreasedSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.userEffectiveWithIncreasedSec ?? 0) / 3600)
                },
                {
                    name: `${intl.formatMessage({ defaultMessage: 'Totals (increased)' })} ${projectName}`,
                    width: 14,
                    key: 'projectEffectiveSecondsWithIncreasedSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.projectEffectiveSecondsWithIncreasedSec ?? 0) / 3600)
                },
            ];

            const mergeRows: { col: number, row: number, nbRows: number }[] = [];
            if (!displayDetails) {
                columns = columns.filter(c => this.removeDetails(c.key));
            }

            parsedData?.forEach((d, idx) => {
                if (d.projectIdRowSpan && d.projectIdRowSpan > 0) {
                    mergeRows.push({ col: 1, row: idx, nbRows: d.projectIdRowSpan });
                    mergeRows.push({ col: columns.length - 2, row: idx, nbRows: d.projectIdRowSpan });
                    mergeRows.push({ col: columns.length, row: idx, nbRows: d.projectIdRowSpan });
                }
                if (d.userIdRowSpan && d.userIdRowSpan > 0) {
                    mergeRows.push({ col: 2, row: idx, nbRows: d.userIdRowSpan });
                    mergeRows.push({ col: columns.length - 3, row: idx, nbRows: d.userIdRowSpan });
                    mergeRows.push({ col: columns.length - 1, row: idx, nbRows: d.userIdRowSpan });
                }
            })

            const details = `${intl.formatMessage({ defaultMessage: 'From {start} to {end}' }, { start: moment(fromDate).format(getFormat("DATE")), end: moment(toDate).format(getFormat("DATE")) })}`;
            const generator = new ReportExcel(`${projectName}`, columns.length, intl, company);
            const buffer = await generator.generateExcel(intl.formatMessage({ defaultMessage: "Report of {project}" }, { project: projectName }), columns, parsedData ?? [], company?.logo, details, mergeRows);

            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            const documentName = `${intl.formatMessage({ defaultMessage: "Report of {project}" }, { project: projectName })}`;
            a.href = url;
            a.download = `${documentName.toLocaleLowerCase().replaceAll(" ", "-")}.xlsx`;
            a.click();
        }
    }

    parseData = (data: EventForProject[] | undefined, startIndex?: number, endIndex?: number) => {
        const indexes = multiLevelGroupBy<EventForProject, 2>(data ?? [], [
            (e) => `pId-${e.projectId?.toString()}`,
            (e) => `uId-${e.userId?.toString()}`,
        ]);

        return flattenGroupedDataWithIDs(indexes, startIndex, endIndex);
    }

    getColumns = () => {
        const { displayDetails } = this.props;

        let columns = this.eventsColumnsByProjectByUser;
        let paginationTotal = (elem1: number, elem2: number, total: number) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} events'} values={{ range0: elem1, range1: elem2, total }} />
        if (!displayDetails) {
            columns = columns.filter(c => this.removeDetails(c.key));
            paginationTotal = (elem1: number, elem2: number, total: number) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total}'} values={{ range0: elem1, range1: elem2, total }} />
        }

        return { columns, paginationTotal };
    }

    render() {
        const { height, eventsForProjects } = this.props;
        const { pagination } = this.state;
        const { currentPage } = pagination;

        const pageSize = Math.round((height - 175) / 50);

        const totalPaidBreaktimesSeconds = eventsForProjects?.reduce((prevValue, event) => prevValue + event.paidBreaktimeSec, 0.0);
        const totalUnpaidBreaktimesSeconds = eventsForProjects?.reduce((prevValue, event) => prevValue + event.unpaidBreaktimeSec, 0.0);
        const totalOvertimesSeconds = eventsForProjects?.reduce((prevValue, event) => prevValue + event.overtimeSec, 0.0);
        const totalEffectiveSeconds = eventsForProjects?.reduce((prevValue, event) => prevValue + event.effectiveSec, 0.0);
        const totalIncreasedHoursSec = eventsForProjects?.reduce((prevValue, event) => prevValue + event.increasedHoursSec, 0.0);
        const totalIncreasedVacationSec = eventsForProjects?.reduce((prevValue, event) => prevValue + event.increasedVacationSec, 0.0);
        const totalEffectiveWithIncreasedSec = eventsForProjects?.reduce((prevValue, event) => prevValue + event.effectiveWithIncreasedSec, 0.0);

        const totalPaidBreaktimesHours = totalPaidBreaktimesSeconds ? roundDecimals(totalPaidBreaktimesSeconds / 3600) : 0;
        const totalNotPaidBreaktimesHours = totalUnpaidBreaktimesSeconds ? roundDecimals(totalUnpaidBreaktimesSeconds / 3600) : 0;
        const totalOvertimesHours = totalOvertimesSeconds ? roundDecimals(totalOvertimesSeconds / 3600) : 0;
        const totalEffectiveHours = totalEffectiveSeconds ? roundDecimals(totalEffectiveSeconds / 3600) : 0;
        const totalIncreasedHoursHours = totalIncreasedHoursSec ? roundDecimals(totalIncreasedHoursSec / 3600) : 0;
        const totalIncreasedVacationHours = totalIncreasedVacationSec ? roundDecimals(totalIncreasedVacationSec / 3600) : 0;
        const totalEffectiveWithIncreasedHours = totalEffectiveWithIncreasedSec ? roundDecimals(totalEffectiveWithIncreasedSec / 3600) : 0;

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const sortedEventsForProjects = this.sortData(eventsForProjects, [this.compareProject, this.compareUser, this.compareDate], this.props.getFullName);
        const tableData = eventsForProjects ? this.parseData(sortedEventsForProjects, startIndex, endIndex) : null;

        const { columns, paginationTotal } = this.getColumns();

        return (
            <>
                {
                    tableData ?
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                            <Alert type='info'
                                message={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                                        <div>
                                            <span><i><FormattedMessage defaultMessage={'Unpaid breaks'} />{':'}</i> {totalNotPaidBreaktimesHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Paid breaks'} />{':'} {totalPaidBreaktimesHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Overtimes'} />{':'}  {totalOvertimesHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><b><FormattedMessage defaultMessage={'Effective hours'} />{':'} </b> {totalEffectiveHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Increased hours'} />{':'} {totalIncreasedHoursHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Increased vacation'} />{':'} {totalIncreasedVacationHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><b><FormattedMessage defaultMessage={'Totals'} />{':'} </b> {totalEffectiveWithIncreasedHours.toFixed(2)}</span>
                                        </div>
                                    </div>
                                }
                            />
                            <Table
                                bordered
                                columns={columns}
                                dataSource={tableData ?? []}
                                scroll={{ x: true }}
                                rowKey={(record: EventForProjectByProjectByUser) => `byprojectbyuser-${record.id}-${record.projectId}-${record.userId}-${record.startDate}`}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: eventsForProjects?.length,
                                    showSizeChanger: false,
                                    onChange: this.handleTableChange,
                                    responsive: true,
                                    size: "small",
                                    showTotal: (total, range) => paginationTotal(range[0], range[1], total)
                                }}
                            />
                        </div>
                        : <p>Problem data</p>
                }
            </>
        );
    }

    eventsColumnsByProjectByUser: ColumnsType<EventForProjectByProjectByUser> = [
        {
            title: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />,
            key: 'projectTitle',
            dataIndex: 'projectTitle',
            className: '__width_250 __ellipsis-text',
            onCell: (record) => ({
                rowSpan: record.projectIdRowSpan,
            }),
            render: (projectTitle: string, record: EventForProjectByProjectByUser) => {
                const formatedProjectTitle = formatProjectTitle(projectTitle, record.projectCustomId);
                return <span title={formatedProjectTitle}>{formatedProjectTitle}</span>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Employee'} />,
            key: 'userFullName',
            className: '__min-width-250 __ellipsis-text',
            onCell: (record) => ({
                rowSpan: record.userIdRowSpan,
            }),
            render: (value: EventForProjectByProjectByUser) => {
                const fullName = this.props.getFullName({ id: value.userId, first_name: value.userFirstName ?? '', last_name: value.userLastName ?? '', code: value.userCode });
                return <span title={fullName}>{fullName}</span>
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Event'} />,
            key: 'title',
            dataIndex: 'title',
            className: '__width_250 __ __ellipsis-text',
            render: (title: string) => <span title={title}>{title}</span>
        },
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'startDate',
            dataIndex: 'startDate',
            className: '__width_110',
            render: (startDate: string) => moment.utc(startDate).format(getFormat('DATE'))
        },
        {
            title: <FormattedMessage defaultMessage={'Start'} />,
            key: 'startTime',
            dataIndex: 'startDate',
            className: '__width_70 __centered-text',
            render: (startDate: string) => moment.utc(startDate).format(getFormat('TIME_SHORT'))
        },
        {
            title: <FormattedMessage defaultMessage={'End'} />,
            key: 'endTime',
            dataIndex: 'endDate',
            className: '__width_70 __centered-text',
            render: (endDate: string) => moment.utc(endDate).format(getFormat('TIME_SHORT'))
        },
        {
            title: <FormattedMessage defaultMessage={'Paid breaks'} />,
            key: 'paidBreaktimeSec',
            dataIndex: 'paidBreaktimeSec',
            className: '__width_80 __centered-text',
            render: (paidBreaktimeSec: number) => roundDecimals(paidBreaktimeSec / 3600).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Unpaid breaks'} />,
            key: 'unpaidBreaktimeSec',
            dataIndex: 'unpaidBreaktimeSec',
            className: '__width_100 __centered-text',
            render: (unpaidBreaktimeSec: number) => roundDecimals(unpaidBreaktimeSec / 3600).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Over'} />,
            key: 'overtimes',
            dataIndex: 'overtimeSec',
            className: '__width_70 __centered-text',
            render: (overtimeSec: number) => roundDecimals(overtimeSec / 3600).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Effectives'} />,
            key: 'effectiveSec',
            dataIndex: 'effectiveSec',
            className: '__width_90 __centered-text',
            render: (effectiveSec: number) => roundDecimals(effectiveSec / 3600).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Increased hours'} />,
            key: 'increasedHoursSec',
            className: '__width_100 __centered-text',
            dataIndex: 'increasedHoursSec',
            render: (increasedHoursSec: number) => roundDecimals((increasedHoursSec / 3600)).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Increased vacation'} />,
            key: 'increasedVacationSec',
            className: '__width_100 __centered-text',
            dataIndex: 'increasedVacationSec',
            render: (increasedVacationSec: number) => roundDecimals((increasedVacationSec / 3600)).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Effectives (increased)'} />,
            key: 'effectiveWithIncreasedSec',
            className: '__width_120 __centered-text',
            dataIndex: 'effectiveWithIncreasedSec',
            render: (effectiveWithIncreasedSec: number) => roundDecimals((effectiveWithIncreasedSec / 3600)).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Comment'} />,
            key: 'userNote',
            dataIndex: 'userNote',
            className: '__min-width-220 __ellipsis-text',
            render: (userNote: string) => <span title={userNote}>{userNote}</span>
        },
        {
            title: <FormattedMessage defaultMessage={'Totals'} />,
            key: 'allEffectiveSeconds',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'User'} />,
                    key: 'userEffectiveSeconds',
                    className: '__width_100  __centered-text',
                    fixed: 'right',
                    onCell: (record) => ({
                        rowSpan: record.userIdRowSpan,
                    }),
                    render: (e: EventForProjectByProjectByUser) => {
                        const userEffectiveSeconds = e.userEffectiveSeconds ?? 0;
                        return roundDecimals(userEffectiveSeconds / 3600).toFixed(2);
                    }
                },
                {
                    title: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />,
                    key: 'projectEffectiveSeconds',
                    className: '__width_100 __centered-text',
                    fixed: 'right',
                    onCell: (record) => ({
                        rowSpan: record.projectIdRowSpan,
                    }),
                    render: (e: EventForProjectByProjectByUser) => {
                        const projectEffectiveSeconds = e.projectEffectiveSeconds ?? 0;
                        return roundDecimals(projectEffectiveSeconds / 3600).toFixed(2);
                    }
                },
            ]
        },
        {
            title: <FormattedMessage defaultMessage={'Totals (increased)'} />,
            key: 'allEffectiveSecondsWithIncreased',
            children: [
                {
                    title: <FormattedMessage defaultMessage={'User'} />,
                    key: 'userEffectiveWithIncreasedSec',
                    dataIndex: 'userEffectiveWithIncreasedSec',
                    className: '__width_100  __centered-text',
                    fixed: 'right',
                    onCell: (record) => ({
                        rowSpan: record.userIdRowSpan,
                    }),
                    render: (userEffectiveWithIncreasedSec: number | undefined) => userEffectiveWithIncreasedSec ? roundDecimals((userEffectiveWithIncreasedSec / 3600)).toFixed(2) : '0.00'
                },
                {
                    title: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />,
                    key: 'projectEffectiveSecondsWithIncreasedSec',
                    dataIndex: 'projectEffectiveSecondsWithIncreasedSec',
                    className: '__width_100 __centered-text',
                    fixed: 'right',
                    onCell: (record) => ({
                        rowSpan: record.projectIdRowSpan,
                    }),
                    render: (projectEffectiveSecondsWithIncreasedSec: number | undefined) => projectEffectiveSecondsWithIncreasedSec ? roundDecimals((projectEffectiveSecondsWithIncreasedSec / 3600)).toFixed(2) : '0.00'
                },
            ]
        }

    ];

}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    sidebarType: state.window.sidebarType,
});

const connector = connect(mapStateToProps, null, null, { forwardRef: true });
const ReportsGroupedByProjectAndUser = connector(ReportsGroupedByProjectAndUserCore);

const ForwardedReportsGroupedByProjectAndUser = forwardRef<ReportsGroupedByProjectAndUserCore, any>((props: Props, ref) => {
    const intl = useIntl();
    const fullName = useFullName();

    return <ReportsGroupedByProjectAndUser {...props} getFullName={(user) => fullName.getFullName(user)} ref={ref as React.Ref<ReportsGroupedByProjectAndUserCore>} intl={intl} />;
});
ForwardedReportsGroupedByProjectAndUser.displayName = "ForwardedReportsGroupedByProjectAndUser";
export default ForwardedReportsGroupedByProjectAndUser;

// #region All utils
// Fonction utilitaire pour extraire le texte d'un JSX ou d'un composant React
type EventForProjectByProjectByUser = EventForProject & {
    projectIdRowSpan?: number;
    projectEffectiveSeconds?: number;
    projectEffectiveSecondsWithIncreasedSec?: number;
    userIdRowSpan?: number;
    userEffectiveSeconds?: number;
    userEffectiveWithIncreasedSec?: number;
};

const flattenGroupedDataWithIDs = (groupedData: ReturnType<typeof multiLevelGroupBy<EventForProject, 2>>, startIndex?: number, endIndex?: number): EventForProjectByProjectByUser[] | null => {
    const rows: EventForProjectByProjectByUser[] = [];
    let calculated_index = 0;
    if (startIndex !== undefined && endIndex === undefined || (startIndex === undefined && endIndex !== undefined)) return null;
    for (const projectId in groupedData) {
        const userGroups = groupedData[projectId];

        const projectEffectiveSeconds = Object.values(userGroups).reduce((prevValue, event) => {
            return prevValue + event.reduce((prevValue, event) => prevValue + event.effectiveSec, 0.0);
        }, 0.0);
        const projectEffectiveSecondsWithIncreasedSec = Object.values(userGroups).reduce((prevValue, event) => {
            return prevValue + event.reduce((prevValue, event) => prevValue + event.effectiveWithIncreasedSec, 0.0);
        }, 0.0);

        let first_project_event_calculated_index = -1;

        for (const userId in userGroups) {
            const events = userGroups[userId];
            const userEffectiveSeconds = events.reduce((prevValue, event) => prevValue + event.effectiveSec, 0.0);
            const userEffectiveWithIncreasedSec = events.reduce((prevValue, event) => prevValue + event.effectiveWithIncreasedSec, 0.0);
            let first_user_event_calculated_index = -1;

            events.forEach((event) => {
                if ((startIndex === undefined || endIndex === undefined) || (calculated_index >= startIndex && calculated_index < endIndex)) {
                    rows.push({
                        ...event,
                        projectIdRowSpan: 0,
                        projectEffectiveSeconds: projectEffectiveSeconds,
                        projectEffectiveSecondsWithIncreasedSec: projectEffectiveSecondsWithIncreasedSec,
                        userIdRowSpan: 0,
                        userEffectiveSeconds: userEffectiveSeconds,
                        userEffectiveWithIncreasedSec: userEffectiveWithIncreasedSec
                    });
                    if (startIndex !== undefined && calculated_index >= startIndex && first_project_event_calculated_index === -1) first_project_event_calculated_index = rows.length - 1;
                    if (startIndex !== undefined && calculated_index >= startIndex && first_user_event_calculated_index === -1) first_user_event_calculated_index = rows.length - 1;
                }
                calculated_index++;
            });

            if (first_user_event_calculated_index > -1) {
                rows[first_user_event_calculated_index].userIdRowSpan = rows.length - first_user_event_calculated_index;
            }

        }
        if (first_project_event_calculated_index > -1) {
            rows[first_project_event_calculated_index].projectIdRowSpan = rows.length - first_project_event_calculated_index;
        }
    }

    return rows;
}
// #endregion