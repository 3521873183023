import moment from "moment";
import { IntlShape } from "react-intl";
import ReportExcel, { IReportExcelRow } from "../../../utils/exports/ReportExcel";
import getFormat from "../../../utils/Lang";
import { ECodePosition } from "../../../utils/types/constants";
import { Company, User } from "../../../utils/types/generalTypes";
import { NetworkDynamicFile } from "../../../utils/types/networkTypes";
import { getFullName } from "../../../utils/utils";
import { UserSmall } from "../../common/general/fullUser";

export function compareUser(a: UserSmall, b: UserSmall, getFullName: (user: UserSmall) => string) {
    const aFullName = getFullName(a);
    const bFullName = getFullName(b);
    const compareFullname = aFullName.localeCompare(bFullName, undefined, { numeric: true });
    return compareFullname;
}

export const exportUsersFiles = async (intl: IntlShape, users: User[], company?: Company) => {
    interface UserByFile {
        user: User,
        file?: NetworkDynamicFile;
    }

    const fileByUser = users.flatMap((u) =>
        u.files && u.files.length > 0 ?
            u.files.map((f) => ({ user: u, file: f as NetworkDynamicFile }))
            :
            [{ user: u }]
    );

    const columns: IReportExcelRow<UserByFile> = [
        {
            name: intl.formatMessage({ defaultMessage: 'User' }),
            width: 40,
            filterButton: true,
            alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
            render: (u) => getFullName(u.user, false, company?.codePosition || ECodePosition.HIDDEN, company?.userCodeDisplayAuthorization || 'hidden')
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Email' }),
            width: 40,
            alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
            render: (u) => u.user.email
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Username' }),
            width: 30,
            alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
            render: (u) => u.user.username
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Mobile' }),
            width: 20,
            alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
            render: (u) => u.user.contact?.mobile
        },
        {
            name: intl.formatMessage({ defaultMessage: 'File' }),
            width: 20,
            filterButton: true,
            alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
            render: (u) => u.file?.field.name
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Expiration date' }),
            width: 20,
            filterButton: true,
            alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
            render: (u) => u.file?.expiryDate ? moment(u.file.expiryDate).format(getFormat('DATE')) : ''
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Warning ignored?' }),
            width: 20,
            filterButton: true,
            alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
            render: (u) => u.file?.ignoreExpiration ? intl.formatMessage({ defaultMessage: 'Yes' }) : intl.formatMessage({ defaultMessage: 'No' })
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Note' }),
            width: 70,
            alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
            render: (u) => u.file?.note
        },
    ];

    const generator = new ReportExcel(`${moment().format('YYYY.MM.DD')}`, columns.length, intl, company);
    // , `${this.props.intl.formatMessage({ defaultMessage: 'From {start} to {end}' }, { start: from.format(getFormat('DATE')), end: to.format(getFormat('DATE')) })}`
    const buffer = await generator.generateExcel(intl.formatMessage({ defaultMessage: "Files" }), columns, fileByUser, company?.logo);

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const documentName = `${intl.formatMessage({ defaultMessage: "Files" })}_${moment().format(getFormat('DATE'))}`;
    a.href = url;
    a.download = `${documentName.toLocaleLowerCase().replaceAll(" ", "-")}.xlsx`;
    a.click();
};