/**
 * Constants containing the rules
 */
export const Rules = {
    Sidemenu: {
        Admin: "sidemenu:admin",
    },
    Newsfeed: {
        Filter: "newsfeed:filter",
        Delete: "newsfeed:delete",
        AllFeeds: "newsfeed:all",
    },
    Message: {
        Filter: "message:filter",
        Delete: "message:delete",
    },
    TeamManagement: {
        Visit: "teamManagement:visit",
        MakeAdmin: "teamManagement:makeAdmin",
    },
    CustomerManagement: {
        Visit: "customerManagement:visit",
    },
    CourseManagement: {
        Visit: "courseManagement:visit",
    },
    Project: {
        Visit: "project:visit",
    },
    ProjectV2: {
        Visit: "projectV2:visit",

    },
    Emergencies: {
        Visit: "emergencies:visit",
    },
    DirectoryListing: {
        Visit: "directoryListing:visit",
    },
    Dashboard: {
        Display: "dashboard:display",
    },
    Planning: {
        Weekly: "planning:weekly",
        Visit: "planning:visit",
        Filters: "planning:filters",
        AI: "planning:ai",
        Settings: "planning:settings",
        DnDVersion: "planning:dndVersion",
        Management: "planning:management",
    },
    Report: {
        Visit: "report:visit",
        Beta: "report:beta:visit",
    },
    SunkhronosLive: {
        Visit: "sunkhronosLive:visit",
    },
    Configurations: {
        Visit: "configurations:visit",
    },
    Integrations: {
        Admin: "integrations:admin"
    }
};

/**
 * The application Role Based Access Control rules
 */
const rules = {
    user: {
        static: [
            "administration:action",
            "planning:weekly",
            "planning:filters"
        ]
    },
    admin: {
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "customerManagement:visit",
            "project:visit",
            "projectV2:visit",
            "dashboard:display",
            "integrations:admin"
        ]
    },
    groupAdmin: {
        static: [
            "sidemenu:admin",
            "report:visit",
            "dashboard:display",
            "planning:filters",
            "project:visit",
            "projectV2:visit",
            "courseManagement:visit",
            "Rules.CourseManagement.Visit"
        ]
    }
};

export const companyModules = [
    {
        id: -1,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 55, // Webevolutions
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 152, // Traductions
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "customerManagement:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 57, // Tour des stations
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 68, // Eroica
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    // {
    //     id:47,
    //     static: [
    //         "sidemenu:admin",
    //         "newsfeed:filter",
    //         "newsfeed:delete",
    //         "newsfeed:all",
    //         "message:filter",
    //         "message:delete",
    //         "teamManagement:visit",
    //         "teamManagement:makeAdmin",
    //         "courseManagement:visit",
    //         "sunkhronosLive:visit",
    //         "administration:action",
    //         "emergencies:visit",
    //         "directoryListing:visit",
    //         "planning:filters",
    //         "planning:settings",
    //         "planning:dndVersion",
    //         "planning:management",
    //         // "report:visit",
    //         "report:beta:visit",
    //         "configurations:visit",
    //     ]
    // },
    {
        id: 66, // Barry
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            // "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 70, // Moxy Hotel
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 80, // Champex
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 96, // Hotel - Restaurant
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 101, // Iris Security
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 102, // Alpshawk
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 103, // GK Sécurité
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 135, // LM Security
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 104, // Barryland Restaurant
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 113, // Valjob Security
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 117, // Webevo Dev
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 123, // Odom
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "courseManagement:visit",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "administration:action",
            "emergencies:visit",
            "sunkhronosLive:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
        ]
    },
    {
        id: 129, // Barryland
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 132, // Martigny Boutique Hotel
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 136, // Bonvin Nettoyage
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
            "customerManagement:visit",
        ]
    },
    {
        id: 140, // Bisa
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 142, // Intenso
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 143, // Enzler
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 145, // Swiss Security (démo CCT)
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 150, // TourismTech
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 173, // Maetis-cpta
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "dashboard:display",
        ]
    },
    {
        id: 179, // La porte d'octodure
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 186, // Hotel Restaurant la poste d'Anzère
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 189, // Altipik
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 175, // Alpes pour tous
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 196, // JM Contactless Event
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "projectV2:visit",
            "courseManagement:visit",
            "dashboard:display",
            "sunkhronosLive:visit"
        ]
    },
    {
        id: 198, // Chateau Swiss
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "projectV2:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 200, // Extra Sunkhronos
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "projectV2:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
    {
        id: 206, // ValJob Event
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "projectV2:visit",
            "configurations:visit",
            "dashboard:display",
        ]
    },
];

export default rules;