import Cookie from 'js-cookie';
import request from 'superagent';
// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
const Compress = require('compress.js').default;

export const INTERNET_ERROR_MESSAGE = 'Not able to connect. Please check your internet connection.';
export const API_ERROR_MESSAGE = 'Could not load data. Please try again.';

// eslint-disable-next-line no-undef
let BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
if (!BASE_URL) {
    BASE_URL = "https://api.sunkhro.com";
}

// BASE_URL = BASE_URL + "/api/";

// eslint-disable-next-line no-undef
const version = process.env.REACT_APP_VERSION;

const baseResponseHandler = (err, res, reject, successCallBack) => {
    if (err) {
        if (err.status === 401) {
            Cookie.remove('userId');
            //TODO Don't force to fr by default
            window.location.pathname = "/fr/login";
            return;
        } else if (err.status === 502) {
            reject({ message: INTERNET_ERROR_MESSAGE });
            return;
        } else if (err.status === 503) {
            //window.location.pathname = "/maintenance.html";
            window.location.reload();
            return;
        } else if (err.status === 400) {
            reject({ message: res.text }); // TODO: use res.body (like 403) instead of res.text and change everywhere "JSON.parse"
            return;
        } else if (err.status === 403) {
            reject(res.body);
            return;
        } else {
            reject({ message: API_ERROR_MESSAGE });
            return;
        }
    }
    successCallBack();
};

export const apiRequest = (url_subpart, requestData, lang = 'fr') => {

    return new Promise((resolve, reject) => {
        request.post(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .send(requestData)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const apiRequestPut = (url_subpart, requestData, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.put(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .send(requestData)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const apiRequestPatch = (url_subpart, requestData, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.patch(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .send(requestData)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const compressedImageUpload = (imageFile, url, callback) => {
    const compress = new Compress();
    compress.compress([imageFile], {
        size: 2, // the max size in MB, defaults to 2MB
        quality: .75, // the quality of the image, max is 1,
        maxWidth: 800, // the max width of the output image, defaults to 1920px
        maxHeight: 800, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
    }).then((data) => {
        const img1 = data[0];
        const base64str = img1.data;
        const imgExt = img1.ext;
        const file = Compress.convertBase64ToFile(base64str, imgExt);

        const reader = new FileReader();
        reader.onload = (e) => {
            apiRequestFileUpload(url, e.target.result).then(
                response => {
                    callback(true, response);
                },
                error => {
                    callback(false, error);
                }
            );
        };

        reader.readAsArrayBuffer(file);
    });
};

export const apiRequestFileUpload = (url, fileData) => {
    console.log("Lecture réussie: ", fileData);
    return new Promise((resolve, reject) => {
        var oReq = new XMLHttpRequest();
        oReq.open("PUT", url, true);
        oReq.setRequestHeader("Content-Type", "application/octet-stream");
        oReq.onload = function (oEvent) {
            // Uploaded.
            resolve(oEvent);
        };
        oReq.onError = function () {
            reject({ message: API_ERROR_MESSAGE });
        };

        const blob = new Uint8Array(fileData);

        oReq.send(blob);
    });
};


export const apiRequestGet = (url_subpart, lang = 'fr') => {
    return new Promise((resolve, reject) => {

        request.get(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const apiRequestPatchWithBody = (url_subpart, requestData, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.patch(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .send(requestData)
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const apiRequestPutWithBody = (url_subpart, requestData, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.put(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .send(requestData)
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const apiRequestPostWithBody = (url_subpart, requestData, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.post(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .send(requestData)
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

// Not really a body, object to string ()
export const apiRequestFakeGetWithBody = (url_subpart, requestData, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.post(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .send(requestData)
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

// export const apiRequestGetWithBody = (url_subpart, requestData) => {
//     return new Promise((resolve, reject) => {
//         request.get(BASE_URL + url_subpart)
//             .set("Authorization", Cookie.get('userId'))
//             .set("X-VERSION", `${version}`)
//             .set("X-CLIENT-TYPE", 'ADMIN')
//             .send(requestData)
//             .end(function (err, res) {
//                 baseResponseHandler(err, res, reject, () => {
//                     resolve(res.body);
//                 });
//             });
//     });
// };

export const apiRequestDeleteWithBody = (url_subpart, requestData, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.delete(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .send(requestData)
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};
export const getRequest = (url_subpart) => {
    return new Promise((resolve, reject) => {
        request.get(url_subpart)
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const apiRequestDelete = (url_subpart, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.delete(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const apiRequestFile = (url_subpart, requestData, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.post(`${BASE_URL}/${lang}/api/${url_subpart}`)
            .send(requestData)
            .set("Authorization", Cookie.get('userId'))
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", "ADMIN")
            .responseType('blob')
            .end((err, res) => {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};

export const login = (username, password, rememberMe, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.post(`${BASE_URL}/${lang}/api/user/login`)
            .send({ username: username, password: password, client_id: 55309 })
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", "ADMIN")
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                    if (rememberMe) {
                        Cookie.set('userId', res.body.token);
                    } else {
                        Cookie.set('userId', res.body.token);

                    }
                    // localStorage.setItem('userId', res.body.token);
                });
            });
    });
};


export const logout = (token, lang = 'fr') => {
    return new Promise((resolve, reject) => {
        request.get(`${BASE_URL}/${lang}/api/user/logout`)
            .set("Authorization", token)
            .set("X-VERSION", `${version}`)
            .set("X-CLIENT-TYPE", 'ADMIN')
            .end(function (err, res) {
                baseResponseHandler(err, res, reject, () => {
                    resolve(res.body);
                });
            });
    });
};