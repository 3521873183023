

import moment from 'moment';
import { Component } from 'react';
import { EventForProject } from '../../../../utils/types/planningTypes';
import { formatProjectTitle } from '../../../../utils/utils';
import { UserSmall } from '../../../common/general/fullUser';

export type IEventForProjectComparatorParameter = { a: EventForProject, b: EventForProject, getFullName: (user: UserSmall) => string };

export type IEventForProjectComparator = (data: IEventForProjectComparatorParameter) => number;

export interface AbstractReportsGroupedProps {
    displayDetails: boolean;
    eventsForProjects?: EventForProject[];
    height: number;
    fromDate: string;
    toDate: string;
}

export abstract class AbstractReportsGrouped<Props extends AbstractReportsGroupedProps, State> extends Component<Props, State> {
    abstract downloadProjectEvents: () => void;


    protected removeDetails(key: React.Key | undefined) {
        switch (key) {
            case 'title':
            case 'startDate':
            case 'startTime':
            case 'endTime':
            case 'paidBreaktimeSec':
            case 'unpaidBreaktimeSec':
            case 'overtimes':
            case 'effectiveSec':
            case 'effectiveWithIncreasedSec':
            case 'increasedHoursSec':
            case 'increasedVacationSec':
            case 'userNote':
                return false;
            default:
                return true
        }
    }

    protected sortData = (data: EventForProject[] | undefined, comparators: IEventForProjectComparator[], getFullName: (user: UserSmall) => string) => {
        return data?.sort((a, b) => {
            for (const comparator of comparators) {
                const compareResult = comparator({ a, b, getFullName });
                if (compareResult !== 0) {
                    return compareResult; // Stoppe immédiatement la comparaison et retourne la valeur
                }
            }
            return 0;
        })
    }

    protected compareProject = (data: IEventForProjectComparatorParameter) => {
        const projectTitleA = formatProjectTitle(data.a.projectTitle, data.a.projectCustomId);
        const projectTitleB = formatProjectTitle(data.b.projectTitle, data.b.projectCustomId);

        const compareProjectName = projectTitleA.localeCompare(projectTitleB, undefined, { numeric: true });
        return compareProjectName;
    }

    protected compareUser = (data: IEventForProjectComparatorParameter) => {
        if (!data.a.userLastName || !data.a.userFirstName || !data.b.userFirstName || !data.b.userLastName) return 0;
        const aFullName = data.getFullName({ id: data.a.userId, first_name: data.a.userFirstName, last_name: data.a.userLastName, code: data.a.userCode });
        const bFullName = data.getFullName({ id: data.b.userId, first_name: data.b.userFirstName, last_name: data.b.userLastName, code: data.b.userCode });
        const compareFullname = aFullName.localeCompare(bFullName, undefined, { numeric: true });
        return compareFullname;
    }

    protected compareDate = (data: IEventForProjectComparatorParameter) => {
        const dateA = moment(data.a.startDate)
        const dateB = moment(data.b.startDate)
        return dateA.diff(dateB);
    }
}