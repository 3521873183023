import { Alert } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { useFullName } from '../../../../hooks/useUsers';
import { CaseType } from '../../../../utils/constants';
import ReportExcel, { IReportExcelRow } from '../../../../utils/exports/ReportExcel';
import getFormat from '../../../../utils/Lang';
import { FullUserProps } from '../../../../utils/objects/withFullName';
import { EventForProject } from '../../../../utils/types/planningTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { formatProjectTitle, getCaseAndPlural, roundDecimals } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import { AbstractReportsGrouped, AbstractReportsGroupedProps } from './abstractReportsGrouped';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps, AbstractReportsGroupedProps, FullUserProps {
}

interface State {
}

class ReportsNotGroupedCore extends AbstractReportsGrouped<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }
    downloadProjectEvents = async () => {
        const { eventsForProjects } = this.props;

        if (eventsForProjects) {
            const { intl, company, getFullName, displayDetails, fromDate, toDate } = this.props;
            const sortedEventsForProjects = this.getSortedEventsForProjects();
            const projectName = this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : intl.formatMessage({ defaultMessage: 'Project' });

            //! Take care to update the columns in the antd table
            let columns: IReportExcelRow<EventForProject> = [
                {
                    name: intl.formatMessage({ defaultMessage: 'Date' }),
                    width: 13,
                    key: 'startDate',
                    filterButton: true,
                    totalsRowLabel: intl.formatMessage({ defaultMessage: 'Totals' }),
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => moment.utc(e.startDate).format(getFormat('DATE')),
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'User' }),
                    width: 40,
                    key: 'userFullName',
                    filterButton: true,
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => getFullName({ id: e.userId, first_name: e.userFirstName ?? '', last_name: e.userLastName ?? '', code: e.userCode })
                },
                {
                    name: projectName,
                    width: 25,
                    key: 'projectTitle',
                    filterButton: true,
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => formatProjectTitle(e.projectTitle, e.projectCustomId),
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Event' }),
                    width: 25,
                    key: 'title',
                    filterButton: true,
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => e.title,
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Start' }),
                    width: 8,
                    key: 'startTime',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => moment.utc(e.startDate).format(getFormat('TIME_SHORT'))
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'End' }),
                    width: 8,
                    key: 'endTime',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => moment.utc(e.endDate).format(getFormat('TIME_SHORT'))
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Paid breaks' }),
                    width: 8,
                    key: 'paidBreaktimeSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.paidBreaktimeSec) / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Unpaid breaks' }),
                    width: 12,
                    key: 'unpaidBreaktimeSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.unpaidBreaktimeSec) / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Over' }),
                    width: 8,
                    key: 'overtimes',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals((e.overtimeSec) / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Effectives' }),
                    width: 10,
                    key: 'effectiveSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals(e.effectiveSec / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Increased hours' }),
                    width: 11,
                    key: 'increasedHoursSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals(e.increasedHoursSec / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Increased vacation' }),
                    width: 11,
                    key: 'increasedVacationSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals(e.increasedVacationSec / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Effectives (increased)' }),
                    width: 13,
                    key: 'effectiveWithIncreasedSec',
                    totalsRowFunction: 'sum',
                    alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
                    render: (e) => roundDecimals(e.effectiveWithIncreasedSec / 3600)
                },
                {
                    name: intl.formatMessage({ defaultMessage: 'Comment' }),
                    width: 40,
                    key: 'userNote',
                    alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
                    render: (e) => e.userNote
                },
            ];
            if (!displayDetails) {
                columns = columns.filter(c => this.removeDetails(c.key));
            }

            const details = `${intl.formatMessage({ defaultMessage: 'From {start} to {end}' }, { start: moment(fromDate).format(getFormat("DATE")), end: moment(toDate).format(getFormat("DATE")) })}`;
            const generator = new ReportExcel(`${projectName}`, columns.length, intl, company);
            const buffer = await generator.generateExcel(intl.formatMessage({ defaultMessage: "Report of {project}" }, { project: projectName }), columns, sortedEventsForProjects ?? [], company?.logo, details);

            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            const documentName = `${intl.formatMessage({ defaultMessage: "Report of {project}" }, { project: projectName })}`;
            a.href = url;
            a.download = `${documentName.toLocaleLowerCase().replaceAll(" ", "-")}.xlsx`;
            a.click();
        }
    }

    getSortedEventsForProjects = () => {
        const { eventsForProjects } = this.props;
        return eventsForProjects?.sort((a, b) => {
            const dateA = new Date(a.startDate).getDate();
            const dateB = new Date(b.startDate).getDate();
            if (dateA !== dateB) return dateA - dateB;

            const fullNameA = `${a.userLastName} ${a.userFirstName}`;
            const fullNameB = `${b.userLastName} ${b.userFirstName}`;
            if (fullNameA !== fullNameB) return fullNameA.localeCompare(fullNameB);
            if (!a.projectTitle || !b.projectTitle) return 0;
            return a.projectTitle.localeCompare(b.projectTitle);
        })
    }

    getColumns = () => {
        const { displayDetails } = this.props;

        if (!displayDetails) {
            return this.eventsColumns.filter(c => this.removeDetails(c.key));
        }

        return this.eventsColumns;
    }

    removeDetails(key: React.Key | undefined) {
        switch (key) {
            case 'startDate':
            case 'effectiveSec':
            case 'effectiveWithIncreasedSec':
            case 'title':
                return true;
            default:
                return super.removeDetails(key);
        }
    }

    render() {
        const { height, eventsForProjects } = this.props;

        const pageSize = Math.round((height - 175) / 50);

        const totalPaidBreaktimesSeconds = eventsForProjects?.reduce((prevValue, event) => prevValue + event.paidBreaktimeSec, 0.0);
        const totalUnpaidBreaktimesSeconds = eventsForProjects?.reduce((prevValue, event) => prevValue + event.unpaidBreaktimeSec, 0.0);
        const totalOvertimesSeconds = eventsForProjects?.reduce((prevValue, event) => prevValue + event.overtimeSec, 0.0);
        const totalEffectiveSeconds = eventsForProjects?.reduce((prevValue, event) => prevValue + event.effectiveSec, 0.0);
        const totalIncreasedHoursSec = eventsForProjects?.reduce((prevValue, event) => prevValue + event.increasedHoursSec, 0.0);
        const totalIncreasedVacationSec = eventsForProjects?.reduce((prevValue, event) => prevValue + event.increasedVacationSec, 0.0);
        const totalEffectiveWithIncreasedSec = eventsForProjects?.reduce((prevValue, event) => prevValue + event.effectiveWithIncreasedSec, 0.0);

        const totalPaidBreaktimesHours = totalPaidBreaktimesSeconds ? roundDecimals(totalPaidBreaktimesSeconds / 3600) : 0;
        const totalNotPaidBreaktimesHours = totalUnpaidBreaktimesSeconds ? roundDecimals(totalUnpaidBreaktimesSeconds / 3600) : 0;
        const totalOvertimesHours = totalOvertimesSeconds ? roundDecimals(totalOvertimesSeconds / 3600) : 0;
        const totalEffectiveHours = totalEffectiveSeconds ? roundDecimals(totalEffectiveSeconds / 3600) : 0;
        const totalIncreasedHoursHours = totalIncreasedHoursSec ? roundDecimals(totalIncreasedHoursSec / 3600) : 0;
        const totalIncreasedVacationHours = totalIncreasedVacationSec ? roundDecimals(totalIncreasedVacationSec / 3600) : 0;
        const totalEffectiveWithIncreasedHours = totalEffectiveWithIncreasedSec ? roundDecimals(totalEffectiveWithIncreasedSec / 3600) : 0;

        const sortedEventsForProjects = this.sortData(eventsForProjects, [this.compareDate, this.compareUser, this.compareProject], this.props.getFullName);

        return (
            <>
                {
                    sortedEventsForProjects ?
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                            <Alert type='info'
                                message={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                                        <div>
                                            <span><i><FormattedMessage defaultMessage={'Unpaid breaks'} />{':'}</i> {totalNotPaidBreaktimesHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Paid breaks'} />{':'} {totalPaidBreaktimesHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Overtimes'} />{':'}  {totalOvertimesHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><b><FormattedMessage defaultMessage={'Effective hours'} />{':'} </b> {totalEffectiveHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Increased hours'} />{':'} {totalIncreasedHoursHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><FormattedMessage defaultMessage={'Increased vacation'} />{':'} {totalIncreasedVacationHours.toFixed(2)}</span>
                                        </div>
                                        <div>
                                            <span><b><FormattedMessage defaultMessage={'Totals'} />{':'} </b> {totalEffectiveWithIncreasedHours.toFixed(2)}</span>
                                        </div>
                                    </div>
                                }
                            />

                            <Table
                                bordered
                                columns={this.getColumns()}
                                dataSource={sortedEventsForProjects}
                                scroll={{ x: true }}
                                rowKey={(record: EventForProject) => `normal-${record.id}-${record.projectId}-${record.userId}-${record.startDate}`}
                                pagination={{
                                    pageSize: pageSize,
                                    showSizeChanger: false,
                                    responsive: true,
                                    size: "small",
                                    showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} events'} values={{ range0: range[0], range1: range[1], total }} />
                                }}
                            />
                        </div>
                        : <p>Problem data</p>
                }
            </>
        );
    }

    //! Take care to update the columns in download region
    eventsColumns: ColumnsType<EventForProject> = [
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'startDate',
            dataIndex: 'startDate',
            className: '__width_110 __centered-text',
            fixed: "left",
            render: (startDate: string) => moment.utc(startDate).format(getFormat('DATE'))
        },
        {
            title: <FormattedMessage defaultMessage={'Employee'} />,
            key: 'userFullName',
            className: '__min-width-250 __ellipsis-text',
            render: (value: EventForProject) => {
                const fullName = this.props.getFullName({ id: value.userId, first_name: value.userFirstName ?? '', last_name: value.userLastName ?? '', code: value.userCode });
                return <span title={fullName}>{fullName}</span>
            },
        },
        {
            title: this.props.company?.projectDisplayText ? getCaseAndPlural(this.props.company?.projectDisplayText, false, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Project'} />,
            key: 'projectTitle',
            dataIndex: 'projectTitle',
            className: '__width_250 __ellipsis-text',
            render: (projectTitle: string, record: EventForProject) => {
                const formatedProjectTitle = formatProjectTitle(projectTitle, record.projectCustomId);
                return <span title={formatedProjectTitle}>{formatedProjectTitle}</span>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: 'title',
            dataIndex: 'title',
            className: '__width_250 __ellipsis-text',
            render: (title: string) => <span title={title}>{title}</span>
        },
        {
            title: <FormattedMessage defaultMessage={'Start'} />,
            key: 'startTime',
            dataIndex: 'startDate',
            className: '__width_70 __centered-text',
            render: (startDate: string) => moment.utc(startDate).format(getFormat('TIME_SHORT'))
        },
        {
            title: <FormattedMessage defaultMessage={'End'} />,
            key: 'endTime',
            dataIndex: 'endDate',
            className: '__width_70 __centered-text',
            render: (endDate: string) => moment.utc(endDate).format(getFormat('TIME_SHORT'))
        },
        {
            title: <FormattedMessage defaultMessage={'Paid breaks'} />,
            key: 'paidBreaktimeSec',
            dataIndex: 'paidBreaktimeSec',
            className: '__width_80 __centered-text',
            render: (paidBreaktimeSec: number) => roundDecimals(paidBreaktimeSec / 3600).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Unpaid breaks'} />,
            key: 'unpaidBreaktimeSec',
            dataIndex: 'unpaidBreaktimeSec',
            className: '__width_100 __centered-text',
            render: (unpaidBreaktimeSec: number) => roundDecimals(unpaidBreaktimeSec / 3600).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Over'} />,
            key: 'overtimes',
            dataIndex: 'overtimeSec',
            className: '__width_70 __centered-text',
            render: (paidBreaktimeSec: number) => roundDecimals(paidBreaktimeSec / 3600).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Effectives'} />,
            key: 'effectiveSec',
            dataIndex: 'effectiveSec',
            className: '__width_90 __centered-text',
            render: (effectiveSec: number) => roundDecimals(effectiveSec / 3600).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Increased hours'} />,
            key: 'increasedHoursSec',
            className: '__width_100 __centered-text',
            dataIndex: 'increasedHoursSec',
            render: (increasedHoursSec: number) => roundDecimals((increasedHoursSec / 3600)).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Increased vacation'} />,
            key: 'increasedVacationSec',
            className: '__width_100 __centered-text',
            dataIndex: 'increasedVacationSec',
            render: (increasedVacationSec: number) => roundDecimals((increasedVacationSec / 3600)).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Effectives (increased)'} />,
            key: 'effectiveWithIncreasedSec',
            className: '__width_120 __centered-text',
            dataIndex: 'effectiveWithIncreasedSec',
            render: (effectiveWithIncreasedSec: number) => roundDecimals((effectiveWithIncreasedSec / 3600)).toFixed(2)
        },
        {
            title: <FormattedMessage defaultMessage={'Comment'} />,
            key: 'userNote',
            dataIndex: 'userNote',
            className: '__min-width-220 __ellipsis-text',
            render: (userNote: string) => <span title={userNote}>{userNote}</span>
        },
    ];

}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    sidebarType: state.window.sidebarType,
});

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

const ReportsNotGrouped = connector(ReportsNotGroupedCore);

const ForwardedReportsNotGrouped = forwardRef<ReportsNotGroupedCore, any>((props: Props, ref) => {
    const intl = useIntl();
    const fullName = useFullName();

    return <ReportsNotGrouped {...props} getFullName={(user) => fullName.getFullName(user)} ref={ref as React.Ref<ReportsNotGroupedCore>} intl={intl} />;
});

ForwardedReportsNotGrouped.displayName = "ForwardedReportsNotGrouped";


export default ForwardedReportsNotGrouped;
