import { Alert, Button, message, notification, Space, Tooltip } from 'antd';
import { NotificationApi } from 'antd/lib/notification';
import moment, { Moment } from 'moment';
import 'moment/locale/fr';
import ReactDOM from 'react-dom';
import { defineMessage, IntlShape, MessageDescriptor } from 'react-intl';
import { Frequency, RRule, rrulestr, Weekday } from 'rrule';
import { store } from '../';
import { UserSmall } from '../components/common/general/fullUser';
import { monthlySetPosOptions, weekDaysRRule } from '../components/teamManagement/userAvailability/availabilityEditForm';
import { CompanyDetailsPrivilege, Privileges } from '../privileges';
import rules, { companyModules } from '../rbacRules';
import { AvailableLanguages, BreaktimeCalculatedTypes, CaseType, CompanyTypes, ContractTypeMode, MathTypeRound, MOMENT_FORMAT, MOMENT_FORMAT_DATE_TO_NETWORK, MOMENT_FORMAT_DISPLAY_TIME, MOMENT_FORMAT_TIME_TO_NETWORK, MOMENT_SHORT_DATE_FORMAT, OvertimesCalculatedStatus, OvertimesCalculatedTypes, ReloadTimes, TimeClockConfirmBehaviourVars } from './constants';
import { EventDataType, EventFrequences, EventWeekdays, OccupancyRateFrequency, PlanningEventOwner } from './enumerations';
import getFormat from './Lang';
import Network from './network';
import { UserEventsData } from './objects/cct/userEventsData';
import { CCodeAuthorization, ECodePosition } from './types/constants';
import { ICustomer, ICustomerNetwork, IMissionRuleType } from './types/customerTypes';
import { Company, DictionaryNumber, DictionaryString, EventClocked, EventClockedDates, EventClockedSmall, EventWithEventClocked, Group, IFilterUsers, RangeOfMoment, RGB, SmallEventClocked, StartAndEndDates, Statistics, User, UserJobTMP, UserYearlyParams } from './types/generalTypes';
import { NetworkAllHolidays, NetworkBreakTime, NetworkCalendarData, NetworkCalendarUserData, NetworkCcnt, NetworkCcntEvent, NetworkCcntEventBreaktime, NetworkCcntEventOvertime, NetworkCctSecurityData, NetworkCctSecurityResponse, NetworkEffectivePeriod, NetworkEvent, NetworkEventClocked, NetworkEventClockedSmall, NetworkEventWithEventClocked, NetworkExclusion, NetworkHolidays, NetworkIncreasedHoursByDay, NetworkMonthlyReport, NetworkMonthlyReportEvent, NetworkMonthlyReportEventBreaktime, NetworkMonthlyReportEventOvertime, NetworkOccupancyRate, NetworkOccupancyRateExclusion, NetworkOvertime, NetworkPeriod, NetworkProjectEvent, NetworkReminder, NetworkSettings, NetworkSimpleBreaktime, NetworkSimpleContract, NetworkSimpleEvent, NetworkSimpleOvertime, NetworkSmallEventClocked, NetworkStatistics, NetworkTemplate, NetworkUserEffectiveHours, NetworkUserExtraVacations, NetworkUserRow, NetworkUserVacations, NetworkUserYearlyParams, NetworkVacations, UserAvailabilityEditNetwork, UserAvailabilityNetwork } from './types/networkTypes';
import { AllHolidays, BreakTime, IBreakTime, ITemplate, PlanningCalendarData, PlanningEffectivePeriod, PlanningEvent, PlanningExclusion, PlanningExclusionEdit, PlanningHolidays, PlanningOccupancyRate, PlanningOccupancyRateExclusion, PlanningOvertime, PlanningPeriod, PlanningPeriodEdit, PlanningSettings, PlanningTemplate, PlanningUserExtraVacations, PlanningUserRow, PlanningUserRowDay, PlanningUserVacations, PlanningVacations, ProjectEvent, ProjectEventExcel, Reminder, RRuleOptions, RRuleOptionsBeforeConvert, UserAvailability, UserAvailabilityEdit } from './types/planningTypes';
import { CcntEvent, CcntEventBreaktime, CcntEventOvertime, CcntType, CctSecurityData, CctSecurityResponse, IncreasedHoursByDay, MonthlyReportEvent, MonthlyReportEventBreaktime, MonthlyReportEventOvertime, MonthlyReportType, SimpleBreaktime, SimpleContract, SimpleEvent, SimpleOvertime, UserEffectiveHours } from './types/reportTypes';
import { ApplicationState } from './types/storeTypes';


/* General */

/**
 * Create an alert
 * @param message the message to display
 * @param type the type of the message: "success" | "info" | "warning" | "error" | undefined
 * @param showIcon if the icon must be visible
 * @param description an additional text
 */
export const alert = (message: string, type: "success" | "info" | "warning" | "error" | undefined, showIcon = true, description?: string) => {
    //get container by id
    const container = document.getElementById("container");

    //delete previous alert
    const previousAlert = document.getElementById('alert-container');
    if (previousAlert) container?.removeChild(previousAlert);

    //add div
    const div = document.createElement("div");
    div.className = "container-alert";
    div.id = "alert-container";
    container?.appendChild(div);

    //create alert
    const component = <Alert
        message={message}
        type={type}
        showIcon={showIcon}
        description={description} />;

    // render component
    const alert = document.getElementById('alert-container');
    if (alert) ReactDOM.render(component, alert);
};

export const showNotification = (title: string, type: string, description?: string) => {
    switch (type) {
        case 'success':
            notification.success({
                message: title,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            });
            break;
        case 'error':
            notification.error({
                message: title,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            });
            break;
        case 'info':
            notification.info({
                message: title,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            });
            break;
        case 'warn':
            notification.warn({
                message: title,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            });
            break;
        case 'warning':
            notification.warning({
                message: title,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            });
            break;
        default:
            notification.info({
                message: title,
                description: description,
                bottom: 0,
                placement: 'bottomRight',
            });
            break;
    }
};

export const showNotif = (api: NotificationApi, title: string, type: string, description?: React.ReactNode, intl?: IntlShape) => {
    const btn = (
        <Space>
            <Button type="default" onClick={() => emptyCache()}>
                {intl ? intl.formatMessage({ defaultMessage: 'Update' }) : 'IntlShape Missing'}
            </Button>
            <Button type="primary" onClick={() => api.close('update-notif-key')}>
                {intl ? intl.formatMessage({ defaultMessage: 'Close' }) : 'IntlShape Missing'}
            </Button>
        </Space>
    );

    switch (type) {
        case 'success':
            api.success({
                duration: 4.5,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                placement: 'bottomRight',
            });
            break;
        case 'error':
            api.error({
                duration: 12,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                placement: 'bottomRight',
            });
            break;
        case 'info':
            api.info({
                duration: 4.5,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                placement: 'bottomRight',
            });
            break;
        case 'warn':
            api.warning({
                duration: 7,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                placement: 'bottomRight',
            });
            break;
        case 'warning':
            api.warning({
                duration: 7,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                placement: 'bottomRight',
            });
            break;
        case 'update':
            api.info({
                duration: 0,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                placement: 'topRight',
                key: 'update-notif-key',
                btn
            });
            break;
        default:
            api.info({
                duration: 4.5,
                message: <span style={description ? { fontWeight: "bold" } : {}}>{title}</span>,
                description: description,
                placement: 'bottomRight',
            });
            break;
    }

};
/**
 * Display an error message
 * @param msg the message to display
 * @param shakeId the id of the component to shake
 */
export const displayErrorMessage = (msg: string, shakeId?: string, timeToShow?: number): void => {
    const component = shakeId ? document.getElementById(shakeId) : undefined;
    if (component && !component.className.includes("shake-animation")) {
        component.className += " shake-animation";
        setTimeout(() => component.className = component.className.replace(" shake-animation", ""), 850);
    }
    const time = timeToShow ? timeToShow : 6;
    message.error(msg, time);
};


/**
 * Convert a vehicule Type ID (number) to textual vehicle type
 * @param vehicleTypeId vehicule Type ID
 */
// export const getVehiculeTypeFromId = (vehicleTypeId?: string): string => {
//     let vehicleTypeString: string = "Error in finding vehicule type from vehicule type ID";
//     switch (vehicleTypeId) {
//         case VehicleTypes.SERVICE:
//             vehicleTypeString = "Véhicule de service";
//             break;

//         case VehicleTypes.PERSONAL:
//             vehicleTypeString = "Véhicule personnel";
//             break;

//         case VehicleTypes.PASSENGER:
//             vehicleTypeString = "Passager";
//             break;

//         default:
//             vehicleTypeString = "Type not found";
//             break;
//     }


//     return vehicleTypeString;
// }

/**
 * Convert a vehicule Type (string) to vehicle type id (number)
 * @param vehicleTypeString vehicule Type string
 */
// export const getVehiculeTypeIdFromString = (vehicleTypeString?: string): string => {
//     let vehicleTypeId: string = "-1";
//     switch (vehicleTypeString) {
//         case "Service":
//             vehicleTypeId = "1";
//             break;

//         case "Personnel":
//             vehicleTypeId = "2";
//             break;

//         case "Passager":
//             vehicleTypeId = "3";
//             break;

//         default:
//             vehicleTypeId = "-1";
//             break;
//     }

//     return vehicleTypeId;
// }


/**
 * Convert a hexadecimal color to rgb color
 * @param hex the hexadecimal color
 * @param amt an value to add to each rgb value - optional
 */
export const hexToRgb = (hex: string, amt = 0): RGB => {
    const num = parseInt(hex.replace("#", ""), 16);
    return {
        red: (num >> 16) + amt,
        green: (num & 0x0000FF) + amt,
        blue: ((num >> 8) & 0x00FF) + amt,
    };
};

// export const hexToRgb2 = (hex: string) => {
//     const bigint = parseInt(hex.slice(1), 16);
//     const r = (bigint >> 16) & 255;
//     const g = (bigint >> 8) & 255;
//     const b = bigint & 255;
//     return { r, g, b };
// }

/**
 * Convert rgb color to hexadecimal color
 * @param red the red value
 * @param green the green value
 * @param blue the blue value
 * @returns the hexadecimal color
 */
export function rgbToHex(red: number, green: number, blue: number): string {
    return '#' + (0x1000000 + (red < 255 ? red < 1 ? 0 : red : 255) * 0x10000 + (blue < 255 ? blue < 1 ? 0 : blue : 255) * 0x100 + (green < 255 ? green < 1 ? 0 : green : 255)).toString(16).slice(1);
}

/**
 * Get a darker version of a color
 * @param color the base color in hexadecimal
 * @param percent the percent effect  - optional
 * @return the resulted color in hexadecimal
 */
export const darker = (color: string, percent = 20): string => {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const red = (num >> 16) - amt, blue = ((num >> 8) & 0x00FF) - amt, green = (num & 0x0000FF) - amt;
    return '#' + (0x1000000 + (red < 255 ? red < 1 ? 0 : red : 255) * 0x10000 + (blue < 255 ? blue < 1 ? 0 : blue : 255) * 0x100 + (green < 255 ? green < 1 ? 0 : green : 255)).toString(16).slice(1);
};

export const calculateColorDistance = (color1: any, color2: any) => {
    const rDiff = color1.red - color2.red;
    const gDiff = color1.green - color2.green;
    const bDiff = color1.blue - color2.blue;
    return Math.sqrt(rDiff * rDiff + gDiff * gDiff + bDiff * bDiff);
};

export const findSimilarColors = (referenceColorHex: string, colorsList: string[], threshold = 100, getIndex = false) => {
    const referenceColor = hexToRgb(referenceColorHex);

    const colors = colorsList.map(hexToRgb);

    const similarColorsIndex: number[] = [];

    const colorsWithDistances = colors.map((color, id) => {
        const distance = calculateColorDistance(referenceColor, color);
        return {
            color: color,
            distance: distance,
            id: id
        };
    }).sort((a, b) => a.distance - b.distance);


    // Filtrer les couleurs en fonction du seuil de similitude
    const similarColors = colorsWithDistances.filter(color => {
        if (color.distance <= threshold) {
            similarColorsIndex.push(color.id);
            return true;
        } else {
            return false;
        }
    }).map(color => color.color);

    if (getIndex) {
        return similarColorsIndex;
    }

    const convertedSimilarColors = similarColors.map(color => rgbToHex(color.red, color.green, color.blue));

    return convertedSimilarColors;
};

/**
 * Returns a supplied numeric expression rounded to the nearest double.
 * 
 * @param numberToRound The value to be rounded to the nearest double.
 * @param nbDecimals The number of decimals, by default 2
 * @returns The number rounded
 */
export const roundDecimals = (numberToRound: number, nbDecimals = 2, roundOrCeil = MathTypeRound.ROUND) => {
    let roundMetho = Math.round;
    let makeDecimal = 1;
    if (nbDecimals > 0) {
        makeDecimal = Math.pow(10, nbDecimals);
    }

    if (roundOrCeil === MathTypeRound.CEIL) roundMetho = Math.ceil;

    return roundMetho((numberToRound + Number.EPSILON) * makeDecimal) / makeDecimal;
};

export const minutesToHours = (hours: number, nbDecimals = 2) => {
    return roundDecimals((hours / 60), nbDecimals);
};

/**
 * 
 * @param value 
 * @returns 
 */
export const val = (value: number | undefined, def = 0) => {
    if (value !== undefined && value !== null) return value;
    else return def;
};

/**
 * Get a lighter version of a color
 * @param color the base color in hexadecimal
 * @param percent the percent effect - optional
 * @return the resulted color in hexadecimal
 */
export const lighter = (color: string, percent = 20): string => {
    const amt = Math.round(2.55 * percent);
    const rgb = hexToRgb(color, amt);
    return rgbToHex(rgb.red, rgb.green, rgb.blue);
};

/**
 * Check if a color is light
 * @param color the color to check
 * @returns true if the color is light, false otherwise
 */
export const colorIsBright = (color: string): boolean => {
    const rgb = hexToRgb(color);
    rgb.red /= 255;
    rgb.green /= 255;
    rgb.blue /= 255;

    return Math.round((Math.max(rgb.red, rgb.green, rgb.blue) + Math.min(rgb.red, rgb.green, rgb.blue)) / 2) >= 0.5;
};


/**
 * Desaturate a color
 * @param color the base color in hexadecimal
 * @param percent the percent effect - optional
 */
export const desaturate = (color: string, percent = 20): string => {
    const rgb = hexToRgb(color);

    const saturation = percent / 100;
    const gray = rgb.red * 0.3086 + rgb.green * 0.6094 + rgb.blue * 0.0820;

    const red = Math.round(rgb.red * saturation + gray * (1 - saturation));
    const green = Math.round(rgb.green * saturation + gray * (1 - saturation));
    const blue = Math.round(rgb.blue * saturation + gray * (1 - saturation));
    return rgbToHex(red, green, blue);
};

/**
 * Given a hex color background, return the best text color to use for that color (white or black)
 * @param {string} backgroundColorHex - the hex value of the background color
 * @returns white or black
 */
export const pickBestTextColor = (backgroundColorHex: string) => {
    const rgb = hexToRgb(backgroundColorHex);

    const brightness = Math.round(((rgb.red * 299) + (rgb.green * 587) + (rgb.blue * 114)) / 1000);
    const textColour = (brightness > 125) ? '#000000' : '#ffffff';
    return textColour;
};

/**
 * Check if a user's role can access a rule
 * @param rule the rule to check
 * @param role the user's role - optional
 * @returns true if the user as the rights, false otherwise
 */
export const checkRBACRule = (rule: string, role?: number, company?: number, groupAdmin?: number) => {
    //if role is not specified get role from redux store
    if (!role) role = store.getState().user.currentUser?.role;
    const permissions = rules[role === 2 ? 'admin' : groupAdmin && groupAdmin > 0 ? "groupAdmin" : 'user'];
    if (company) {
        let modules = companyModules.find(cm => cm.id === company);
        if (modules === undefined) {
            modules = companyModules[0];
        }
        return modules !== undefined && modules.static.includes(rule) && permissions.static.includes(rule);
    } else {
        return permissions.static.includes(rule);
    }
};


/**
 * 
 * @param privilege the privilege to check
 * @param user the current user
 * @returns true if the user as the permissions, false otherwise
 */
export const checkPrivilege = (privilege: string, user?: User, userViewId?: number) => {
    //if user is not specified get user from redux store
    if (!user) user = store.getState().user.currentUser;
    const integrations = store.getState().integrations.enabledIntegrations.data;

    // switch over the privilege and act accordingly
    switch (privilege) {
        case Privileges.Planning.Edition:
            return Boolean(user?.can_edit_planning) && user?.id === userViewId;
        case Privileges.Planning.Visit:
            return user?.company_detail ? !user.company_detail.ext : false;
        case Privileges.Planning.readOnlyAllPlanning:
            return Boolean(user?.can_read_only_all_planning) || user?.role === 2;
        case Privileges.Report.Visit:
            return user?.company_detail ? !user.company_detail.ext : false;
        case Privileges.CRM.Visit:
            return user?.company_detail ? user.company_detail.ext && store.getState().user.company?.client && user.role > 1 : false;
        case Privileges.Dashboard.Display:
            return user?.company_detail ? (user && ([2, 3].includes(user.role) || user.groupsAdmin && user.groupsAdmin.length > 0)) : false;
        case Privileges.SMS.Visit:
            if (user?.company_detail) {
                if (user.company_detail.ext && store.getState().user.company?.client && user.role > 1) {
                    return true;
                } else if (user.company_id === 55 && user.role > 1) {
                    return true; //Allow company webevolutions
                } else {
                    return false;
                }
            } else {
                return false;
            }
        case Privileges.Integrations.Admin:
            if (integrations?.hotelaEnabled || integrations?.officeMakerEnabled || integrations?.globalOfficeEnabled) {
                return true;
            }
            return false;
        default:
            return false;
    }
};

export const getStartDateForTimeClock = (company: Company | undefined, planDates: StartAndEndDates, timeclockDates: StartAndEndDates, fromTimeClock: boolean) => {
    let startDate = timeclockDates.startDate.clone();
    let endDate = timeclockDates.endDate.clone();
    if (company && !fromTimeClock) {
        switch (company.timeClockConfirmBehaviour) {
            case TimeClockConfirmBehaviourVars.BindStart:
                if (startDate.isBefore(planDates.startDate, "seconds"))
                    startDate = planDates.startDate.clone();
                break;
            case TimeClockConfirmBehaviourVars.BindEnd:
                if (endDate.isAfter(planDates.endDate, "seconds"))
                    endDate = planDates.endDate.clone();
                break;
            case TimeClockConfirmBehaviourVars.BindStartEnd:
                if (startDate.isBefore(planDates.startDate, "seconds"))
                    startDate = planDates.startDate.clone();
                if (endDate.isAfter(planDates.endDate, "seconds"))
                    endDate = planDates.endDate.clone();
                break;
        }
    }
    return { startDate, endDate } as StartAndEndDates;
};

/**
 * 
 * @param privilege the privilege to check
 * @param company the current user company
 * @param user the current user
 * @param checkedValue optional parameter to check Edition Privilege by user_id
 * @returns true if the user as the permissions, false otherwise
 */
export const checkCompanyDetailsPrivilege = (privilege: string, company?: Company, user?: User, checkedValue?: number) => {
    //if user is not specified get user from redux store
    //if (!company) company = store.getState().user.company
    //if (!user) user = store.getState().user.currentUser;

    // switch over the privilege and act accordingly
    switch (privilege) {
        case CompanyDetailsPrivilege.TimeClock.Visit:
            if (company === undefined || user === undefined || !company.isTimeClockEnabled) return false;
            switch (company.timeClockReadAuthorization) {
                case 'users':
                    return true;
                case 'groupadmins':
                    return Boolean(user !== undefined && user.groupsAdmin && user.groupsAdmin.length > 0);
                case 'admins':
                    return Boolean(user !== undefined && [2, 3].includes(user.role));
                default:
                    return false;
            }
        case CompanyDetailsPrivilege.TimeClock.Edition:
            if (company === undefined || user === undefined || !company.isTimeClockEnabled) return false;
            switch (company.timeClockConfirmAuthorization) {
                case 'groupadmin':
                    if (user !== undefined && [2, 3].includes(user.role)) {
                        return true;
                    } else if (user !== undefined && user.groupsAdmin && user.groupsAdmin.length > 0) {
                        return Boolean(checkedValue !== undefined && checkedValue !== user.id);
                    }
                    return false;

                case 'groupadmin+':
                    if (user !== undefined && [2, 3].includes(user.role)) {
                        return true;
                    } else if (user !== undefined && user.groupsAdmin && user.groupsAdmin.length > 0) {
                        return true;
                    }
                    return false;

                case 'admin':
                    if (user !== undefined && [2, 3].includes(user.role)) {
                        return true;
                    }
                    return false;
                default:
                    return false;
            }
        case CompanyDetailsPrivilege.Overtime.Visit:
        case CompanyDetailsPrivilege.Overtime.Edition:
            if (company === undefined || user === undefined || company.isTimeClockEnabled) return false;
            if ([2, 3].includes(user.role) || (user.groupsAdmin && user.groupsAdmin.length > 0)) return true;
            return false;
        default:
            return false;
    }
};

export const isDevMode = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

/**
 * 
 * @param array 
 * @returns 
 */
export function isNullOrEmpty(array: Array<any> | undefined | null | string): array is null | undefined | [] {
    return array === undefined || array === null || array.length === 0;
}
export const capitalize_first_letter = (value: string, by_words = false) => {
    if (by_words) {
        const _value = value.split(" ");
        for (let i = 0; i < _value.length; i++) {
            _value[i] = _value[i].charAt(0).toUpperCase() + _value[i].slice(1);
        }
        return _value.join(" ");
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
};
export const getCaseAndPlural = (value: string, plurals = false, case_type = CaseType.FIRST_LETTER_UPPERCASE) => {
    let _value = value;
    if (plurals) {
        const _splitted_value = value.split(" ");
        _splitted_value[0] = _splitted_value[0] + 's';
        _value = _splitted_value.join(" ");
    }

    switch (case_type) {
        case CaseType.FIRST_LETTER_UPPERCASE:
            return capitalize_first_letter(_value);
        case CaseType.FULL_LOWERCASE:
            return _value.toLowerCase();
        case CaseType.FULL_UPERCASE:
            return _value.toUpperCase();
        case CaseType.WORDS_FIRST_LETTER_UPPERCASE:
            return capitalize_first_letter(_value, true);
        default:
            return capitalize_first_letter(_value);
    }
};

/**
 * Convert network statistics to general statistics
 * @param statistics the network statistics to convert
 * @returns the converted statistics
 */
export const convertNetworkStatisticsToStatistics = (statistcs: NetworkStatistics): Statistics => ({
    ...statistcs,
    planning: {
        nbEvents: statistcs.planning.nbEvents,
        todayEvents: convertNetworkUserEventsToPlanningUserEvents(statistcs.planning.todayEvents, []),
        startingTodayEvents: convertNetworkUserEventsToPlanningUserEvents(statistcs.planning.startingTodayEvents, []),
        endingTodayEvents: convertNetworkUserEventsToPlanningUserEvents(statistcs.planning.endingTodayEvents, []),
    }
});


/* Planning */

export const getIntervalText = (frequence: Frequency, interval: number) => { //Never used
    let string = "";
    switch (frequence) {
        case EventFrequences.Hourly:
            string = "heure";
            break;
        case EventFrequences.Daily:
            string = "jour";
            break;
        case EventFrequences.Weekly:
            string = "semaine";
            break;
        case EventFrequences.Monthly:
            string = "mois";
            break;
        case EventFrequences.Yearly:
            string = "année";
            break;
    }

    return string += interval > 1 && frequence !== EventFrequences.Monthly ? "s" : "";
};

export const getFrequenceText = (frequence: Frequency) => {
    switch (frequence) {
        case EventFrequences.Hourly:
            return "Chaque heure";
        case EventFrequences.Daily:
            return "Quotidienne";
        case EventFrequences.Weekly:
            return "Hebdomadaire";
        case EventFrequences.Monthly:
            return "Mensuelle";
        case EventFrequences.Yearly:
            return "Annuelle";
    }
};

export const getWeekdayText = (weekday: number | undefined) => {
    if (weekday === undefined) return '';
    switch (weekday) {
        case EventWeekdays.Monday:
            return "Lundi";
        case EventWeekdays.Tuesday:
            return "Mardi";
        case EventWeekdays.Wednesday:
            return "Mercredi";
        case EventWeekdays.Thursday:
            return "Jeudi";
        case EventWeekdays.Friday:
            return "Vendredi";
        case EventWeekdays.Saturday:
            return "Samedi";
        case EventWeekdays.Sunday:
        default:
            return "Dimanche";
    }
};

/**
 * Get a weekday object from a value
 * @param value the integer value
 */
export const getWeekdayWithValue = (value: number): Weekday => {
    switch (value) {
        case RRule.MO.weekday:
            return RRule.MO;
        case RRule.TU.weekday:
            return RRule.TU;
        case RRule.WE.weekday:
            return RRule.WE;
        case RRule.TH.weekday:
            return RRule.TH;
        case RRule.FR.weekday:
            return RRule.FR;
        case RRule.SA.weekday:
            return RRule.SA;
        default:
            return RRule.SU;
    }
};

/**
 * Get the occupancy rate frequency text
 * @param value the integer value
 */
export const getOccupancyRateFrequencyText = (value: OccupancyRateFrequency): MessageDescriptor => {
    switch (value) {
        case OccupancyRateFrequency.Daily:
            return defineMessage({ defaultMessage: 'Day' });
        case OccupancyRateFrequency.Weekly:
            return defineMessage({ defaultMessage: 'Week' });
        case OccupancyRateFrequency.Monthly:
            return defineMessage({ defaultMessage: 'Month' });
        default:
            return defineMessage({ defaultMessage: 'Year' });
    }
};

/**
 * Get an occupancy rate summary text 
 * @param occupancyRate the concerned occupancy rate
 * @returns the summary text
 */
export const getOccupancyRateSummaryText = (intl: IntlShape, occupancyRate: PlanningOccupancyRate): string => {
    let { typeOfDay } = occupancyRate;
    const { staffType, poi, quantity, frequency, rrule, startDate, endDate, startHour, endHour } = occupancyRate;

    if (!typeOfDay || (typeOfDay && Object.keys(typeOfDay).length === 0 && typeOfDay.constructor === Object)) {
        typeOfDay = undefined;
    }

    const weekdays = rrule?.byweekday && rrule.byweekday.length > 0 ?
        rrule.byweekday.slice(1).reduce((acc, val) => acc + ", " + getWeekdayText(val)?.toLowerCase(), getWeekdayText(rrule.byweekday[0])?.toLowerCase())
        : '';

    const frequencyText = rrule?.byweekday && rrule?.byweekday.length > 0 ? weekdays : intl.formatMessage(getOccupancyRateFrequencyText(frequency));
    return intl.formatMessage(
        { defaultMessage: 'Each {frequency}, {from} check that at least {event} {time} {type} {ability} {location} {planned}' },
        {
            frequency: frequencyText,
            from: startDate && endDate ? intl.formatMessage({ defaultMessage: 'from {start} to {end},' }, { start: startDate ? startDate.format(getFormat('DAY_SHORT_AND_MONTH_HALF_AND_YEAR')) : '', end: endDate ? endDate.format(getFormat('DAY_SHORT_AND_MONTH_HALF_AND_YEAR')) : '' }) : '',
            event: intl.formatMessage({ defaultMessage: '{count, plural, one {{count} event} other {{count} events}}' }, { count: quantity }),
            time: startHour && endHour ? `(${startHour.seconds() !== 0 ? startHour.format(getFormat('TIME')) : startHour.format(getFormat('TIME_SHORT'))} -> ${endHour.seconds() !== 0 ? endHour.format(getFormat('TIME')) : endHour.format(getFormat('TIME_SHORT'))})` : '',
            type: typeOfDay ? intl.formatMessage({ defaultMessage: 'of the {type} day type' }, { type: typeOfDay.title }) : '',
            ability: staffType ? intl.formatMessage({ defaultMessage: 'with {ability} ability' }, { ability: staffType.name }) : '',
            location: poi ? intl.formatMessage({ defaultMessage: 'at {location} location' }, { location: poi.title }) : '',
            planned: intl.formatMessage({ defaultMessage: '{count, plural, one {is present in the planning} other {are present in the planning}}' }, { count: quantity })
        });
};

/**
 * Check if there are rrule
 * @returns true if there are any rules, otherwise false
 */
export const checkRrule = (rrule?: RRuleOptions) => {
    if (rrule) {
        return Object.keys(rrule).filter(k => {
            if (!rrule[k]) return false;
            if (Array.isArray(rrule[k])) return rrule[k].length > 0;
            return true;
        }).length > 0;
    } else {
        return false;
    }
};

/**
  * Convert the network settings into planning compatible settings
  * @param settings the network settings
  * @returns the planning settings
  */
export const convertNetworkSettingsToPlanningSettings = (settings: NetworkSettings): PlanningSettings => ({
    ...settings,
    groupAdminWriteAuthorization: settings.groupAdminWriteAuthorization,
    workingDaysOfWeek: settings.workingDaysOfWeek ? settings.workingDaysOfWeek.split(",").map((str: string) => parseInt(str)) : undefined,
    startHourOfDay: settings.startHourOfDay ? moment(settings.startHourOfDay, MOMENT_FORMAT_TIME_TO_NETWORK) : undefined,
    endHourOfDay: settings.endHourOfDay ? moment(settings.endHourOfDay, MOMENT_FORMAT_TIME_TO_NETWORK).minutes() === 59 ? moment(settings.endHourOfDay, MOMENT_FORMAT_TIME_TO_NETWORK).seconds(59) : (moment(settings.endHourOfDay, MOMENT_FORMAT_TIME_TO_NETWORK)) : undefined,
    colors: settings.colors,
});


/**
  * Convert the planning settings into network compatible settings
  * @param settings the planning settings
  * @returns the network settings
  */
export const convertPlanningSettingsToNetworkSettings = (settings: PlanningSettings): NetworkSettings => {
    const endHourOfDay = (settings.endHourOfDay as Moment).minutes() === 59 ? (settings.endHourOfDay as Moment).seconds(59).format('HH:mm:ss') : (settings.endHourOfDay as Moment)?.format('HH:mm:ss');
    return ({
        ...settings,
        workingDaysOfWeek: (settings.workingDaysOfWeek as number[])?.join(","),
        startHourOfDay: (settings.startHourOfDay as Moment)?.format(MOMENT_FORMAT_TIME_TO_NETWORK),
        endHourOfDay: endHourOfDay,
        colors: settings.colors?.filter(c => c.color.length > 0 && c.title.length > 0).map(c => ({
            id: c.id && c.id < 0 ? undefined : c.id,
            title: c.title,
            color: c.color,
        })),
        checkEventsOverlap: settings.checkEventsOverlap
    });
};



export const convertUserYearlyParamsToNetworkUserYearlyParams = (userYearlyParams: UserYearlyParams): NetworkUserYearlyParams => {
    return ({
        ...userYearlyParams,
        userJob: userYearlyParams.userJob.id
    });
};


/* Events */

/**
 * Rrule string to rrule object
 * @param rrule the rrule string
 * @returns the rrule object
 */
export const rruleToRules = (rrule: string | undefined): RRuleOptions | undefined => {
    if (!rrule) return undefined;
    if (typeof rrule === "string") {
        const options = RRule.fromString(rrule).options;

        return {
            rrule: {
                freq: options.freq,
                dtstart: options.dtstart,
                interval: options.interval,
                until: options.until,
                count: options.count,
                byweekday: options.byweekday,
            },
            finish: options.until ? EventDataType.Until : (options.count ? EventDataType.Count : undefined),
        };
    } else if (typeof rrule === "object") {

        const rruleTmp: RRuleOptionsBeforeConvert = rrule;
        const rruleDef: RRuleOptions = {
            ...rruleTmp,
            dtstart: rruleTmp.dtstart === null ? null : rruleTmp.dtstart === undefined ? undefined : new Date(rruleTmp.dtstart)
        };
        return {
            rrule: {
                freq: rruleDef.freq,
                dtstart: rruleDef.dtstart,
                interval: rruleDef.interval,
                until: rruleDef.until,
                count: rruleDef.count,
                byweekday: rruleDef.byweekday,
            },
            finish: rruleDef.until ? EventDataType.Until : (rruleDef.count ? EventDataType.Count : undefined),
        };
    } else {
        return undefined;
    }
};

/**
 * Check if an event happen during at least one period
 * @param event the event
 * @param periods the periods
 * @returns true if the event happend during at least one period, false otherwise
 */
export const checkEventHappenDuringPeriod = (event: PlanningEvent, periods: PlanningEffectivePeriod[]): boolean => {
    for (let i = 0; i < periods.length; ++i) {
        if (event.startDate.diff(periods[i].endDate, 'minutes') <= 0 && event.endDate.diff(periods[i].startDate, 'minutes') >= 0) {
            return true;
        }
    }
    return false;
};

/**
 * Check if an event start and end dates happen during at least one period
 * @param event the event
 * @param periods the periods
 * @returns true if the event dates happend during at least one period, false otherwise
 */
export const checkEventDatesHappenDuringPeriod = ({ startDate, endDate }: PlanningEvent, periods: PlanningEffectivePeriod[]): boolean => {
    for (let i = 0; i < periods.length; ++i) {
        if (periods[i].startDate.diff(startDate, 'minutes') <= 0 && periods[i].endDate.diff(startDate, 'minutes') >= 0) {
            // start date is during a period, check end date
            for (let j = 0; j < periods.length; ++i) {
                if (periods[i].startDate.diff(endDate, 'minutes') <= 0 && periods[i].endDate.diff(endDate, 'minutes') >= 0) {
                    // end date is during a period, return true
                    return true;
                }
            }
        }
    }

    return false;
};

/**
 * Check if an event start and end dates happen during the working hours
 * @param event the event
 * @param settings the settings
 * @returns true if the event dates happend during the working hours
 */
export const checkEventDatesHappenDuringWorkingHours = ({ startDate, endDate }: PlanningEvent, settings: PlanningSettings): boolean => {
    if (settings && settings.startHourOfDay && settings.endHourOfDay) {
        const workStart = settings.startHourOfDay!.hours() + (settings.startHourOfDay!.minutes() / 60);
        const workEnd = settings.endHourOfDay!.hours() + (settings.endHourOfDay!.minutes() / 60);
        const eventStart = startDate.hours() + (startDate.minutes() / 60);
        const eventEnd = endDate.hours() + (endDate.minutes() / 60);

        return workStart <= eventStart && eventEnd <= workEnd;
    }
    return true;
};

/**
 * Convert a network event to a planning event
 * @param event the network event to convert
 * @return a planning event
 */
export const convertNetworkEventToPlanningEvent = (event: NetworkEvent, isDayLocked?: boolean): PlanningEvent => {
    return {
        ...event,
        reminders: event.reminders !== undefined ? convertNetworkRemindersToReminders(event.reminders) : undefined,
        breakTimes: event.breakTimes !== undefined ? convertNetworkBreakTimesToBreakTimes(event.breakTimes) : undefined,
        overtimes: event.overtimes !== undefined ? convertNetworkOvertimesToPlanningOvertimes(event.overtimes) : undefined,
        project: event.project,
        startDate: moment(event.startDate),
        endDate: moment(event.endDate),
        timerStart: event.timerStart ? moment(event.timerStart) : undefined,
        timerStop: event.timerStop ? moment(event.timerStop) : undefined,
        created: event.created ? moment(event.created) : undefined,
        modified: event.modified ? moment(event.modified) : undefined,
        owner: event.userId ? PlanningEventOwner.User : (event.groupId ? PlanningEventOwner.Group : PlanningEventOwner.Global),
        clocked: event.clocked ? convertNetworkEventClockedSmallToEventClockedSmall(event.clocked) : undefined,
        ruleId: event.ruleId,
        eventLocked: event.eventLocked || isDayLocked ? true : false

    };

};

export const getCompanyType = (company: Company) => {
    switch (company.type) {
        case CompanyTypes.OLD:
            return CompanyTypes.OLD;
        case CompanyTypes.NORMAL:
            return CompanyTypes.NORMAL;
        case CompanyTypes.CCNT:
            return CompanyTypes.CCNT;
        case CompanyTypes.SECURITY:
            return CompanyTypes.SECURITY;
        default:
            return undefined;
    }
};

/**
 * Convert a network break time into a break time
 * @param breakTime the network break time
 * @return the converted break time
 */
export const convertNetworkBreakTimeToBreakTime = (breakTime: NetworkBreakTime): BreakTime => {
    return {
        ...breakTime,
        startDate: moment(breakTime.startDate).second(0),
        endDate: moment(breakTime.endDate).second(0),
        clocked: breakTime.clocked ? {
            ...breakTime.clocked,
            startDate: breakTime.clocked.startDate ? moment(breakTime.clocked.startDate) : undefined,
            endDate: breakTime.clocked.endDate ? moment(breakTime.clocked.endDate) : undefined,
            manualStartDate: breakTime.clocked.manualStartDate ? moment(breakTime.clocked.manualStartDate) : undefined,
            manualEndDate: breakTime.clocked.manualEndDate ? moment(breakTime.clocked.manualEndDate) : undefined,
            adminStartDate: breakTime.clocked.adminStartDate ? moment(breakTime.clocked.adminStartDate) : undefined,
            adminEndDate: breakTime.clocked.adminEndDate ? moment(breakTime.clocked.adminEndDate) : undefined,
            created: moment(breakTime.clocked.created),
            modified: breakTime.clocked.modified ? moment(breakTime.clocked.modified) : undefined,
        } : undefined

    };
};

export const convertNetworkReminderToReminder = (reminder: NetworkReminder): Reminder => {
    return {
        ...reminder,
        created: moment(reminder.created),
        modified: reminder.modified ? moment(reminder.modified) : undefined,
    };
};
export const convertNetworkEventClockedToEventClocked = (eventClocked: NetworkEventClocked, users: User[]): EventClocked => {
    const user = users.find(u => u.id == eventClocked.userId);

    return {
        ...eventClocked,
        title: eventClocked.name,
        user: {
            id: eventClocked.userId,
            name: `${user?.last_name} ${user?.first_name}`,
            avatar: user?.image!,
        },
        startDate: eventClocked.startDate ? moment(eventClocked.startDate) : undefined,
        endDate: eventClocked.endDate ? moment(eventClocked.endDate) : undefined,
        manualStartDate: eventClocked.manualStartDate ? moment(eventClocked.manualStartDate) : undefined,
        manualEndDate: eventClocked.manualEndDate ? moment(eventClocked.manualEndDate) : undefined,
        adminStartDate: eventClocked.adminStartDate ? moment(eventClocked.adminStartDate) : undefined,
        adminEndDate: eventClocked.adminEndDate ? moment(eventClocked.adminEndDate) : undefined,
        planStartDate: eventClocked.planStartDate ? moment(eventClocked.planStartDate) : undefined,
        planEndDate: eventClocked.planEndDate ? moment(eventClocked.planEndDate) : undefined,
        breakTimesClocked: eventClocked.breakTimesClocked ? eventClocked.breakTimesClocked.map(breakTClocked => ({
            ...breakTClocked,
            startDate: breakTClocked.startDate ? moment(breakTClocked.startDate) : undefined,
            endDate: breakTClocked.endDate ? moment(breakTClocked.endDate) : undefined,
            manualStartDate: breakTClocked.manualStartDate ? moment(breakTClocked.manualStartDate) : undefined,
            manualEndDate: breakTClocked.manualEndDate ? moment(breakTClocked.manualEndDate) : undefined,
            adminStartDate: breakTClocked.adminStartDate ? moment(breakTClocked.adminStartDate) : undefined,
            adminEndDate: breakTClocked.adminEndDate ? moment(breakTClocked.adminEndDate) : undefined,
            created: moment(breakTClocked.created),
            modified: breakTClocked.modified ? moment(breakTClocked.modified) : undefined,
        })) : undefined
    };
};

export const generatePartialEventClockedFromNetworkEvent = (event: NetworkEventWithEventClocked, users: User[]): EventClocked => {
    const user = users.find(u => u.id === event.userId);
    return {
        color: event.color,
        created: moment().toISOString(),
        description: '',
        error: false,
        eventId: event.id,
        id: generateNegativeUniqueId(),
        isConfirmed: false,
        isManual: false,
        isRefused: false,
        modified: moment().toISOString(),
        name: event.title,
        title: event.title,
        user: {
            id: event.userId,
            name: `${user?.last_name} ${user?.first_name}`,
            avatar: user?.image,
        },
        userId: event.userId,
        planStartDate: moment(event.startDate),
        planEndDate: moment(event.endDate),
        adminStartDate: moment(event.startDate),
        adminEndDate: moment(event.endDate),
    };
};

export const convertNetworkEventClockedsToEventClockeds = (eventClocked: NetworkEventClocked[], users: User[]): EventClocked[] => eventClocked.map(ec => convertNetworkEventClockedToEventClocked(ec, users));

export const convertNetworkEventClockedSmallToEventClockedSmall = (eventClocked: NetworkEventClockedSmall): EventClockedSmall => {
    return {
        ...eventClocked,
        startDate: eventClocked.startDate ? moment(eventClocked.startDate) : undefined,
        endDate: eventClocked.endDate ? moment(eventClocked.endDate) : undefined,
        manualStartDate: eventClocked.manualStartDate ? moment(eventClocked.manualStartDate) : undefined,
        manualEndDate: eventClocked.manualEndDate ? moment(eventClocked.manualEndDate) : undefined,
        adminStartDate: eventClocked.adminStartDate ? moment(eventClocked.adminStartDate) : undefined,
        adminEndDate: eventClocked.adminEndDate ? moment(eventClocked.adminEndDate) : undefined,
        planStartDate: moment(eventClocked.planStartDate),
        planEndDate: moment(eventClocked.planEndDate),
        breakTimesClocked: eventClocked.breakTimesClocked.map(breakTClocked => ({
            ...breakTClocked,
            startDate: breakTClocked.startDate ? moment(breakTClocked.startDate) : undefined,
            endDate: breakTClocked.endDate ? moment(breakTClocked.endDate) : undefined,
            manualStartDate: breakTClocked.manualStartDate ? moment(breakTClocked.manualStartDate) : undefined,
            manualEndDate: breakTClocked.manualEndDate ? moment(breakTClocked.manualEndDate) : undefined,
            adminStartDate: breakTClocked.adminStartDate ? moment(breakTClocked.adminStartDate) : undefined,
            adminEndDate: breakTClocked.adminEndDate ? moment(breakTClocked.adminEndDate) : undefined,
            created: moment(breakTClocked.created),
            modified: breakTClocked.modified ? moment(breakTClocked.modified) : undefined,
        }))
    };
};
export const convertNetworkEventClockedSmallsToEventClockedSmalls = (eventClocked: NetworkEventClockedSmall[]): EventClockedSmall[] => eventClocked.map(ec => convertNetworkEventClockedSmallToEventClockedSmall(ec));

export const getEventClockedStartedOrManualOrAdmin = (eventClocked: EventClockedSmall): EventClockedDates => {
    if (eventClocked.adminStartDate && eventClocked.adminEndDate) {
        return {
            startDate: eventClocked.adminStartDate!,
            endDate: eventClocked.adminEndDate,
            breakTimeDuration: eventClocked.breakTimesClocked.reduce((value, breakTimeClocked) => value = value + (((breakTimeClocked.manualIsPaid && breakTimeClocked.adminIsPaid === undefined) || (breakTimeClocked.adminIsPaid) || (breakTimeClocked.adminIsPaid === undefined && breakTimeClocked.manualIsPaid === undefined && breakTimeClocked.isPaid)) ? breakTimeClocked.totalHours : 0), 0)

        };
    }
    else if (eventClocked.isManual)
        return {
            startDate: eventClocked.manualStartDate!,
            endDate: eventClocked.manualEndDate,
            breakTimeDuration: eventClocked.breakTimesClocked.reduce((value, breakTimeClocked) => value = value + (((breakTimeClocked.manualIsPaid && breakTimeClocked.adminIsPaid === undefined) || (breakTimeClocked.adminIsPaid) || (breakTimeClocked.adminIsPaid === undefined && breakTimeClocked.manualIsPaid === undefined && breakTimeClocked.isPaid)) ? breakTimeClocked.totalHours : 0), 0)

        };
    else
        return {
            startDate: eventClocked.startDate,
            endDate: eventClocked.endDate ? eventClocked.endDate : undefined,
            breakTimeDuration: eventClocked.breakTimesClocked.reduce((value, breakTimeClocked) => value = value + (((breakTimeClocked.manualIsPaid && breakTimeClocked.adminIsPaid === undefined) || (breakTimeClocked.adminIsPaid) || (breakTimeClocked.adminIsPaid === undefined && breakTimeClocked.manualIsPaid === undefined && breakTimeClocked.isPaid)) ? breakTimeClocked.totalHours : 0), 0)

        };
};
/**
 * Convert an array of network break times into an array of break times
 * @param breakTimes the array of network break times
 * @return the array of converted break times
 */
export const convertNetworkBreakTimesToBreakTimes = (breakTimes: NetworkBreakTime[]): BreakTime[] => breakTimes.map(bt => convertNetworkBreakTimeToBreakTime(bt));


export const convertNetworkRemindersToReminders = (reminders: NetworkReminder[]): Reminder[] => reminders.map(r => convertNetworkReminderToReminder(r));
/**
 * Convert a project event to project event excel
 * @param event the project event to convert
 * @return a project event excel
 */
export const convertProjectEventToProjectEventExcel = (event: ProjectEvent): ProjectEventExcel => {
    return {
        dateFrom: moment(event.dateFrom).format("DD/MM/YYYY"),
        title: event.title,
        userName: event.userName,
        startTime: moment(event.dateFrom).format("HH:mm"),
        endTime: moment(event.dateTo).format("HH:mm"),
        overtimesHours: event.overtimesHours ? event.overtimesHours.toString() : '',
        effectiveHours: event.effectiveHours ? event.effectiveHours.toString() : '',
        projectName: event.project?.title ? event.project.title : '',
        userNote: event.userNote,
    };
};

/**
 * Convert an array of project event into an array of project event excel
 * @param events the array of project events
 * @return the array of converted project event excel
 */
export const convertProjectEventsToProjectEventsExcel = (events: ProjectEvent[]): ProjectEventExcel[] => events.map(e => convertProjectEventToProjectEventExcel(e));

/**
 * Convert an array of network event into an array of planning events
 * @param events the array of network events
 * @return the array of converted planning events
 */
export const convertNetworkEventsToPlanningEvents = (events: NetworkEvent[]): PlanningEvent[] => events.map(e => convertNetworkEventToPlanningEvent(e));


export const convertNetworkEventsToPlanningEventsV2 = (events: NetworkEvent[]): PlanningEvent[] => events.map(e => convertNetworkEventToPlanningEventV2(e, store.getState() as ApplicationState));

export const convertNetworkEventToPlanningEventV2 = (event: NetworkEvent, state: ApplicationState): PlanningEvent => {
    return {
        ...event,
        startDate: moment.utc(event.startDate),
        endDate: moment.utc(event.endDate),
        reminders: event.reminders !== undefined ? convertNetworkRemindersToReminders(event.reminders) : undefined,
        breakTimes: event.breakTimes !== undefined ? convertNetworkBreakTimesToBreakTimes(event.breakTimes) : undefined,
        overtimes: event.overtimes !== undefined ? convertNetworkOvertimesToPlanningOvertimes(event.overtimes) : undefined,
        project: event.projectId ? state.configurations.project.find(p => p.id === event.projectId) : event.project,
        typeOfDay: event.typeOfDayId ? state.configurations.typesOfDay.find(p => p.id === event.typeOfDayId) : event.typeOfDay,
        typeOfDayOff: event.typeOfDayOffId ? state.configurations.typesOfDayOff.find(p => p.id === event.typeOfDayOffId) : event.typeOfDayOff,
        color: event.colorId ? state.planning.settings.colors?.find(p => p.id === event.colorId) : event.color,
        poi: event.poiId ? state.location.pois?.data?.find(p => p.id === event.poiId) : event.poi,
        timerStart: event.timerStart ? moment(event.timerStart) : undefined,
        timerStop: event.timerStop ? moment(event.timerStop) : undefined,
        created: event.created ? moment(event.created) : undefined,
        modified: event.modified ? moment(event.modified) : undefined,
        owner: event.userId ? PlanningEventOwner.User : (event.groupId ? PlanningEventOwner.Group : PlanningEventOwner.Global),
        clocked: event.clocked ? convertNetworkEventClockedSmallToEventClockedSmall(event.clocked) : undefined

    };

};

/**
 * Convert a planning event to a planning event
 * @param event the planning event to convert
 * @return a network event
 */
export const convertPlanningEventToNetworkEvent = (event: PlanningEvent): NetworkEvent => {
    return {
        ...event,
        reminders: event.reminders !== undefined ? convertRemindersToNetworkReminders(event.reminders) : undefined,
        breakTimes: event.breakTimes !== undefined ? convertBreakTimesToNetworkBreakTimes(event.breakTimes) : undefined,
        overtimes: undefined, //TODO convert like breaktimes
        startDate: event.startDate.format(MOMENT_FORMAT),
        endDate: event.endDate.format(MOMENT_FORMAT),
        timerStart: event.timerStart?.format(MOMENT_FORMAT),
        timerStop: event.timerStop?.format(MOMENT_FORMAT),
        created: event.created?.format(MOMENT_FORMAT),
        modified: event.modified?.format(MOMENT_FORMAT),
        id: event.id,
        clocked: undefined,
    };
};

/**
 * Convert a break time into a network break time
 * @param breakTime the break time
 * @return the converted network break time
 */
export const convertBreakTimeToNetworkBreakTime = (breakTime: BreakTime): NetworkBreakTime => {
    return {
        ...breakTime,
        startDate: moment(breakTime.startDate).second(0).format(MOMENT_FORMAT),
        endDate: moment(breakTime.endDate).second(0).format(MOMENT_FORMAT),
        clocked: breakTime.clocked ? {
            ...breakTime.clocked,
            startDate: breakTime.clocked.startDate ? moment(breakTime.clocked.startDate).format(MOMENT_FORMAT) : undefined,
            endDate: breakTime.clocked.endDate ? moment(breakTime.clocked.endDate).format(MOMENT_FORMAT) : undefined,
            manualStartDate: breakTime.clocked.manualStartDate ? moment(breakTime.clocked.manualStartDate).format(MOMENT_FORMAT) : undefined,
            manualEndDate: breakTime.clocked.manualEndDate ? moment(breakTime.clocked.manualEndDate).format(MOMENT_FORMAT) : undefined,
            adminStartDate: breakTime.clocked.adminStartDate ? moment(breakTime.clocked.adminStartDate).format(MOMENT_FORMAT) : undefined,
            adminEndDate: breakTime.clocked.adminEndDate ? moment(breakTime.clocked.adminEndDate).format(MOMENT_FORMAT) : undefined,
            created: moment(breakTime.clocked.created).format(MOMENT_FORMAT),
            modified: breakTime.clocked.modified ? moment(breakTime.clocked.modified).format(MOMENT_FORMAT) : undefined,
        } : undefined

    };
};

export const convertReminderToNetworkReminder = (reminder: Reminder): NetworkReminder => {
    return {
        ...reminder,
        created: moment(reminder.created).format(MOMENT_FORMAT),
        modified: reminder.modified ? moment(reminder.modified).format(MOMENT_FORMAT) : undefined,
    };
};


/**
 * Convert an array of break times into an array of network break times
 * @param breakTime the array of break times
 * @return the array of converted network break times
 */
export const convertBreakTimesToNetworkBreakTimes = (breakTimes: BreakTime[]): NetworkBreakTime[] => breakTimes.map(bt => convertBreakTimeToNetworkBreakTime(bt));

export const convertRemindersToNetworkReminders = (reminders: Reminder[]): NetworkReminder[] => reminders.map(r => convertReminderToNetworkReminder(r));

/**
 * Convert a network template to a planning template
 * @param template the network template to convert
 * @return a planning template
 */
export const convertNetworkTemplateToPlanningTemplate = (template: NetworkTemplate): PlanningTemplate => {

    return {
        ...template,
        reminders: template.reminders !== undefined ? convertNetworkRemindersToReminders(template.reminders) : undefined,
        breakTimes: template.breakTimes !== undefined ? convertNetworkBreakTimesToBreakTimes(template.breakTimes) : undefined,
        overtimes: template.overtimes !== undefined ? convertNetworkOvertimesToPlanningOvertimes(template.overtimes) : undefined,
        project: template.project,
        startDate: moment(template.startDate),
        endDate: moment(template.endDate),
        timerStart: template.timerStart ? moment(template.timerStart) : undefined,
        timerStop: template.timerStop ? moment(template.timerStop) : undefined,
        created: template.created ? moment(template.created) : undefined,
        modified: template.modified ? moment(template.modified) : undefined,
        clocked: undefined
    };
};

/**
 * Convert an array of network templates into an array of planning templates
 * @param events the array of network templates
 * @return the array of converted planning templates
 */
export const convertNetworkTemplatesToPlanningTemplates = (templates: NetworkTemplate[]): PlanningTemplate[] => templates.map(t => convertNetworkTemplateToPlanningTemplate(t));

/**
 * Convert a planning template to a network template
 * @param template the planning template to convert
 * @return a network template
 */
export const convertPlanningTemplateToNetworkTemplate = (template: PlanningTemplate): NetworkTemplate => {

    return {
        ...template,
        breakTimes: template.breakTimes !== undefined ? convertBreakTimesToNetworkBreakTimes(template.breakTimes) : undefined,
        overtimes: undefined, //TODO convert like breaktimes
        reminders: template.reminders !== undefined ? convertRemindersToNetworkReminders(template.reminders) : undefined,
        startDate: template.startDate.format(MOMENT_FORMAT),
        endDate: template.endDate.format(MOMENT_FORMAT),
        timerStart: template.timerStart?.format(MOMENT_FORMAT),
        timerStop: template.timerStop?.format(MOMENT_FORMAT),
        created: template.created?.format(MOMENT_FORMAT),
        modified: template.modified?.format(MOMENT_FORMAT),
        clocked: undefined,
    };
};

export const convertNetworkTemplatesToITemplates = (templates: NetworkTemplate[]): ITemplate[] => templates.map(t => convertNetworkTemplateToITemplate(t));

export const convertITemplateToNetworkTemplate = (template: ITemplate): NetworkTemplate => {

    return {
        ...template,
        breakTimes: template.breakTimes !== undefined ? convertIBreakTimesToNetworkBreakTimes(template.breakTimes) : undefined,
        overtimes: undefined, //TODO convert like breaktimes
        reminders: template.reminders !== undefined ? convertRemindersToNetworkReminders(template.reminders) : undefined,
        startDate: moment(template.startDate).format(MOMENT_FORMAT),
        endDate: moment(template.endDate).format(MOMENT_FORMAT),
        timerStart: moment(template.timerStart)?.format(MOMENT_FORMAT),
        timerStop: moment(template.timerStop)?.format(MOMENT_FORMAT),
        created: moment(template.created)?.format(MOMENT_FORMAT),
        modified: moment(template.modified)?.format(MOMENT_FORMAT),
        clocked: undefined,
    };
};

export const convertNetworkTemplateToITemplate = (template: NetworkTemplate): ITemplate => {

    return {
        ...template,
        reminders: template.reminders !== undefined ? convertNetworkRemindersToReminders(template.reminders) : undefined,
        breakTimes: template.breakTimes !== undefined ? convertNetworkBreakTimesToIBreakTimes(template.breakTimes) : undefined,
        project: template.project,
        startDate: moment(template.startDate).format(MOMENT_FORMAT),
        endDate: moment(template.endDate).format(MOMENT_FORMAT),
        timerStart: template.timerStart ? moment(template.timerStart).format(MOMENT_FORMAT) : undefined,
        timerStop: template.timerStop ? moment(template.timerStop).format(MOMENT_FORMAT) : undefined,
        created: template.created ? moment(template.created).toISOString() : undefined,
        modified: template.modified ? moment(template.modified).toISOString() : undefined,
    };
};

export const convertNetworkBreakTimesToIBreakTimes = (breakTimes: NetworkBreakTime[]): IBreakTime[] => breakTimes.map(bt => convertNetworkBreakTimeToIBreakTime(bt));

export const convertNetworkBreakTimeToIBreakTime = (breakTime: NetworkBreakTime): IBreakTime => {
    return {
        ...breakTime,
        startDate: moment(breakTime.startDate).second(0).format(MOMENT_FORMAT),
        endDate: moment(breakTime.endDate).second(0).format(MOMENT_FORMAT),
    };
};

export const convertIBreakTimesToNetworkBreakTimes = (breakTimes: IBreakTime[]): NetworkBreakTime[] => breakTimes.map(bt => convertIBreakTimeToNetworkBreakTime(bt));

export const convertIBreakTimeToNetworkBreakTime = (breakTime: IBreakTime): NetworkBreakTime => {
    return {
        ...breakTime,
        startDate: moment(breakTime.startDate).second(0).format(MOMENT_FORMAT),
        endDate: moment(breakTime.endDate).second(0).format(MOMENT_FORMAT),
    };
};

export const convertIBreakTimesToSimpleBreaktimes = (breakTimes: IBreakTime[]): SimpleBreaktime[] => breakTimes.map(bt => convertIBreakTimeToSimpleBreakTime(bt));

export const convertIBreakTimeToSimpleBreakTime = (breakTime: IBreakTime): SimpleBreaktime => {
    return {
        ...breakTime,
        dateFrom: moment(breakTime.startDate).second(0),
        dateTo: moment(breakTime.endDate).second(0),
    };
};

export const convertIBreakTimesToBreaktimes = (breakTimes: IBreakTime[]): BreakTime[] => breakTimes.map(bt => convertIBreakTimeToBreakTime(bt));

export const convertIBreakTimeToBreakTime = (breakTime: IBreakTime): BreakTime => {
    return {
        ...breakTime,
        startDate: moment(breakTime.startDate).second(0),
        endDate: moment(breakTime.endDate).second(0),
    };
};

export const convertBreakTimesToIBreakTimes = (breakTimes: BreakTime[]): IBreakTime[] => breakTimes.map(bt => convertBreakTimeToIBreakTime(bt));

export const convertBreakTimeToIBreakTime = (breakTime: BreakTime): IBreakTime => {
    return {
        ...breakTime,
        startDate: breakTime.startDate.clone().second(0).format(MOMENT_FORMAT),
        endDate: breakTime.endDate.clone().second(0).format(MOMENT_FORMAT),
    };
};

export const convertITemplateToPlanningTemplate = (template: ITemplate, duration?: number, missionRuleType?: IMissionRuleType): PlanningTemplate => {
    return {
        ...template,
        title: template.title,
        created: moment(template.created),
        modified: moment(template.modified),
        timerStart: undefined,
        timerStop: undefined,
        breakTimes: template.breakTimes ? convertIBreakTimesToBreaktimes(template.breakTimes) : [],
        startDate: moment(template.startDate),
        endDate: moment(template.endDate),
        shouldLast: missionRuleType === IMissionRuleType.QUOTA ? undefined : duration,
    };
};

export const convertPlanningTemplateToITemplate = (template: PlanningTemplate): ITemplate => {
    return {
        ...template,
        startDate: template.startDate.format(MOMENT_FORMAT),
        endDate: template.endDate.format(MOMENT_FORMAT),
        timerStart: template.timerStart?.format(MOMENT_FORMAT),
        timerStop: template.timerStop?.format(MOMENT_FORMAT),
        created: template.created?.format(MOMENT_FORMAT),
        modified: template.modified?.format(MOMENT_FORMAT),
        breakTimes: template.breakTimes ? convertBreakTimesToIBreakTimes(template.breakTimes) : []
    };
};

export const convertITemplateToSimpleEvent = (template: ITemplate): SimpleEvent => {
    return {
        id: -1,
        title: template.title,
        breakTimes: template.breakTimes !== undefined ? convertIBreakTimesToSimpleBreaktimes(template.breakTimes) : [],
        dateFrom: moment(template.startDate),
        dateTo: moment(template.endDate),
        overtimes: []
    };
};

export const convertICostumersNetworkToICustomers = (costumers: ICustomerNetwork[]): ICustomer[] => costumers.map(o => convertICostumerNetworkToICustomer(o));

export const convertICostumerNetworkToICustomer = (costumer: ICustomerNetwork): ICustomer => ({
    ...costumer,
    created: moment(costumer.created),
    modified: moment(costumer.modified),
});

/**
 * Convert an array of network overtimes into an array of overtimes
 * @param overtimes the array of network overtimes
 * @return the array of converted overtimes
 */
export const convertNetworkOvertimesToPlanningOvertimes = (overtimes: NetworkOvertime[]): PlanningOvertime[] => overtimes.map(o => convertNetworkOvertimeToPlanningOvertime(o));


/**
 * Convert a network overtime to a planning overtime
 * @param overtime the overtime to convert
 * @returns the converted planning overtime
 */
export const convertNetworkOvertimeToPlanningOvertime = (overtime: NetworkOvertime): PlanningOvertime => ({
    ...overtime,
    startDate: moment(overtime.startDate),
    endDate: moment(overtime.endDate),
});

/**
 * Convert a planning overtime to a network overtime
 * @param overtime the overtime to convert
 * @returns the converted network overtime
 */
export const convertPlanningOvertimeToNetworkOvertime = (overtime: PlanningOvertime): NetworkOvertime => ({
    ...overtime,
    startDate: overtime.startDate.format(MOMENT_FORMAT),
    endDate: overtime.endDate.format(MOMENT_FORMAT),
});

/**
 * Convert the network user events into planning compatible user events
 * @param events the network events to convert
 * @returns a array of planning events
 */
export const convertNetworkUserEventsToPlanningUserEvents = (events: NetworkEvent[], periods: PlanningEffectivePeriod[]): PlanningEvent[] => {
    const planningEvents: PlanningEvent[] = [];

    //iterate over each events
    events.forEach((event: NetworkEvent) => {
        const newEvent = {
            ...event,
            reminders: event.reminders !== undefined ? convertNetworkRemindersToReminders(event.reminders) : undefined,
            breakTimes: event.breakTimes !== undefined ? convertNetworkBreakTimesToBreakTimes(event.breakTimes) : undefined,
            overtimes: event.overtimes !== undefined ? convertNetworkOvertimesToPlanningOvertimes(event.overtimes) : undefined,
            startDate: moment(event.startDate),
            endDate: moment(event.endDate),
            timerStart: event.timerStart ? moment(event.timerStart) : undefined,
            timerStop: event.timerStop ? moment(event.timerStop) : undefined,
            created: moment(event.created),
            modified: moment(event.modified),
            clocked: undefined,
        };
        if (checkEventHappenDuringPeriod(newEvent, periods)) {
            planningEvents.push(newEvent);
        }
    });

    return planningEvents.sort((a, b) => a.startDate.diff(b.startDate) < 0 ? -1 : 1);
};

/**
 * Make a clone of an event
 * @param event the event to clone
 * @returns the cloned event
 */
export const cloneEvent = (event: PlanningEvent): PlanningEvent => ({
    ...event,
    startDate: moment(event.startDate),
    endDate: moment(event.endDate),
    created: moment(event.created),
    modified: moment(event.modified),
    owner: event.userId ? PlanningEventOwner.User : (event.groupId ? PlanningEventOwner.Group : PlanningEventOwner.Global),
});


/* Periods - Exclusions */

/**
 * Convert a network period into a planning period
 * @param period the network period to convert
 * @return the converted planning period
 */
export const convertNetworkPeriodToPlanningPeriod = ({ id, title, user, group, startDate, endDate, rrule, isUserCreated, isRefused, isConfirmed }: NetworkPeriod): PlanningPeriod => {
    const options = rruleToRules(rrule);

    return {
        id,
        title,
        user,
        group,
        startDate: moment(startDate),
        endDate: moment(endDate),
        rrule: options?.rrule,
        finish: options?.finish,
        isUserCreated,
        isRefused,
        isConfirmed,
    };
};

/**
 * Convert a planning period into a network period
 * @param period the planning period to convert
 * @return the converted network period
 */
export const convertPlanningPeriodToNetworkPeriod = ({ id, title, user, group, startDate, endDate, rrule, isUserCreated, isRefused, isConfirmed }: PlanningPeriod): NetworkPeriod => {
    const options = rrule;

    //delete all undefined attributes
    if (options) {
        Object.keys(options!).forEach((k: string) => {
            if (!options![k]) delete options![k];
            if (Array.isArray(options![k]) && options![k].length === 0) delete options![k];
        });
    }

    // set as utc time
    if (options?.dtstart) options.dtstart = moment.utc(startDate.format(MOMENT_FORMAT)).toDate();

    return {
        id,
        title,
        user,
        group,
        userId: user?.id,
        groupId: group?.id,
        startDate: startDate.format(MOMENT_FORMAT),
        endDate: endDate.format(MOMENT_FORMAT),
        rrule: new RRule(options).toString(),
        isUserCreated,
        isRefused,
        isConfirmed,
    };
};

/**
 * Convert a network period array into a planning period array
 * @param period the network periods to convert
 * @return the converted planning periods array
 */
export const convertNetworkPeriodsToPlanningPeriods = (periods: NetworkPeriod[]): PlanningPeriod[] => periods.map(p => convertNetworkPeriodToPlanningPeriod(p));


/**
 * Convert a network period array into a planning period array
 * @param exclusion the network periods to convert
 * @return the converted planning periods array
 */
export const convertNetworkExclusionsToPlanningExclusions = (exclusions: NetworkExclusion[]): PlanningExclusion[] => exclusions.map(e => convertNetworkExclusionToPlanningExclusion(e));

/**
 * Clone a period
 * @param period the period to clone
 * @return the cloned period
 */
export const clonePeriod = ({ id, title, user, group, startDate, endDate, rrule, finish, isUserCreated, isConfirmed, isRefused }: PlanningPeriod): PlanningPeriod => ({
    id,
    title,
    user,
    group,
    startDate: moment(startDate),
    endDate: moment(endDate),
    rrule: {
        freq: rrule?.freq,
        dtstart: rrule && rrule.dtstart ? new Date(rrule.dtstart.getTime()) : undefined,
        interval: rrule?.interval,
        count: rrule?.count,
        until: rrule?.until,
        byweekday: rrule?.byweekday?.slice(),
    },
    finish,
    isUserCreated,
    isConfirmed,
    isRefused
});

export const clonePeriodEdit = ({ id, title, user, group, startDate, endDate, rrule, finish, isUserCreated, isConfirmed, isRefused }: PlanningPeriodEdit): PlanningPeriodEdit => ({
    id,
    title,
    user,
    group,
    startDate: startDate ? moment(startDate) : undefined,
    endDate: endDate ? moment(endDate) : undefined,
    rrule: {
        freq: rrule?.freq,
        dtstart: rrule && rrule.dtstart ? new Date(rrule.dtstart.getTime()) : undefined,
        interval: rrule?.interval,
        count: rrule?.count,
        until: rrule?.until,
        byweekday: rrule?.byweekday?.slice(),
    },
    finish,
    isUserCreated,
    isConfirmed,
    isRefused
});

/**
 * Clone an overtime
 * @param overtime the overtime to clone
 * @return the cloned overtime
 */
export const cloneOvertime = (overtime: PlanningOvertime): PlanningOvertime => ({
    ...overtime,
    startDate: moment(overtime.startDate),
    endDate: moment(overtime.endDate)
});


/**
 * Convert a network exclusion into a planning exclusion
 * @param exclusion the network exclusion to convert
 * @return the converted planning exclusion
 */
export const convertNetworkExclusionToPlanningExclusion = ({ id, user, group, title, startDate, endDate, rrule, is_user_created, is_confirmed, is_refused }: NetworkExclusion): PlanningExclusion => {
    const options = rruleToRules(rrule);

    return {
        id,
        title,
        user,
        group,
        startDate: moment(startDate),
        endDate: moment(endDate),
        rrule: options?.rrule,
        finish: options?.finish,
        isUserCreated: is_user_created,
        isConfirmed: is_confirmed,
        isRefused: is_refused,
    };
};


/**
 * Convert a planning exclusion into a network exclusion
 * @param exclusion the planning exclusion to convert
 * @return the converted network exclusion
 */
export const convertPlanningExclusionToNetworkExclusion = ({ id, user, group, title, startDate, endDate, rrule, isUserCreated, isConfirmed, isRefused }: PlanningExclusion): NetworkExclusion => {
    const options = rrule;

    //delete all undefined attributes
    if (options) {
        Object.keys(options!).forEach((k: string) => {
            if (!options![k]) delete options![k];
            if (Array.isArray(options![k]) && options![k].length === 0) delete options![k];
        });
    }

    // set as utc time
    if (options?.dtstart) options.dtstart = moment.utc(startDate.format(MOMENT_FORMAT)).toDate();

    return {
        id,
        title,
        user,
        group,
        userId: user?.id,
        groupId: group?.id,
        startDate: startDate.format(MOMENT_FORMAT),
        endDate: endDate.format(MOMENT_FORMAT),
        rrule: new RRule(options).toString(),
        is_user_created: isUserCreated,
        is_confirmed: isConfirmed,
        is_refused: isRefused
    };
};


/**
 * Convert a planning exclusion array into a network exclusion array
 * @param exclusion the planning exclusion array to convert
 * @return the converted network exclusion array
 */
export const convertPlanningExclusionsToNetworkExclusions = (exclusions: PlanningExclusion[]): NetworkExclusion[] => exclusions.map(e => convertPlanningExclusionToNetworkExclusion(e));

/**
 * Convert a network user vacations into a planning user vacations
 * @param vacations the network user vacations
 * @return the converted planning user vacations
 */
export const convertNetworkUserVacationsToPlanningUserVacations = (vacations: NetworkUserVacations): PlanningUserVacations => ({
    ...vacations,
    startDate: moment(vacations.startDate),
    endDate: moment(vacations.endDate),
});

/**
 * Convert a planning user vacations into a network user vacations
 * @param vacations the planning user vacations
 * @return the converted network user vacations
 */
export const convertPlanningUserVacationsToNetworkUserVacations = (vacations: PlanningUserVacations): NetworkUserVacations => ({
    ...vacations,
    startDate: vacations.startDate.format(MOMENT_FORMAT),
    endDate: vacations.endDate.format(MOMENT_FORMAT),
});

/**
 * Convert a network user extra vacations into a planning user extra vacations
 * @param vacations the network user extra vacations
 * @return the converted planning user extra vacations
 */
export const convertNetworkUserExtraVacationsToPlanningUserExtraVacations = (vacations: NetworkUserExtraVacations): PlanningUserExtraVacations => ({
    ...vacations,
    startDate: moment(vacations.startDate),
    endDate: moment(vacations.endDate),
});

/**
 * Convert a planning user vacations into a network user vacations
 * @param vacations the planning user extra vacations
 * @return the converted network user extra vacations
 */
export const convertPlanningUserExtraVacationsToNetworkUserExtraVacations = (vacations: PlanningUserExtraVacations): NetworkUserExtraVacations => ({
    ...vacations,
    startDate: vacations.startDate.format(MOMENT_FORMAT),
    endDate: vacations.endDate.format(MOMENT_FORMAT),
});

/**
 * Clone an exclusion
 * @param exclusion the exclusion to clone
 * @return the cloned exclusion
 */
export const cloneExclusion = ({ id, user, group, title, startDate, endDate, rrule, finish, isUserCreated, isConfirmed, isRefused }: PlanningExclusion): PlanningExclusion => ({
    id,
    user,
    group,
    title,
    finish,
    startDate: moment(startDate),
    endDate: moment(endDate),
    rrule: {
        freq: rrule?.freq,
        dtstart: rrule && rrule.dtstart ? new Date(rrule.dtstart.getTime()) : undefined,
        interval: rrule?.interval,
        count: rrule?.count,
        until: rrule?.until,
        byweekday: rrule?.byweekday?.slice(),
    },
    isUserCreated,
    isConfirmed,
    isRefused
});

export const cloneExclusionEdit = ({ id, user, group, title, startDate, endDate, rrule, finish }: PlanningExclusionEdit): PlanningExclusionEdit => ({
    id,
    user,
    group,
    title,
    finish,
    startDate: startDate ? moment(startDate) : undefined,
    endDate: endDate ? moment(endDate) : undefined,
    rrule: {
        freq: rrule?.freq,
        dtstart: rrule && rrule.dtstart ? new Date(rrule.dtstart.getTime()) : undefined,
        interval: rrule?.interval,
        count: rrule?.count,
        until: rrule?.until,
        byweekday: rrule?.byweekday?.slice(),
    },
});


/**
 * Convert a network effective period into a planning effective period
 * @param period the network effective period to convert
 * @return the converted planning effective period
 */
export const convertNetworkEffectivePeriodToPlanningEffectivePeriod = ({ startDate, endDate }: NetworkEffectivePeriod): PlanningEffectivePeriod => ({
    startDate: moment(startDate),
    endDate: moment(endDate),
});

/**
 * Convert a planning effective period into a network effective period
 * @param period the planning effective period to convert
 * @return the converted network effective period
 */
export const convertPlanningEffectivePeriodToNetworkEffectivePeriod = ({ startDate, endDate }: PlanningEffectivePeriod): NetworkEffectivePeriod => ({
    startDate: startDate.format(MOMENT_FORMAT),
    endDate: endDate.format(MOMENT_FORMAT),
});

/**
 * Convert a network effective period array into a planning effective period array
 * @param periods the network effective period array to convert
 * @return the converted planning effective period array
 */
export const convertNetworkEffectivePeriodsToPlanningEffectivePeriods = (periods: NetworkEffectivePeriod[]): PlanningEffectivePeriod[] => periods.map(p => convertNetworkEffectivePeriodToPlanningEffectivePeriod(p));


/**
 * Convert a planning effective period array into a network effective period array
 * @param periods the planning effective period array to convert
 * @return the converted network effective period array
 */
export const convertPlanningEffectivePeriodsToNetworkEffectivePeriods = (periods: PlanningEffectivePeriod[]): NetworkEffectivePeriod[] => periods.map(p => convertPlanningEffectivePeriodToNetworkEffectivePeriod(p));



/* Configurations */

/**
 * Convert a list of network occupancy rates to a list of planning occupancy rates
 * @param occupancyRates the list of network occupancy rates
 * @return the converted list of planning occupancy rates
 */
export const convertNetworkOccupancyRatesToPlanningOccupancyRates = (occupancyRates: NetworkOccupancyRate[]): PlanningOccupancyRate[] => occupancyRates.map(o => convertNetworkOccupancyRateToPlanningOccupancyRate(o));

/**
 * Convert a network occupancy rate to a planning occupancy rate
 * @param occupancyRate the network occupancy rate
 * @return the converted planning occupancy rate
 */
export const convertNetworkOccupancyRateToPlanningOccupancyRate = (occupancyRate: NetworkOccupancyRate): PlanningOccupancyRate => ({
    ...occupancyRate,
    startDate: occupancyRate.startDate ? moment(occupancyRate.startDate) : undefined,
    endDate: occupancyRate.endDate ? moment(occupancyRate.endDate) : undefined,
    startHour: occupancyRate.startHour ? moment(occupancyRate.startHour, MOMENT_FORMAT_TIME_TO_NETWORK) : undefined,
    endHour: occupancyRate.endHour ? moment(occupancyRate.endHour, MOMENT_FORMAT_TIME_TO_NETWORK) : undefined,
    rrule: occupancyRate.rrule ? rruleToRules(occupancyRate.rrule)?.rrule : {},
    exclusions: occupancyRate.exclusions?.slice(),
});

/**
 * Convert a list of network occupancy rates to a list of planning occupancy rates
 * @param occupancyRates the list of network occupancy rates
 * @return the converted list of planning occupancy rates
 */
export const convertNetworkCcntToCcnt = (networkCcnt: NetworkCcnt): CcntType => ({
    ...networkCcnt,
    entryDate: moment(networkCcnt.entryDate),
    exitDate: moment(networkCcnt.exitDate),
    events: convertNetworkCcntEventsToCcntEvents(networkCcnt.events)
});

/**
 * Convert a network occupancy rate to a planning occupancy rate
 * @param occupancyRate the network occupancy rate
 * @return the converted planning occupancy rate
 */
export const convertNetworkCcntEventsToCcntEvents = (networkCcntEvents: NetworkCcntEvent[]): CcntEvent[] => networkCcntEvents.map(o => convertNetworkCcntEventToCcntEvent(o));

export const convertNetworkCcntEventToCcntEvent = (networkCcntEvent: NetworkCcntEvent): CcntEvent => ({
    ...networkCcntEvent,
    dateFrom: moment(networkCcntEvent.dateFrom),
    dateTo: moment(networkCcntEvent.dateTo),
    overtime: convertNetworkCcntEventOvertimesToCcntEventOvertimes(networkCcntEvent.overtime),
    breaktime: convertNetworkCcntEventBreaktimesToCcntEventBreaktimes(networkCcntEvent.breaktime)
});

export const convertNetworkCcntEventOvertimesToCcntEventOvertimes = (networkCcntEventOvertimes: NetworkCcntEventOvertime[]): CcntEventOvertime[] => networkCcntEventOvertimes.map(o => convertNetworkCcntEventOvertimeToCcntEventOvertime(o));

export const convertNetworkCcntEventOvertimeToCcntEventOvertime = (networkCcntEventOvertime: NetworkCcntEventOvertime): CcntEventOvertime => ({
    ...networkCcntEventOvertime,
    dateFrom: moment(networkCcntEventOvertime.dateFrom),
    dateTo: moment(networkCcntEventOvertime.dateTo),
});

export const convertNetworkCcntEventBreaktimesToCcntEventBreaktimes = (networkCcntEventBreaktimes: NetworkCcntEventBreaktime[]): CcntEventBreaktime[] => networkCcntEventBreaktimes.map(o => convertNetworkCcntEventBreaktimeToCcntEventBreaktime(o));

export const convertNetworkCcntEventBreaktimeToCcntEventBreaktime = (networkCcntEventBreaktime: NetworkCcntEventBreaktime): CcntEventBreaktime => ({
    ...networkCcntEventBreaktime,
    dateFrom: moment(networkCcntEventBreaktime.dateFrom),
    dateTo: moment(networkCcntEventBreaktime.dateTo),
});



/**
 * Convert a list of network occupancy rates to a list of planning occupancy rates
 * @param occupancyRates the list of network occupancy rates
 * @return the converted list of planning occupancy rates
 */
export const convertNetworkMonthlyReportToMonthlyReport = (networkMonthlyReport: NetworkMonthlyReport): MonthlyReportType => ({
    ...networkMonthlyReport,
    events: convertNetworkMonthlyReportEventsToMonthlyReportEvents(networkMonthlyReport.events)
});

/**
* Convert a network occupancy rate to a planning occupancy rate
* @param occupancyRate the network occupancy rate
* @return the converted planning occupancy rate
*/
export const convertNetworkMonthlyReportEventsToMonthlyReportEvents = (networkMonthlyReportEvents: NetworkMonthlyReportEvent[]): MonthlyReportEvent[] => networkMonthlyReportEvents.map(o => convertNetworkMonthlyReportEventToMonthlyReportEvent(o));

export const convertNetworkMonthlyReportEventToMonthlyReportEvent = (networkMonthlyReportEvent: NetworkMonthlyReportEvent): MonthlyReportEvent => ({
    ...networkMonthlyReportEvent,
    dateFrom: moment(networkMonthlyReportEvent.dateFrom),
    dateTo: moment(networkMonthlyReportEvent.dateTo),
    overtime: convertNetworkMonthlyReportEventOvertimesToMonthlyReportEventOvertimes(networkMonthlyReportEvent.overtime),
    breaktime: convertNetworkMonthlyReportEventBreaktimesToMonthlyReportEventBreaktimes(networkMonthlyReportEvent.breaktime)
});


export const convertNetworkMonthlyReportEventOvertimesToMonthlyReportEventOvertimes = (networkMonthlyReportEventOvertimes: NetworkMonthlyReportEventOvertime[]): MonthlyReportEventOvertime[] => networkMonthlyReportEventOvertimes.map(o => convertNetworkMonthlyReportEventOvertimeToMonthlyReportEventOvertime(o));

export const convertNetworkMonthlyReportEventOvertimeToMonthlyReportEventOvertime = (networkMonthlyReportEventOvertime: NetworkMonthlyReportEventOvertime): MonthlyReportEventOvertime => ({
    ...networkMonthlyReportEventOvertime,
    dateFrom: moment(networkMonthlyReportEventOvertime.dateFrom),
    dateTo: moment(networkMonthlyReportEventOvertime.dateTo),
});

export const convertNetworkSimpleBreaktimeToSimpleBreaktime = (networkSimpleBreaktime: NetworkSimpleBreaktime): SimpleBreaktime => ({
    ...networkSimpleBreaktime,
    dateFrom: moment(networkSimpleBreaktime.dateFrom).set("seconds", 0).set("milliseconds", 0),
    dateTo: moment(networkSimpleBreaktime.dateTo).set("seconds", 0).set("milliseconds", 0),
});
export const convertNetworkSimpleBreaktimesToSimpleBreaktimes = (networkSimpleBreaktimes: NetworkSimpleBreaktime[]): SimpleBreaktime[] => networkSimpleBreaktimes.map(o => convertNetworkSimpleBreaktimeToSimpleBreaktime(o));

export const convertNetworkSimpleOvertimeToSimpleOvertime = (networkSimpleOvertime: NetworkSimpleOvertime): SimpleOvertime => ({
    ...networkSimpleOvertime,
    dateFrom: moment(networkSimpleOvertime.dateFrom).set("seconds", 0).set("milliseconds", 0),
    dateTo: moment(networkSimpleOvertime.dateTo).set("seconds", 0).set("milliseconds", 0),
});
export const convertNetworkSimpleOvertimesToSimpleOvertimes = (networkSimpleOvertimes: NetworkSimpleOvertime[]): SimpleOvertime[] => networkSimpleOvertimes.map(o => convertNetworkSimpleOvertimeToSimpleOvertime(o));

export const convertNetworkSimpleEventToSimpleEvent = (networkSimpleEvent: NetworkSimpleEvent): SimpleEvent => ({
    ...networkSimpleEvent,
    dateFrom: moment(networkSimpleEvent.dateFrom).set("milliseconds", 0),
    dateTo: moment(networkSimpleEvent.dateTo).set("milliseconds", 0),
    breakTimes: convertNetworkSimpleBreaktimesToSimpleBreaktimes(networkSimpleEvent.breakTimes),
    overtimes: convertNetworkSimpleOvertimesToSimpleOvertimes(networkSimpleEvent.overtimes)
});
export const convertNetworkSimpleEventsToSimpleEvents = (networkSimpleEvents: NetworkSimpleEvent[]): SimpleEvent[] => networkSimpleEvents.map(o => convertNetworkSimpleEventToSimpleEvent(o));

export const convertNetworkMonthlyReportEventBreaktimesToMonthlyReportEventBreaktimes = (networkMonthlyReportEventBreaktimes: NetworkMonthlyReportEventBreaktime[]): MonthlyReportEventBreaktime[] => networkMonthlyReportEventBreaktimes.map(o => convertNetworkMonthlyReportEventBreaktimeToMonthlyReportEventBreaktime(o));

export const convertNetworkMonthlyReportEventBreaktimeToMonthlyReportEventBreaktime = (networkMonthlyReportEventBreaktime: NetworkMonthlyReportEventBreaktime): MonthlyReportEventBreaktime => ({
    ...networkMonthlyReportEventBreaktime,
    dateFrom: moment(networkMonthlyReportEventBreaktime.dateFrom),
    dateTo: moment(networkMonthlyReportEventBreaktime.dateTo),
});


export const convertNetworkSimpleContractToSimpleContract = (networkSimpleContract: NetworkSimpleContract): SimpleContract => ({
    ...networkSimpleContract,
    startDate: moment(networkSimpleContract.startDate),
    endDate: moment(networkSimpleContract.endDate),
});
export const convertNetworkSimpleContractsToSimpleContracts = (networkSimpleContracts: NetworkSimpleContract[]): SimpleContract[] => networkSimpleContracts.map(o => convertNetworkSimpleContractToSimpleContract(o));

/**
 * Convert a network occupancy rate exclusion to a planning occupancy rate exclusion
 * @param exclusion the network occupancy rate exclusion
 * @return the converted planning occupancy rate exclusion
 */
export const convertNetworkOccupancyRateExclusionToPlanningOccupancyRateExclusion = (exclusion: NetworkOccupancyRateExclusion): PlanningOccupancyRateExclusion => ({
    ...exclusion,
    startDate: moment(exclusion.startDate),
    endDate: moment(exclusion.endDate),
});

/**
 * Convert a planning occupancy rate exclusion to a network occupancy rate exclusion
 * @param exclusion the planning occupancy rate exclusion
 * @return the converted network occupancy rate exclusion
 */
export const convertPlanningOccupancyRateExclusionToNetworkOccupancyRateExclusion = (exclusion: PlanningOccupancyRateExclusion): NetworkOccupancyRateExclusion => ({
    ...exclusion,
    startDate: exclusion.startDate ? exclusion.startDate.format("YYYY-MM-DD") : undefined,
    endDate: exclusion.endDate ? exclusion.endDate.format("YYYY-MM-DD") : undefined,
});

/**
 * Clone an occupancy rate
 * @param occupancyRate the occupancyRate to clone
 * @return the cloned occupancy rate
 */
export const cloneOccupancyRate = (occupancyRate: PlanningOccupancyRate): PlanningOccupancyRate => ({
    ...occupancyRate,
    typeOfDay: Object.assign({}, occupancyRate.typeOfDay),
    startDate: occupancyRate.startDate ? moment(occupancyRate.startDate) : undefined,
    endDate: occupancyRate.endDate ? moment(occupancyRate.endDate) : undefined,
    rrule: {
        freq: occupancyRate.rrule?.freq,
        dtstart: occupancyRate.rrule && occupancyRate.rrule.dtstart ? new Date(occupancyRate.rrule.dtstart.getTime()) : undefined,
        interval: occupancyRate.rrule?.interval,
        count: occupancyRate.rrule?.count,
        until: occupancyRate.rrule?.until,
        byweekday: occupancyRate.rrule?.byweekday?.slice(),
    },
});


/**
 * Clone the settings
 * @param settings the settings to clone
 * @return the cloned settings
 */
export const cloneSettings = (settings: PlanningSettings): PlanningSettings => ({
    ...settings,
    workingDaysOfWeek: settings.workingDaysOfWeek ? Array.from(settings.workingDaysOfWeek) : undefined,
    startHourOfDay: moment(settings.startHourOfDay),
    endHourOfDay: moment(settings.endHourOfDay),
    colors: settings.colors ? settings.colors.map(c => Object.assign({}, c)) : [],
    subscribedOptionalHolidays: settings.subscribedOptionalHolidays ? settings.subscribedOptionalHolidays.slice() : undefined,
    checkEventsOverlap: settings.checkEventsOverlap
});

/**
 * Clone the vacations
 * @param vacations the vacations to clone
 * @return the cloned vacations
 */
export const cloneVacations = (vacations: PlanningVacations): PlanningVacations => ({
    ...vacations,
    startDate: moment(vacations.startDate),
    endDate: moment(vacations.endDate),
});

/**
 * Clone a user's vacations
 * @param vacations the user's vacations to clone
 * @return the cloned user's vacations
 */
export const cloneUserVacations = (vacations: PlanningUserVacations): PlanningUserVacations => ({
    ...vacations,
    startDate: moment(vacations.startDate),
    endDate: moment(vacations.endDate),
});

/**
 * Clone a user's extra vacations
 * @param vacations the user's extra vacations to clone
 * @return the cloned user's extra vacations
 */
export const cloneUserExtraVacations = (vacations: PlanningUserExtraVacations): PlanningUserExtraVacations => ({
    ...vacations,
    startDate: moment(vacations.startDate),
    endDate: moment(vacations.endDate),
});

/**
 * Clone a occupancy rate exclusion
 * @param exclusion the exclusion to clone
 * @return the cloned exclusion
 */
export const cloneOccupancyRateExclusion = (exclusion: PlanningOccupancyRateExclusion): PlanningOccupancyRateExclusion => ({
    ...exclusion,
    startDate: moment(exclusion.startDate),
    endDate: moment(exclusion.endDate),
});

/**
 * Convert a network vacations to planning vacations
 * @param vacations the network vacations to convert
 * @returns the converted planning vacations
 */
export const convertNetworkVacationsToPlanningVacations = (vacations: NetworkVacations): PlanningVacations => ({
    ...vacations,
    startDate: moment(vacations.startDate).startOf("days"),
    endDate: moment(vacations.endDate).endOf("days"),
});


/**
 * Convert a planning vacations to network vacations
 * @param vacations the planning vacations to convert
 * @returns the converted network vacations
 */
export const convertPlanningVacationsToNetworkVacations = (vacations: PlanningVacations): NetworkVacations => ({
    ...vacations,
    startDate: vacations.startDate.format("YYYY-MM-DD"),
    endDate: vacations.endDate.format("YYYY-MM-DD"),
});

/**
 * Convert the network holidays to planning holidays
 * @param vacations the network holidays to convert
 * @returns the converted planning holidays
 */
export const convertNetworkHolidaysToPlanningHolidays = (holidays: NetworkHolidays): PlanningHolidays => ({
    ...holidays,
    startDate: moment(holidays.startDate),
    endDate: moment(holidays.endDate),
    optionalCountries: holidays.optionalCountries.split(','),
    mandatoryCountries: holidays.mandatoryCountries.split(','),
});

/**
 * Convert the planning holidays to network holidays
 * @param vacations the planning holidays to convert
 * @returns the converted network holidays
 */
export const convertPlanningHolidaysToNetworkHolidays = (holidays: PlanningHolidays): NetworkHolidays => ({
    ...holidays,
    startDate: holidays.startDate.format("YYYY-MM-DD"),
    endDate: holidays.endDate.format("YYYY-MM-DD"),
    optionalCountries: holidays.optionalCountries.join(','),
    mandatoryCountries: holidays.mandatoryCountries.join(','),
});

/**
 * Convert a planning user row to network user row
 * @param vacations the planning holidays to convert
 * @returns the converted network holidays
 */
export const convertNetworkUserRowToPlanningUserRow = (userRow: NetworkUserRow): PlanningUserRow => ({
    ...userRow,
    days: userRow.days.map((u): PlanningUserRowDay => ({
        ...u,
        events: u.events ? convertNetworkEventsToPlanningEvents(u.events) : undefined,
        overtimes: u.overtimes ? u.overtimes.map(o => convertNetworkOvertimeToPlanningOvertime(o)) : undefined,
    }))
});


export const handleForgotPassword = (params: { email: string, language?: string; }, callbackSuccess?: (success: string) => void, callbackError?: (error: string) => void): void => {
    Network.forgotPassword(params.email, params.language).then(
        (success) => {
            callbackSuccess && callbackSuccess(success);
        },
        (error) => {
            callbackError && callbackError(error);
        },
    );
};

/**
* Convert the network calendar data to planning
* @param vacations the network calendar data
* @returns the converted planning calendar data
*/
export const convertNetworkCalendarDataToPlanningCalendarData = (calendarData: NetworkCalendarData): PlanningCalendarData => ({
    ...calendarData,
    calendarUserData: calendarData.calendarUserData.map((data: NetworkCalendarUserData) => ({
        ...data,
        days: data.days.map(d => ({
            ...d,
            date: moment(d.date),
            cellEvents: d.cellEvents.map(e => ({
                ...e,
                event: convertNetworkEventToPlanningEvent(e.event),
            })),
            cellOvertimes: d.cellOvertimes.map(o => ({
                ...o,
                overtime: convertNetworkOvertimeToPlanningOvertime(o.overtime),
            })),
        }))
    }))
});



export const emptyCache = () => {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload();
    }
};




// for eventual futur use
export const decimalize = (numberToDecimalize: number, nbDecimals = 2, comma = false): string => {
    const number = (Math.round(numberToDecimalize * 100) / 100).toFixed(nbDecimals);
    if (comma) number.replaceAll('.', ',');

    return number;
};

//#region Convert NetworkCctSecurityResponse To CctSecurityResponse 
/**
 * 
 * @param networkCctSecurityData 
 * @returns 
 */
export const convertNetworkCctSecurityDataToCctSecurityData = (networkCctSecurityData: NetworkCctSecurityData): CctSecurityData => {
    const data: CctSecurityData = {
        ...networkCctSecurityData,
        events: convertNetworkSimpleEventsToSimpleEvents(networkCctSecurityData.events),
        contracts: convertNetworkSimpleContractsToSimpleContracts(networkCctSecurityData.contracts),

    };
    return data;
};

/**
 * 
 * @param networkCctSecurityResponse 
 * @returns 
 */
export const convertNetworkCctSecurityResponseToCctSecurityResponse = (networkCctSecurityResponse: NetworkCctSecurityResponse): CctSecurityResponse => {
    const response: CctSecurityResponse = {
        data: networkCctSecurityResponse.data.map(d => convertNetworkCctSecurityDataToCctSecurityData(d)),
        initialDaysOff: [
            ...networkCctSecurityResponse.initialDaysOff
        ],
        initialHours: [
            ...networkCctSecurityResponse.initialHours
        ]
    };

    return response;
};
//#endregion

//#region Convert NetworkAllHolidays to AllHolidays
/**
 * 
 * @param networkAllHoliday 
 * @returns 
 */
export const convertNetworkAllHolidayToAllHoliday = (networkAllHoliday: NetworkAllHolidays): AllHolidays => {
    return {
        ...networkAllHoliday,
        startDate: moment(networkAllHoliday.startDate),
        endDate: moment(networkAllHoliday.endDate).endOf("day")
    };
};

export const convertNetworkUsersEffectiveHoursToUsersEffectiveHours = (networkUsersEffectiveHours: NetworkUserEffectiveHours[]): UserEffectiveHours[] => networkUsersEffectiveHours.map(ah => convertNetworkUserEffectiveHoursToUserEffectiveHours(ah));
export const convertNetworkUserEffectiveHoursToUserEffectiveHours = (networkUserEffectiveHours: NetworkUserEffectiveHours): UserEffectiveHours => {
    return {
        ...networkUserEffectiveHours,
        startDate: moment(networkUserEffectiveHours.startDate),
        endDate: moment(networkUserEffectiveHours.endDate)
    };
};

/**
 * 
 * @param networkAllHolidays 
 * @returns 
 */
export const convertNetworkAllHolidaysToAllHolidays = (networkAllHolidays: NetworkAllHolidays[]): AllHolidays[] => networkAllHolidays.map(ah => convertNetworkAllHolidayToAllHoliday(ah));


//#region Convert NetworkIncreasedHoursByDay
export const convertNetworkIncreasedHoursByDayToIncreasedHoursByDay = (networkIncreasedHoursByDay: NetworkIncreasedHoursByDay): IncreasedHoursByDay => {
    return {
        ...networkIncreasedHoursByDay,
        ruleStartDate: moment(networkIncreasedHoursByDay.ruleStartDate),
        ruleEndDate: moment(networkIncreasedHoursByDay.ruleEndDate),
        startTime: moment(networkIncreasedHoursByDay.startTime, [moment.ISO_8601, 'HH:mm:ss']),
        endTime: moment(networkIncreasedHoursByDay.endTime, [moment.ISO_8601, 'HH:mm:ss']),
        created: moment(networkIncreasedHoursByDay.created),
        modified: moment(networkIncreasedHoursByDay.modified),
    };
};

export const convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay = (networkIncreasedHoursByDay: NetworkIncreasedHoursByDay[]): IncreasedHoursByDay[] => networkIncreasedHoursByDay.map(i => convertNetworkIncreasedHoursByDayToIncreasedHoursByDay(i));
//#endregion

//#region Convert Network Planning Event to Simple Event
/**
 * Convert an array of network event into an array of simple events
 * @param events the array of network events
 * @return the array of converted simple events
 */
export const convertNetworkEventsToSimpleEvents = (events: NetworkEvent[]): SimpleEvent[] => events.map(e => convertNetworkEventToSimpleEvent(e));

/**
* Convert a network event to a simple event
* @param event the network event to convert
* @return a simple event
*/
export const convertNetworkEventToSimpleEvent = (event: NetworkEvent): SimpleEvent => {
    return {
        ...event,
        id: event.id ? event.id : -1,
        reminders: event.reminders !== undefined ? convertNetworkRemindersToReminders(event.reminders) : [],
        breakTimes: event.breakTimes !== undefined ? convertNetworkBreakTimesToSimpleBreaktimes(event.breakTimes) : [],
        overtimes: event.overtimes !== undefined ? convertNetworkOvertimesToSimpleOvertimes(event.overtimes) : [],
        dateFrom: moment(event.startDate),
        dateTo: moment(event.endDate),
    };

};

export const weekdaysBetweenTwoDates = (startDate: Moment, endDate: Moment, exclusionDates: Moment[] = []) => {
    const weekdays = [1, 2, 3, 4, 5]; // Weekdays (Monday = 1, ..., Friday = 5)

    // Function to check if a date is a weekday
    const isWeekday = (date: Moment) => {
        const dayOfWeek = date.isoWeekday();
        return weekdays.includes(dayOfWeek);
    };

    // Function to check if a date is in the list of exclusion dates
    const isExcludedDate = (date: Moment) => {
        return exclusionDates.some(exclusionDate => date.isSame(exclusionDate, 'day'));
    };

    const weekdaysBetweenTwoDates = [];

    const currentDate = startDate.clone();
    const lastDate = endDate.clone();

    while (currentDate.isSameOrBefore(lastDate)) {
        if (isWeekday(currentDate) && !isExcludedDate(currentDate)) {
            weekdaysBetweenTwoDates.push(currentDate.clone()); // Clone the date to avoid later modifications
        }

        currentDate.add(1, 'day'); // Add one day
    }

    return weekdaysBetweenTwoDates;
};


export const momentDurationFormat = (duration: moment.Duration, showSeconds = true) => {
    const isNegative = duration.asSeconds() < 0;
    const absoluteDuration = moment.duration(Math.abs(duration.asMilliseconds()));

    const hours = Math.floor(absoluteDuration.asHours());
    const minutes = absoluteDuration.minutes();
    const seconds = absoluteDuration.seconds();

    const signPrefix = isNegative ? '-' : '';
    const hoursStr = `${hours.toString().padStart(2, '0')}`;
    const minutesStr = `${minutes.toString().padStart(2, '0')}`;
    const secondsStr = `${seconds.toString().padStart(2, '0')}`;

    return `${signPrefix}${hoursStr}:${minutesStr}${showSeconds ? `:${secondsStr}` : ''}`;
};

export const containARangeOutside = (range: RangeOfMoment, listOfRanges: RangeOfMoment[]) => {
    return listOfRanges.some(({ startDate, endDate }) => startDate.isBefore(range.startDate) || endDate.isAfter(range.endDate));
};

/**
* Convert a planning event to a simple event
* @param event the planning event to convert
* @return a simple event
*/
export const convertPlanningEventToSimpleEvent = (event: PlanningEvent): SimpleEvent => {
    return {
        id: event.id ? event.id : -1,
        title: event.title,
        breakTimes: event.breakTimes !== undefined ? convertPlanningBreakTimesToSimpleBreaktimes(event.breakTimes) : [],
        overtimes: event.overtimes !== undefined ? convertPlanningOvertimesToSimpleOvertimes(event.overtimes) : [],
        dateFrom: moment(event.startDate),
        dateTo: moment(event.endDate),
    };
};

/**
 * Convert an array of network break times into an array of simple break times
 * @param breakTimes the array of network break times
 * @return the array of converted simple break times
 */
export const convertNetworkBreakTimesToSimpleBreaktimes = (breakTimes: NetworkBreakTime[]): SimpleBreaktime[] => breakTimes.map(bt => convertNetworkBreakTimeToSimpleBreakTime(bt));

/**
* Convert a network break time into a break time
* @param breakTime the network break time
* @return the converted break time
*/
export const convertNetworkBreakTimeToSimpleBreakTime = (breakTime: NetworkBreakTime): SimpleBreaktime => {
    return {
        ...breakTime,
        dateFrom: moment(breakTime.startDate).second(0),
        dateTo: moment(breakTime.endDate).second(0),
    };
};

/**
 * Convert an array of network break times into an array of simple break times
 * @param breakTimes the array of network break times
 * @return the array of converted simple break times
 */
export const convertPlanningBreakTimesToSimpleBreaktimes = (breakTimes: BreakTime[]): SimpleBreaktime[] => breakTimes.map(bt => convertPlanningBreakTimeToSimpleBreakTime(bt));

/**
* Convert a network break time into a break time
* @param breakTime the network break time
* @return the converted break time
*/
export const convertPlanningBreakTimeToSimpleBreakTime = (breakTime: BreakTime): SimpleBreaktime => {
    return {
        ...breakTime,
        dateFrom: moment(breakTime.startDate).second(0),
        dateTo: moment(breakTime.endDate).second(0),
    };
};

/**
 * Convert an array of network overtimes into an array of simple overtimesy
 * @param overtimes the array of network overtimes
 * @return the array of converted simple overtimes
 */
export const convertNetworkOvertimesToSimpleOvertimes = (overtimes: NetworkOvertime[]): SimpleOvertime[] => overtimes.map(bt => convertNetworkOvertimeToSimpleOvertime(bt));


/**
 * Convert a network overtime to a simple overtime
 * @param overtime the overtime to convert
 * @returns the converted simple overtime
 */
export const convertNetworkOvertimeToSimpleOvertime = (overtime: NetworkOvertime): SimpleOvertime => ({
    ...overtime,
    dateFrom: moment(overtime.startDate),
    dateTo: moment(overtime.endDate),
    isNegative: overtime.isNegative ?? false,
    isConfirmed: overtime.isConfirmed ?? false,
    isRefused: overtime.isRefused ?? false,
});

/**
 * Convert an array of network overtimes into an array of simple overtimesy
 * @param overtimes the array of network overtimes
 * @return the array of converted simple overtimes
 */
export const convertPlanningOvertimesToSimpleOvertimes = (overtimes: PlanningOvertime[]): SimpleOvertime[] => overtimes.map(bt => convertPlanningOvertimeToSimpleOvertime(bt));

/**
 * Convert a network overtime to a simple overtime
 * @param overtime the overtime to convert
 * @returns the converted simple overtime
 */
export const convertPlanningOvertimeToSimpleOvertime = (overtime: PlanningOvertime): SimpleOvertime => ({
    ...overtime,
    dateFrom: moment(overtime.startDate),
    dateTo: moment(overtime.endDate),
    isNegative: overtime.isNegative ?? false,
    isConfirmed: overtime.isConfirmed ?? false,
    isRefused: overtime.isRefused ?? false,
});

/**
 * Convert an array of network event into an array of simple events
 * @param events the array of network events
 * @return the array of converted simple events
 */
export const convertNetworkEventsToProjectEvents = (events: NetworkProjectEvent[]): ProjectEvent[] => events.map(e => convertNetworkEventToProjectEvent(e));

/**
* Convert a network event to a simple event
* @param event the network event to convert
* @return a simple event
*/
export const convertNetworkEventToProjectEvent = (event: NetworkProjectEvent): ProjectEvent => {
    const projectEvent: ProjectEvent = {
        ...event,
        id: event.id ? event.id : -1,
        breakTimes: event.breakTimes !== undefined ? convertNetworkSimpleBreaktimesToSimpleBreaktimes(event.breakTimes) : [],
        overtimes: event.overtimes !== undefined ? convertNetworkSimpleOvertimesToSimpleOvertimes(event.overtimes) : [],
        dateFrom: moment(event.dateFrom),
        dateTo: moment(event.dateTo),
    };

    projectEvent.effectiveHours = UserEventsData.eventDuration(projectEvent, true, true);
    projectEvent.paidBreaktimesHours = UserEventsData.breaktimesDuration([projectEvent], BreaktimeCalculatedTypes.PAID);
    projectEvent.notPaidBreaktimesHours = UserEventsData.breaktimesDuration([projectEvent], BreaktimeCalculatedTypes.NOTPAID);
    projectEvent.breaktimesHours = projectEvent.paidBreaktimesHours + projectEvent.notPaidBreaktimesHours;
    projectEvent.overtimesHours = UserEventsData.overtimesDuration([projectEvent], OvertimesCalculatedTypes.ALL, OvertimesCalculatedStatus.CONFIRMED);

    return projectEvent;

};
//#endregion

//#region User Availability
export const convertUserAvailabilitiesNetworkToUserAvailabilities = (userAvailabilities: UserAvailabilityNetwork[]): UserAvailability[] => userAvailabilities.map(e => convertUserAvailabilityNetworkToUserAvailability(e));

export const convertUserAvailabilityNetworkToUserAvailability = (data: UserAvailabilityNetwork): UserAvailability => {
    return {
        ...data,
        startTime: moment(data.startTime, "HH:mm"),
        endTime: moment(data.endTime, "HH:mm"),
        startDateRule: moment(data.startDateRule),
        endDateRule: moment(data.endDateRule),
        created: moment(data.created),
        modified: moment(data.modified),
        rrule: data.rrule && data.rrule.length > 0 ? rrulestr(data.rrule) : undefined,

    };
};

export const availabilityRruleToString = (intl: IntlShape, availability: UserAvailabilityEdit, allday: boolean) => {
    const rrule = availability.rrule;
    let textToText = "";
    if (availability && availability.startDateRule && availability.endDateRule) {
        textToText += intl.formatMessage({ defaultMessage: 'From {start} to {end},' }, { start: availability.startDateRule.format(getFormat('DATE')), end: availability.endDateRule.format(getFormat('DATE')) });
    }

    switch (rrule?.options.freq) {
        case RRule.DAILY:
            textToText += ` ${intl.formatMessage({ defaultMessage: '{count, plural, one {every day,} other {every day,}}' }, { count: rrule.options.interval })}`;
            break;
        case RRule.WEEKLY:
            textToText += ` ${intl.formatMessage({ defaultMessage: '{count, plural, one {every week,} other {every week,}}' }, { count: rrule.options.interval })}`;
            if (rrule.options.byweekday && rrule.options.byweekday.length > 0) {
                const parsedWeekDays = weekDaysRRule.filter(d => rrule.options.byweekday.includes(d.weekday)).map(d => intl.formatMessage({ defaultMessage: 'on {day}' }, { day: intl.formatMessage(d.name) }));
                if (parsedWeekDays.length === 1) {
                    textToText += (` ${parsedWeekDays[0]} `);
                } else {
                    textToText += (` ${parsedWeekDays.slice(0, -1).join(", ") + " et " + parsedWeekDays.slice(-1)} `);
                }
            }
            break;
        case RRule.MONTHLY:
            textToText += ` ${intl.formatMessage({ defaultMessage: '{count, plural, one {every month,} other {every month,}}' }, { count: rrule.options.interval })}`;
            if (rrule.options.bymonthday.length > 0) {
                textToText += `${intl.formatMessage({ defaultMessage: 'the day n°{day}' }, { day: rrule.options.bymonthday })}`;
            } else {
                const monthlyOptions = monthlySetPosOptions.find(m => m.bysetpos === rrule.options.bysetpos[0]);
                const weekDay = weekDaysRRule.find(w => w.weekday === rrule.options.byweekday[0]);

                if (monthlyOptions && weekDay) {
                    textToText += `${intl.formatMessage({ defaultMessage: 'on {month} {day},' }, { month: intl.formatMessage(monthlyOptions.name).toLocaleLowerCase(), day: intl.formatMessage(weekDay.name) })}`;
                }
            }
            break;
        default:
            textToText = intl.formatMessage({ defaultMessage: 'An error occurred, please contact us (frequency issue)' });
    }

    if (availability && availability.startTime && availability.endTime) {
        const startTimeString = availability.startTime.format(MOMENT_FORMAT_DISPLAY_TIME);
        const endTimeString = availability.endTime.format(MOMENT_FORMAT_DISPLAY_TIME);
        if (allday) {
            textToText += intl.formatMessage({ defaultMessage: 'all day.' });
        } else {
            textToText += intl.formatMessage({ defaultMessage: 'from {start} to {end}' }, { start: startTimeString, end: endTimeString });
        }
    }

    return textToText;
};

export const rruleToString = (intl: IntlShape, rrule: RRule, nbHours?: number, nbUsers?: number) => {
    let textToText = "";

    if (rrule && rrule.options.dtstart && rrule.options.until) {
        textToText += intl.formatMessage({ defaultMessage: 'From {start} to {end},' }, { start: moment(rrule.options.dtstart).format(getFormat('DATE')), end: moment(rrule.options.until).format(getFormat('DATE')) });
    }

    switch (rrule?.options.freq) {
        case RRule.DAILY:
            textToText += ` ${intl.formatMessage({ defaultMessage: '{count, plural, one {every day,} other {every day,}}' }, { count: rrule.options.interval })}`;
            break;
        case RRule.WEEKLY:
            textToText += ` ${intl.formatMessage({ defaultMessage: '{count, plural, one {every week,} other {every week,}}' }, { count: rrule.options.interval })}`;
            if (rrule.options.byweekday && rrule.options.byweekday.length > 0) {
                const parsedWeekDays = weekDaysRRule.filter(d => rrule.options.byweekday.includes(d.weekday)).map(d => intl.formatMessage({ defaultMessage: 'on {day}' }, { day: intl.formatMessage(d.name) }));
                if (parsedWeekDays.length === 1) {
                    textToText += (` ${parsedWeekDays[0]} `);
                } else {
                    textToText += (` ${parsedWeekDays.slice(0, -1).join(", ") + " et " + parsedWeekDays.slice(-1)} `);
                }
            }
            break;
        case RRule.MONTHLY:
            textToText += ` ${intl.formatMessage({ defaultMessage: '{count, plural, one {every month,} other {every month,}}' }, { count: rrule.options.interval })}`;
            if (rrule.options.bymonthday.length > 0) {
                textToText += `${intl.formatMessage({ defaultMessage: 'the day n°{day}' }, { day: rrule.options.bymonthday })}`;
            } else {
                const monthlyOptions = monthlySetPosOptions.find(m => m.bysetpos === rrule.options.bysetpos[0]);
                const weekDay = weekDaysRRule.find(w => w.weekday === rrule.options.byweekday[0]);

                if (monthlyOptions && weekDay) {
                    textToText += `${intl.formatMessage({ defaultMessage: 'on {month} {day},' }, { month: intl.formatMessage(monthlyOptions.name).toLocaleLowerCase(), day: intl.formatMessage(weekDay.name) })}`;
                }
            }
            break;
        default:
            textToText = intl.formatMessage({ defaultMessage: 'An error occurred, please contact us (frequency issue)' });
    }
    if (nbHours) {
        textToText += intl.formatMessage({ defaultMessage: 'lasting {hours} hours' }, { hours: nbHours });
    }
    if (nbUsers) {
        textToText += intl.formatMessage({ defaultMessage: '{count, plural, one {for {count} user} other {for {count} users}}' }, { count: nbUsers });
    }
    textToText += '.';

    return textToText;
};

export const convertUserAvailabilitiesEditToUserAvailabilitiesEditNetwork = (userAvailabilities: UserAvailabilityEdit[]): UserAvailabilityEditNetwork[] => userAvailabilities.map(e => convertUserAvailabilityEditToUserAvailabilityEditNetwork(e));

export const convertUserAvailabilityEditToUserAvailabilityEditNetwork = (data: UserAvailabilityEdit): UserAvailabilityEditNetwork => {
    return {
        ...data,
        startTime: moment(data.startTime).format(MOMENT_FORMAT_TIME_TO_NETWORK),
        endTime: moment(data.endTime).format(MOMENT_FORMAT_TIME_TO_NETWORK),
        startDateRule: moment(data.startDateRule).format(MOMENT_FORMAT_DATE_TO_NETWORK),
        endDateRule: moment(data.endDateRule).format(MOMENT_FORMAT_DATE_TO_NETWORK),
        rrule: data.rrule?.toString()
    };
};
//#endregion

export function groupBy<T, K extends keyof any>(list: T[], keyGetter: (input: T) => K): Record<K, T[]> {
    return list.reduce((previous, currentItem) => {
        const group = keyGetter(currentItem);
        // If array doesn't exist yet, create it
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);
}



export const contractsAvailableBetweenMonths = (user_contracts: UserJobTMP[], startMonth: Moment, endMonth: Moment) => {
    const contracts = user_contracts.sort((a, b) => {
        const dateA = moment(a.date_in_report);
        const dateB = moment(b.date_in_report);
        return dateB.diff(dateA);
    });

    return [...contracts.filter((c) => !(endMonth.isBefore(moment(c.date_in_report), "year") || startMonth.isAfter(moment(c.contract_expiry_date), "year")))];
};

export const convertNetworkEventWithEventClockedToEventWithEventClocked = (event: NetworkEventWithEventClocked, user?: User): EventWithEventClocked => {
    return {
        ...event,
        startDate: moment(event.startDate),
        endDate: moment(event.endDate),
        user: {
            id: user ? user.id : -1,
            name: user ? user.last_name + ' ' + user.first_name : '',
            code: user ? user.code : undefined,
            avatar: user ? user.image : ''
        },
        breakTimes: event.breakTimes.map(b => ({
            ...b,
            startDate: moment(b.startDate),
            endDate: moment(b.endDate),
            clocked: b.clocked ? ({
                ...b.clocked,
                startDate: b.clocked.startDate ? moment(b.clocked.startDate) : undefined,
                endDate: b.clocked.endDate ? moment(b.clocked.endDate) : undefined,
                manualStartDate: b.clocked.manualStartDate ? moment(b.clocked.manualStartDate) : undefined,
                manualEndDate: b.clocked.manualEndDate ? moment(b.clocked.manualEndDate) : undefined,
                adminStartDate: b.clocked.adminStartDate ? moment(b.clocked.adminStartDate) : undefined,
                adminEndDate: b.clocked.adminEndDate ? moment(b.clocked.adminEndDate) : undefined,
                created: moment(b.clocked.created),
                modified: b.clocked.modified ? moment(b.clocked.modified) : undefined,
            }) : undefined
        })),
        clocked: event.clocked ? convertNetworkSmallEventClockedToSmallEventClocked(event.clocked) : undefined,
    };
};

export const convertNetworkSmallEventClockedToSmallEventClocked = (clocked: NetworkSmallEventClocked): SmallEventClocked => {
    return {
        ...clocked,
        startDate: moment(clocked.startDate),
        endDate: clocked.endDate ? moment(clocked.endDate) : undefined,
        manualStartDate: clocked.manualStartDate ? moment(clocked.manualStartDate) : undefined,
        manualEndDate: clocked.manualEndDate ? moment(clocked.manualEndDate) : undefined,
        created: moment(clocked.created),
        modified: moment(clocked.modified),
        breakTimesClocked: clocked.breakTimesClocked ?
            clocked.breakTimesClocked.map(breakTClocked => ({
                ...breakTClocked,
                startDate: breakTClocked.startDate ? moment(breakTClocked.startDate) : undefined,
                endDate: breakTClocked.endDate ? moment(breakTClocked.endDate) : undefined,
                manualStartDate: breakTClocked.manualStartDate ? moment(breakTClocked.manualStartDate) : undefined,
                manualEndDate: breakTClocked.manualEndDate ? moment(breakTClocked.manualEndDate) : undefined,
                adminStartDate: breakTClocked.adminStartDate ? moment(breakTClocked.adminStartDate) : undefined,
                adminEndDate: breakTClocked.adminEndDate ? moment(breakTClocked.adminEndDate) : undefined,
                created: moment(breakTClocked.created),
                modified: breakTClocked.modified ? moment(breakTClocked.modified) : undefined,
            }))
            : undefined
    };
};

export const normalizeString = (str: string) => {
    return str
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
};

export const searchIgnoreCaseAndAccentInsensitive = (mainString: string, searchString: string) => {
    const normalizedMain = normalizeString(mainString);
    const normalizedSearch = normalizeString(searchString);
    return normalizedMain.includes(normalizedSearch);
};


export const ShrinkedText = (props: { children: string, maxSize: number, fullDescription?: string | JSX.Element; }) => {
    const { fullDescription, children, maxSize } = props;
    return (
        <Tooltip title={fullDescription || children}>
            <span
                style={{
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    width: maxSize
                }}
            >{children}</span>
        </Tooltip>
    );
};
export const shrinkDateRange = (fromDate: Moment, toDate: Moment, maxSize: number) => {
    return (
        <Tooltip title={
            <div>
                <p>{`Du ${fromDate.format('DD-MM-YYYY HH:mm')}`}</p>
                <p>{`Au ${toDate.format('DD-MM-YYYY HH:mm')}`}</p>
            </div>
        }>
            <span
                style={{
                    overflowX: 'hidden',
                    whiteSpace: 'nowrap',
                    width: maxSize,
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                }}
            ><div style={{ padding: '4px 7px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#896D4D54', top: 0, bottom: 0, left: 0, position: 'absolute' }}>{`${fromDate.format('HH:mm')} - ${toDate.format('HH:mm')}`}</div><div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>{`${fromDate.format(MOMENT_SHORT_DATE_FORMAT)}`}</div></span>
        </Tooltip>
    );
};
export const shrinkDate = (fromDate: Moment, toDate: Moment, maxSize: number) => {
    return (
        <Tooltip title={
            <div>
                <p>{`Du ${fromDate.format('DD-MM-YYYY HH:mm')}`}</p>
                <p>{`Au ${toDate.format('DD-MM-YYYY HH:mm')}`}</p>
            </div>
        }>
            <span
                style={{
                    overflowX: 'hidden',
                    whiteSpace: 'nowrap',
                    width: maxSize,
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                }}
            ><div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>{`${fromDate.format(MOMENT_SHORT_DATE_FORMAT)}`}</div></span>
        </Tooltip>
    );
};
export const shrinkTime = (fromDate: Moment, toDate: Moment) => {
    return (
        <Tooltip title={
            <div>
                <p>{`Du ${fromDate.format('DD-MM-YYYY HH:mm')}`}</p>
                <p>{`Au ${toDate.format('DD-MM-YYYY HH:mm')}`}</p>
            </div>
        }>
            <span

            ><div>{`${fromDate.format('HH:mm')} - ${toDate.format('HH:mm')}`}</div></span>
        </Tooltip>
    );
};
export const isTouchDevice = () => (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));


export const isDeprecated = (date: Moment | undefined, speed: ReloadTimes) => (date === undefined || moment().diff(date, "minutes") >= speed);

export const generateNegativeUniqueId = () => -1 * Math.floor(Math.random() * Math.pow(10, 15));

export const cloneMomentWithLocale = (date: Moment) => date.clone().locale(moment.locale());

export const getFlagCode = (lang: string) => {
    const selectedLanguage = AvailableLanguages.find(l => l.value.toLocaleLowerCase() === lang.toLocaleLowerCase());

    return selectedLanguage ? selectedLanguage.flag.toLocaleUpperCase() : 'FR';
};

export const isEmailValid = (email: string) => {
    const regEmail = /^(?!.*\.\.)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
    return regEmail.test(email);
};

export const isPhoneValid = (phone: string) => {
    const regex = /^.*[a-zA-Z].*/;
    return [9, 10, 11, 12, 13].includes(phone.length) && !regex.test(phone);
};

export const generateUniqueIdentifier = () => {
    const timestamp = Date.now().toString(36);
    const randomString = Math.random().toString(36).substring(2, 10);
    return `${timestamp}-${randomString}`;
};

export const disabledDate = (current: Moment, startDate: Moment, endDate: Moment): boolean => {
    // Can not select days before today and today
    return current.isBefore(moment(startDate), "day") || current.isAfter(endDate, "day");
};

export const fixedDecimal = (value: string, decimals = 2): string => {
    return parseFloat(value).toFixed(decimals);
};

export const downloadBlob = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
};

/**
 * Groups the elements of an array based on a key returned by the key selector function.
 * This function creates an object where each key is a value returned by the `keySelector` function, and
 * each value is an array of objects corresponding to that key.
 * 
 * @param array The array of elements to group.
 * @param keySelector A function that takes an item from the array and returns a key to group the items by.
 * 
 * @returns An object where the keys are the results of the `keySelector`, and the values are arrays of elements from the input array
 *          that share the same key.
 * 
 * @example
 * const people = [
 *   { id: 1, name: 'Alice', age: 30 },
 *   { id: 2, name: 'Bob', age: 25 },
 *   { id: 3, name: 'Alice', age: 35 }
 * ];
 * 
 * const groupedByName = groupByObject(people, person => person.name);
 * 
 * // Iterating through the result
 * Object.entries(groupedByName).forEach(([name, group]) => {
 *   console.log(`Name: ${name}`);
 *   group.forEach(person => {
 *     console.log(person);
 *   });
 * });
 */
export const groupByObject = <T, K extends string | number | symbol>(
    array: T[],
    keySelector: (item: T) => K
): Record<K, T[]> => {
    const result: Record<K, T[]> = {} as Record<K, T[]>;

    for (const item of array) {
        const key = keySelector(item);
        if (!(key in result)) {
            result[key] = [];
        }
        result[key].push(item);
    }

    return result;
};

/**
 * Groups the elements of an array based on a key returned by the key selector function.
 * This function creates a `Map` where each key is a value returned by the `keySelector` function, and
 * each value is an array of objects corresponding to that key.
 * 
 * @param array The array of elements to group.
 * @param keySelector A function that takes an item from the array and returns a key to group the items by.
 * 
 * @returns A `Map` where the keys are the results of the `keySelector`, and the values are arrays of elements from the input array
 *          that share the same key.
 * 
 * @example
 * const people = [
 *   { id: 1, name: 'Alice', age: 30 },
 *   { id: 2, name: 'Bob', age: 25 },
 *   { id: 3, name: 'Alice', age: 35 }
 * ];
 * 
 * const groupedByName = groupByMap(people, person => person.name);
 * 
 * // Iterating through the result
 * groupedByName.forEach((group, name) => {
 *   console.log(`Name: ${name}`);
 *   group.forEach(person => {
 *     console.log(person);
 *   });
 * });
 */
export const groupByMap = <T, K>(
    array: T[],
    keySelector: (item: T) => K
): Map<K, T[]> => {
    const result = new Map<K, T[]>();

    for (const item of array) {
        const key = keySelector(item);
        if (!result.has(key)) {
            result.set(key, []);
        }
        result.get(key)!.push(item);
    }

    return result;
};


export const createIndexesOld = <T,>(
    collection: Iterable<T>,
    keySelectors: ((item: T) => string)[]
): { [key: string]: Map<string, T[]>; } => {
    const indexes: { [key: string]: Map<string, T[]>; } = {};

    // For each key selector function, create a corresponding index
    for (const keySelector of keySelectors) {
        const result = new Map<string, T[]>();
        for (const item of collection) {
            const key = keySelector(item);
            if (!result.has(key)) {
                result.set(key, []);
            }
            result.get(key)!.push(item);
        }
        indexes[keySelector.name] = result; // Using the function name as the index key
    }

    return indexes;
};

type GroupByCallback<T> = (item: T) => string | number;

/**
 * Dynamically constructs the return type for multi-level grouping.
 * @template T - The type of the elements being grouped.
 * @template Depth - The remaining depth of the grouping.
 */
type NestedGroup<T, Depth extends number> = Depth extends 1
    ? Record<string | number, T[]>
    : Record<string | number, NestedGroup<T, Decrement<Depth>>>;

/**
 * Utility type to decrement a number literal type, enabling depth calculation.
 */
type Decrement<N extends number> = [
    never, // 0
    0,     // 1
    1,     // 2
    2,     // 3
    3,     // 4
    4,     // 5
    5,     // 6
    6,     // 7
    7,     // 8
    8,     // 9
    9      // 10
][N];

/**
 * Multi-level group-by function.
 * @template T - Type of the array elements.
 * @template Depth - Number of grouping levels.
 * @param items - Array of items to be grouped.
 * @param callbacks - Array of grouping callbacks corresponding to grouping levels.
 * @returns A nested grouped object of the appropriate depth.
 */
export const multiLevelGroupBy = <T, Depth extends number>(
    items: T[],
    callbacks: GroupByCallback<T>[]
): NestedGroup<T, Depth> => {
    const groupRecursive = (remainingCallbacks: GroupByCallback<T>[], elements: T[]): any => {
        if (remainingCallbacks.length === 0) return elements;

        const [currentCallback, ...restCallbacks] = remainingCallbacks;

        const grouped = elements.reduce<Record<string | number, any>>((acc, item) => {
            const key = currentCallback(item);
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);
            return acc;
        }, {});

        if (restCallbacks.length > 0) {
            for (const key in grouped) {
                grouped[key] = groupRecursive(restCallbacks, grouped[key]);
            }
        }

        return grouped;
    };

    return groupRecursive(callbacks, items) as NestedGroup<T, Depth>;
};

export const parseError = (error: unknown): string => {
    if (error instanceof Error) {
        const errorMessage = `
            Message: ${error.message}
            Stack: ${error.stack || 'No stack trace available'}
            Name: ${error.name}
        `;
        return errorMessage;
    } else {
        const errorDetails = typeof error === 'object' && error !== null ? JSON.stringify(error) : String(error);
        return errorDetails;
    }
};

export const getContractTypeType = (c: UserJobTMP) => {
    if (c.typeOfContract)
        //TODO: Uncomment to show other than fixed contract
        return {
            isNormal: c.typeOfContract.mode === ContractTypeMode.NORMAL,
            isHourly: c.typeOfContract.mode === ContractTypeMode.HOURLY,
            isAuxiliary: c.typeOfContract.mode === ContractTypeMode.AUXILIARY,
        };
    else
        return {
            isNormal: true,
            isHourly: false,
            isAuxiliary: false,
        };
};

export const openGoogleMapsRoute = (fromLat: number, fromLng: number, toLat: number, toLng: number): void => {
    const baseUrl = "https://www.google.com/maps/dir/?api=1";
    const from = `${fromLat},${fromLng}`;
    const to = `${toLat},${toLng}`;
    const travelMode = "driving";
    const url = `${baseUrl}&origin=${from}&destination=${to}&travelmode=${travelMode}`;

    // Ouvre le lien dans un nouvel onglet
    window.open(url, "_blank");
};

export const toggleFullScreen = (element: HTMLElement | null = document.documentElement): void => {
    try {
        if (!document.fullscreenElement) element?.requestFullscreen();
        else document.exitFullscreen();
    } catch (error) {
        console.error("An error occurred while toggling fullscreen mode:", error);
    }
};

export const tabLinkToRouteParams = <T extends Record<string, string | number>>(tabLink: T): string => {
    return Object.values(tabLink).join('|');
};

export const filterUsers = (users: User[] | undefined, groups: Group[] | undefined, filters: IFilterUsers) => {
    let usersInSelectedGroups: number[] | undefined = undefined;

    if (users && groups && filters.groups.length > 0) {
        const { groups: selectedGroups, usersToExclude } = filters;
        const userIdInSelectedGroups = selectedGroups.reduce((obj, group) => {
            if (group)
                obj[group] = [];
            return obj;
        }, {} as DictionaryString<number[]>);
        for (const user of users)
            if (user.group_users)
                if (user.group_users.some(g => selectedGroups.includes(g.group)))
                    for (const groupUser of user.group_users)
                        if (groupUser.group in userIdInSelectedGroups && !usersToExclude.includes(user.id))
                            userIdInSelectedGroups[groupUser.group].push(user.id);

        usersInSelectedGroups = Object.keys(userIdInSelectedGroups).reduce((userIds, groupId) => [...userIds, ...userIdInSelectedGroups[groupId]], [] as number[]);
    }

    if (groups && filters.users.length > 0)
        usersInSelectedGroups = (usersInSelectedGroups ?? []).concat(filters.users);

    if (usersInSelectedGroups === undefined)
        return users;

    return users?.filter(u => usersInSelectedGroups?.includes(u.id));
};


export const getMimeType = (src: string): string | undefined => {
    const extension = src.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'webp':
            return 'image/webp';
        case 'avif':
            return 'image/avif';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        case 'svg':
            return 'image/svg+xml';
        default:
            return undefined; // Si l'extension n'est pas reconnue
    }
};

export const getNameOrder = (user: UserSmall, lastNameFirst: boolean) => {
    const firstName = user.first_name ?? (user as any).lastName;
    const lastName = user.last_name ?? (user as any).lastName;
    return lastNameFirst ?
        `${lastName} ${firstName}`
        : `${firstName} ${lastName}`;
};

export const getFullName = (user: UserSmall, lastNameFirst = true, codePosition: ECodePosition, codeAuthorization: CCodeAuthorization) => {
    const fullName = getNameOrder(user, lastNameFirst);

    if (codeAuthorization === 'hidden' || ![ECodePosition.FIRST, ECodePosition.LAST].includes(codePosition) || !user.code || user.code.length === 0) return fullName;
    if (codePosition === ECodePosition.FIRST) return `(${user.code}) ${fullName}`;
    return `${fullName} (${user.code})`;
};

export const formatProjectTitle = (title?: string, customId?: string) => {
    if (customId)
        return `(${customId}) ${title ?? ''}`;

    return title ?? '';
};


export function availableContracts(month: Moment, contracts: UserJobTMP[]) {
    return contracts.filter(c => c.id !== undefined && moment(c.contract_expiry_date, 'YYYY-MM-DD').isSameOrAfter(month, 'dates') && moment(c.date_in_report, 'YYYY-MM-DD').isSameOrBefore(month, 'dates'));
}

export function filterContracts(startDate: Moment, endDate: Moment, contracts: UserJobTMP[]): UserJobTMP[] {
    return contracts.filter(contract =>
        moment(contract.contract_expiry_date).isAfter(startDate) &&
        moment(contract.date_in_report).isBefore(endDate)
    );
}

export function getInitialSelectedContracts(users: User[], from: Moment, to: Moment) {
    const selectedContracts: DictionaryNumber<number | undefined> = {};
    users.forEach(u => {
        if (isNullOrEmpty(u.job)) {
            selectedContracts[u.id] = undefined;
        } else {
            const contracts = filterContracts(from, to, u.job);
            if (isNullOrEmpty(contracts)) {
                selectedContracts[u.id] = undefined;
            } else {
                selectedContracts[u.id] = contracts[0].id;
            }
        }
    });
    return selectedContracts;
}

export function getImageDimensions(url: string): Promise<{ width: number, height: number; }> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight });
        };
        img.onerror = reject;
        img.src = url;
    });
}

export function normalizeName(name: string): string {
    return name
        .normalize("NFD")                    // Décompose les caractères accentués
        .replace(/[\u0300-\u036f]/g, "")       // Supprime les accents
        .toLowerCase()                        // Met en minuscules
        .replace(/\s+/g, "")                   // Supprime les espaces
        .replace(/[^a-z0-9]/g, "");             // Supprime les caractères spéciaux
}