import { useEffect, useRef } from "react";

const SvgComponent = ({ svgString }: { svgString: string; }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.innerHTML = svgString;
        }
    }, [svgString]);

    return <div ref={ref} />;
};

export default SvgComponent;