import { Button, Popconfirm, Popover } from 'antd';
import moment, { Moment } from 'moment';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { BLUE_COLOR, MOMENT_FORMAT_DISPLAY, MOMENT_FORMAT_DISPLAY_DATE, ORANGE_COLOR, RED_COLOR } from '../../../../utils/constants';
import { ISignatureStatus, SignatureStatus } from '../../../../utils/types/generalTypes';
import FAIcon from '../../../common/FAIcon';
import CircleButton from '../../../common/fields/circleButton';
import SvgComponent from '../../../common/general/SvgComponent/SvgComponent';
import './signature.css';

interface Props {
    year: number;
    contractName?: string;
    contractEndDate?: Moment;
    signature: ISignatureStatus;
    requestSignature: () => void;
    remindSignature: () => void;
    cancelReport?: () => void;
}

const CCNTSignatureCell = (props: Props) => {
    let popoverContent: ReactNode | string = null;
    let icon: ReactNode = null;
    let canCancelReport = false;

    const getContent = () => {
        switch (props.signature.status) {
            case SignatureStatus.SIGNED:
                if (props.signature.svg) {
                    popoverContent = <>
                        <div className='pencil-signature'>
                            <SvgComponent svgString={props.signature.svg} />
                        </div>
                        <div className='label-signed-on'><FormattedMessage defaultMessage={"Signed on {date}"} values={{ date: moment(props.signature.signedDate).format(MOMENT_FORMAT_DISPLAY_DATE) }} /></div>
                    </>;
                    icon = <div className='pencil-signature thicker'>
                        <SvgComponent svgString={props.signature.svg} />
                    </div>;
                }
                canCancelReport = true;
                break;

            case SignatureStatus.SIGNABLE:
                icon = <CircleButton
                    small
                    icon={<FAIcon prefix="fad" name="signature" />}
                    showTooltip={true}
                    placement='left'
                    title='Demande de signature'
                    onClick={(e) => { e.stopPropagation(); props.requestSignature(); }}
                />;
                break;

            case SignatureStatus.AWAITING:
                icon = <FAIcon prefix='fad' name='clock-twelve' fontSize={20} color={BLUE_COLOR} className='spinning' />;
                popoverContent = <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <FormattedMessage defaultMessage={"The report is awaiting signature"} /><br />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {props.signature.reminderSent ? <span className='last-signature-label'><FormattedMessage defaultMessage={"Previous reminder"} /> : {moment.utc(props.signature.reminderSent).format(MOMENT_FORMAT_DISPLAY)}</span> : null}
                        <CircleButton
                            small
                            icon={<FAIcon prefix="fad" name="bell" />}
                            onClick={(e) => { e.stopPropagation(); props.remindSignature(); }}
                        />
                    </div>
                </div>;
                canCancelReport = true;
                break;

            case SignatureStatus.PREVIOUS_MONTH_AWAITING_SIGNATURE:
                icon = <FAIcon prefix='fad' name='circle-exclamation' fontSize={20} color={ORANGE_COLOR} />;
                popoverContent = <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {props.signature.month
                        ? <FormattedMessage defaultMessage={"The report for {month} is still awaiting signature"} values={{ month: moment().month(props.signature.month - 1).format("MMMM") }} />
                        : <FormattedMessage defaultMessage={"The report for a previous month is still awaiting signature"} />
                    }
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {props.signature.reminderSent ? <span className='last-signature-label'><FormattedMessage defaultMessage={"Previous reminder"} /> : {moment.utc(props.signature.reminderSent).format(MOMENT_FORMAT_DISPLAY)}</span> : null}
                        <CircleButton
                            small
                            icon={<FAIcon prefix="fad" name="bell" />}
                            onClick={(e) => { e.stopPropagation(); props.remindSignature(); }}
                        />
                    </div>
                </div>;
                break;

            case SignatureStatus.PREVIOUS_MONTH_NOT_REQUESTED:
                popoverContent = <FormattedMessage defaultMessage={"The signature of a previous report is not requested."} />;
                icon = <FAIcon prefix='fas' name='do-not-enter' fontSize={20} color={"#ff7f7f"} />;
                break;

            default:
                return null;
        }
        const endOfyear = moment(`${props.year}-12-31`, "YYYY-MM-DD");
        popoverContent = popoverContent || canCancelReport
            ?
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {popoverContent}
                {canCancelReport
                    ?
                    <div style={{ marginTop: '10px' }}>
                        <Popconfirm
                            destroyTooltipOnHide
                            icon={<FAIcon prefix="fad" name="circle-exclamation" color={RED_COLOR} />}
                            title={
                                <div>
                                    <div>
                                        <FormattedMessage
                                            defaultMessage={"The following reports will also be cancelled"}
                                            description={"After this there is a bullet list of the affected elements."}
                                        />
                                    </div>
                                    <ul>
                                        <li><FormattedMessage defaultMessage={"Contract"} /> <strong>{props.contractName}</strong></li>
                                        <li><FormattedMessage defaultMessage={"Since"} /> <strong>{props.signature.month ? moment().month(props.signature.month - 1).format("MMMM") : null} {props.year}</strong></li>
                                        <li><FormattedMessage defaultMessage={"Until"} /> <strong>{(props.contractEndDate && props.contractEndDate.isBefore(endOfyear) ? props.contractEndDate : moment().month(11)).format("MMMM")} {props.year}</strong></li>
                                    </ul>
                                </div>
                            }
                            okButtonProps={{ type: 'primary', danger: true }}
                            okText={<FormattedMessage defaultMessage={"Continue"} />}
                            cancelText={<FormattedMessage defaultMessage={"Cancel"} />}
                            cancelButtonProps={{ type: 'dashed' }}
                            onConfirm={props.cancelReport}
                        >
                            <Button type="primary" danger><FormattedMessage defaultMessage={"Cancel report"} /></Button>
                        </Popconfirm>
                    </div>
                    :
                    null
                }
            </div>
            : null;

        if (popoverContent) {
            return <Popover placement='left' content={popoverContent} destroyTooltipOnHide>
                <div onClick={(e) => { e.stopPropagation(); }}>{icon}</div>
            </Popover>;
        }
        return <>{icon}</>;
    };

    return (
        <div className='ccnt-signature-cell'>{getContent()}</div>
    );
};

export default CCNTSignatureCell;