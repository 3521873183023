import { DashOutlined, MoreOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, InputNumber, Popconfirm, Space, Spin, Tabs, TabsProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import isEqual from 'react-fast-compare';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { findByEmptying } from '../../../../utils/array_utils';
import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { FullUserProps, withFullName } from '../../../../utils/objects/withFullName';
import { DictionaryString, IFilterUsers, RouterProps } from '../../../../utils/types/generalTypes';
import { PlanningEvent, TypeOfDay } from '../../../../utils/types/planningTypes';
import { IWorkControlEvent, IWorkControlEventEdit, IWorkControlSmallEvent, IWorkControlWorkRecord, WorkControlActions } from '../../../../utils/types/productivityTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { isNullOrEmpty, multiLevelGroupBy, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import ActionsToolbar from '../../../common/fields/ActionsToolbar/actionsToolbar';
import AmazingDatePicker, { PickerMode, PickerModeType } from '../../../common/fields/AmazingDatePicker/amazingDatePicker';
import CircleButton from '../../../common/fields/circleButton';
import SpeedDial from '../../../common/fields/speedDial';
import WorkDone from '../../../common/fields/WorkDone/workDone';
import Event, { EventSource } from '../../../common/general/event';
import VirtualTable from '../../../common/general/virtualTable';
import { FilterSidebar } from '../../../common/navigations/containerTabs';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';
import FiltersComponent from '../../../planningPerf/tabs/common/filters';


type ReduxProps = ConnectedProps<typeof connector>;
interface IProps extends ReduxProps, RouterProps, IntlProps, ContainerTabsItemProps { }
type Props = IProps & FullUserProps;

type WorkControlEventsByTypeOfDay = Record<string | number, IWorkControlEvent[]>;
type WorkControlEventsByDateByTypeOfDay = Record<string | number, WorkControlEventsByTypeOfDay>;

interface State {
    filters: IFilterUsers;

    dateFrom: Moment;
    dateTo: Moment;
    loading: boolean;
    loadingAccept: number;
    loadingRefuse: number;
    workControlEventsByDateByTypeOfDay: WorkControlEventsByDateByTypeOfDay;
    selectedWorkControlEventIds: number[];
    splitByDays: boolean;
    eventEditing?: IWorkControlEventEdit;

    pickerType?: PickerModeType;
    firstLoad: boolean;
}

class ProductivityTracking extends ContainerTabsItem<Props, State> {
    initialFilters: IFilterUsers = {
        users: [],
        groups: [],
        usersToExclude: [],
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            filters: this.initialFilters,

            dateFrom: moment().startOf("month"),
            dateTo: moment(),
            loading: false,
            loadingAccept: -1,
            loadingRefuse: -1,
            workControlEventsByDateByTypeOfDay: {},
            selectedWorkControlEventIds: [],
            pickerType: PickerMode.MONTH,
            splitByDays: true,
            firstLoad: true,
        };
    }

    componentDidMount(): void {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        this.refreshWorkControlEvents();
        window.addEventListener('keydown', this.handleEscapeKey);

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (
            this.state.loading !== prevState.loading ||
            this.state.loadingRefuse !== prevState.loadingRefuse ||
            this.state.loadingAccept !== prevState.loadingAccept ||
            this.state.pickerType !== prevState.pickerType ||
            !this.state.dateFrom.isSame(prevState.dateFrom) ||
            !this.state.dateTo.isSame(prevState.dateTo) ||
            !isEqual(this.state.selectedWorkControlEventIds, prevState.selectedWorkControlEventIds)
        ) {
            this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        }
        if (!isEqual(prevState.filters, this.state.filters)) {
            this.props.addOrUpdateSidebars(this.getSidebars(), this.props.keyLink);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleEscapeKey);
    }

    getExtra = () => {
        const { intl } = this.props;
        const { loading, loadingAccept, loadingRefuse, dateFrom, dateTo, pickerType } = this.state;
        const isLoading = loading || loadingAccept > -1 || loadingRefuse > -1;
        return (
            <>
                <AmazingDatePicker
                    disableFuture
                    initialPickerType={pickerType}
                    disabled={isLoading}
                    loadingData={loading}
                    selectByDay
                    selectByWeek
                    selectByMonth
                    controlled={{
                        valueFrom: dateFrom,
                        valueTo: dateTo,
                        onChange: this.onChangeDate,
                    }}
                />
                <CircleButton
                    small
                    withoutTooltip
                    title={intl.formatMessage({ defaultMessage: 'Force update' })}
                    icon={<FAIcon prefix={'fad'} name="rotate" />}
                    onClick={() => this.refreshWorkControlEvents()}
                    loading={loading}
                    disabled={loadingAccept > -1 || loadingRefuse > -1} />
            </>
        );
    }

    getSidebars = () => {
        const { intl } = this.props;
        const { filters } = this.state;
        const content = (
            <FiltersComponent
                reset={this.resetFilters}
                users={{
                    selectedUsers: filters.users,
                    changeUsers: (val) => this.setFilters({ users: val }),
                }}
                groups={{
                    usersToExclude: filters.usersToExclude,
                    selectedGroups: filters.groups,
                    changeGroups: (groups, toExclude) => this.setFilters({ groups, usersToExclude: toExclude })
                }}
            />
        )
        return [FilterSidebar(content, intl)];
    }

    handleEscapeKey = (event: KeyboardEvent) => {
        const { selectedWorkControlEventIds } = this.state;
        if (event.key === 'Escape') {
            if (selectedWorkControlEventIds.length > 0) this.setState({ selectedWorkControlEventIds: [] });
        }
    }

    onChangeDate = (from: Moment | null, to: Moment | null, pickerType?: PickerModeType) => {
        this.setState(prevState => ({ dateFrom: from ?? prevState.dateFrom, dateTo: to ?? prevState.dateTo, pickerType }), () => this.refreshWorkControlEvents());
    }

    formatDateForGroupBy = (splitByDays: boolean) => {
        const { pickerType } = this.state;
        const defaultFormat = "YYYY-MM-DD";
        if (splitByDays) return defaultFormat;
        switch (pickerType) {
            case PickerMode.WEEK:
                return "ww";
            case PickerMode.MONTH:
                return "YYYY-MM";
            case PickerMode.YEAR:
                return "YYYY";
            default:
                return defaultFormat;
        }
    }

    refreshWorkControlEvents = (forceSplitByDays: boolean | undefined = undefined) => {
        const { intl } = this.props;
        const { dateFrom, dateTo, loading, splitByDays: splitByDaysFromState } = this.state;

        const splitByDays = forceSplitByDays !== undefined ? forceSplitByDays : splitByDaysFromState;
        if (loading) return;

        this.setState({ loading: true });

        Network.getWorkControlEvents(dateFrom, dateTo)
            .then((response) => {
                if (response.error) {
                    showNotification(intl.formatMessage({ defaultMessage: "Unable to load initial data." }), "error");
                } else {
                    const workControlEventsByDateByTypeOfDay = multiLevelGroupBy<IWorkControlEvent, 2>(response.data ?? [], [
                        (e) => moment.utc(e.startDate).format(this.formatDateForGroupBy(splitByDays)),
                        (e) => `${e.typeOfDayId}`,
                    ]);
                    this.setState({ workControlEventsByDateByTypeOfDay, loading: false, splitByDays, firstLoad: false });
                }
            })
            .catch(() => {
                showNotification(intl.formatMessage({ defaultMessage: "Unable to load initial data." }), "error");
                this.setState({ loading: false });
            });
    }

    setFilters = (filters: Partial<IFilterUsers>) => {
        this.setState((prevState) => ({ filters: { ...prevState.filters, ...filters } }));
    }

    resetFilters = () => {
        this.setState({ filters: this.initialFilters });
    }

    removeEventFromWorkControlEvents = (event: IWorkControlSmallEvent, workControlEventsByDateByTypeOfDay: WorkControlEventsByDateByTypeOfDay, saveState = true) => {
        const typeOfDayId = event.typeOfDayId;
        const startDate = moment.utc(event.startDate).format(this.formatDateForGroupBy(this.state.splitByDays))
        if (startDate in workControlEventsByDateByTypeOfDay && typeOfDayId in workControlEventsByDateByTypeOfDay[startDate]) {
            workControlEventsByDateByTypeOfDay[startDate][typeOfDayId] = workControlEventsByDateByTypeOfDay[startDate][typeOfDayId].filter(e => e.id !== event.id);
        }
        if (saveState) {
            this.setState({ workControlEventsByDateByTypeOfDay });
        }
        return workControlEventsByDateByTypeOfDay;
    }

    removeEventsFromWorkControlEvents = (events: IWorkControlSmallEvent[], workControlEventsByDateByTypeOfDay: WorkControlEventsByDateByTypeOfDay, saveState = true) => {
        events.forEach(event => {
            workControlEventsByDateByTypeOfDay = this.removeEventFromWorkControlEvents(event, workControlEventsByDateByTypeOfDay, false);
        });

        if (saveState) {
            this.setState({ workControlEventsByDateByTypeOfDay });
        }
        return workControlEventsByDateByTypeOfDay;

    }

    saveWorkControlEvent = () => {
        const { intl } = this.props;
        const { eventEditing } = this.state;
        if (eventEditing && !isNullOrEmpty(eventEditing.workRecords)) {
            this.setState({ loadingAccept: eventEditing.id });
            const workControlEventsByDateByTypeOfDay = cloneDeep(this.state.workControlEventsByDateByTypeOfDay);
            Network.editWorkControlEvent(eventEditing.id, eventEditing.workRecords.map(wr => ({ id: wr.workItemId, amount: wr.amount })))
                .then(
                    response => {
                        if (!response.error) {
                            this.setState({ workControlEventsByDateByTypeOfDay: this.removeEventFromWorkControlEvents(response.data, workControlEventsByDateByTypeOfDay, false), eventEditing: undefined });
                            showNotification(intl.formatMessage({ defaultMessage: "Successfully confirmed" }), "success");
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: "A problem has occurred during confirmation" }), "error");
                        }
                    }
                )
                .catch(
                    error => {
                        console.error(error);
                        showNotification(intl.formatMessage({ defaultMessage: "A problem has occurred during confirmation" }), "error");
                    }
                )
                .finally(() => this.setState({ loadingAccept: -1 }));
        }
    }

    acceptWorkControlEvent = (eventId: number) => {
        const { intl } = this.props;
        this.setState({ loadingAccept: eventId });
        const workControlEventsByDateByTypeOfDay = cloneDeep(this.state.workControlEventsByDateByTypeOfDay);
        Network.actionWorkControlEvent(eventId, WorkControlActions.ACCEPT)
            .then(
                response => {
                    if (!response.error) {
                        this.setState({ workControlEventsByDateByTypeOfDay: this.removeEventFromWorkControlEvents(response.data, workControlEventsByDateByTypeOfDay, false) });
                        showNotification(intl.formatMessage({ defaultMessage: "Successfully confirmed" }), "success");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: "A problem has occurred during confirmation" }), "error");
                    }
                }
            )
            .catch(
                error => {
                    console.error(error);
                    showNotification(intl.formatMessage({ defaultMessage: "A problem has occurred during confirmation" }), "error");
                }
            )
            .finally(() => this.setState({ loadingAccept: -1 }));
    }

    refuseWorkControlEvent = (eventId: number) => {
        const { intl } = this.props;
        this.setState({ loadingRefuse: eventId });
        const workControlEventsByDateByTypeOfDay = cloneDeep(this.state.workControlEventsByDateByTypeOfDay);
        Network.actionWorkControlEvent(eventId, WorkControlActions.REFUSE)
            .then(
                response => {
                    if (!response.error) {
                        this.setState({ workControlEventsByDateByTypeOfDay: this.removeEventFromWorkControlEvents(response.data, workControlEventsByDateByTypeOfDay, false) });
                        showNotification(intl.formatMessage({ defaultMessage: "Successfully rejected" }), "success");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: "A problem occurred during refusal" }), "error");
                    }
                }
            )
            .catch(
                error => {
                    console.error(error);
                    showNotification(intl.formatMessage({ defaultMessage: "A problem occurred during refusal" }), "error");
                }
            )
            .finally(() => this.setState({ loadingRefuse: -1 }));
    }

    acceptWorkControlEvents = (eventIds: number[]) => {
        const { intl } = this.props;
        this.setState({ loadingAccept: 0 });
        const workControlEventsByDateByTypeOfDay = cloneDeep(this.state.workControlEventsByDateByTypeOfDay);
        Network.actionWorkControlEvents(eventIds, WorkControlActions.ACCEPT)
            .then(
                response => {
                    if (!response.error) {
                        showNotification(intl.formatMessage({ defaultMessage: "Successfully confirmed" }), "success");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: "A problem occurred while confirming part of the events." }), "warning");
                    }

                    this.setState({
                        workControlEventsByDateByTypeOfDay: this.removeEventsFromWorkControlEvents(response.data, workControlEventsByDateByTypeOfDay, false),
                        selectedWorkControlEventIds: []
                    });

                }
            )
            .catch(
                error => {
                    console.error(error);
                    showNotification(intl.formatMessage({ defaultMessage: "A problem has occurred during confirmation" }), "error");
                }
            )
            .finally(() => this.setState({ loadingAccept: -1 }));
    }

    refuseWorkControlEvents = (eventIds: number[]) => {
        const { intl } = this.props;
        this.setState({ loadingRefuse: 0 });
        const workControlEventsByDateByTypeOfDay = cloneDeep(this.state.workControlEventsByDateByTypeOfDay);
        Network.actionWorkControlEvents(eventIds, WorkControlActions.REFUSE)
            .then(
                response => {
                    if (!response.error) {
                        showNotification(intl.formatMessage({ defaultMessage: "Successfully rejected" }), "success");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: "A problem occurred while rejecting part of the events" }), "warning");
                    }

                    this.setState({
                        workControlEventsByDateByTypeOfDay: this.removeEventsFromWorkControlEvents(response.data, workControlEventsByDateByTypeOfDay, false),
                        selectedWorkControlEventIds: []
                    });
                }
            )
            .catch(
                error => {
                    console.error(error);
                    showNotification(intl.formatMessage({ defaultMessage: "A problem occurred during refusal" }), "error");
                }
            )
            .finally(() => this.setState({ loadingRefuse: -1 }));
    }

    onChangeSelectedEvents = (keys: React.Key[]) => this.setState({ selectedWorkControlEventIds: keys as number[] });

    render() {
        const { isSmartphone, intl, typeOfDay, users, groups, height } = this.props;
        const { workControlEventsByDateByTypeOfDay, selectedWorkControlEventIds, loading, loadingAccept, loadingRefuse, pickerType, filters, splitByDays, eventEditing, firstLoad } = this.state;

        const nbSelected = selectedWorkControlEventIds.length;
        const loadingAcceptOrRefuse = loadingAccept > -1 || loadingRefuse > -1;
        const disabled = loadingAcceptOrRefuse || nbSelected === 0;

        let tableHeight = height - 135;
        if (tableHeight < 250) tableHeight = 250;

        let typeOfDayForDay: TypeOfDay[];
        let typeOfDayFound: TypeOfDay | null;
        let typeOfDayTitle: string;
        let workControlTypeOfDayColumns: ColumnsType<IWorkControlEvent>;
        let workControlDateColumn: ColumnsType<IWorkControlEvent>;

        let tabsByTypeOfDay: React.ReactNode;
        let itemsByTypeOfDay: TabsProps['items'];

        let usersInSelectedGroups: number[] = [];
        let hasAtLeastOneFilter = false;

        if (groups && filters.groups.length > 0) {
            const { groups: selectedGroups, usersToExclude } = filters;
            const userIdInSelectedGroups = selectedGroups.reduce((obj, group) => {
                if (group)
                    obj[group] = [];
                return obj;
            }, {} as DictionaryString<number[]>);
            for (const user of users)
                if (user.group_users)
                    if (user.group_users.some(g => selectedGroups.includes(g.group)))
                        for (const groupUser of user.group_users)
                            if (groupUser.group in userIdInSelectedGroups && !usersToExclude.includes(user.id))
                                userIdInSelectedGroups[groupUser.group].push(user.id);

            usersInSelectedGroups = Object.keys(userIdInSelectedGroups).reduce((userIds, groupId) => [...userIds, ...userIdInSelectedGroups[groupId]], [] as number[]);
        }

        let nbTotalEvents = 0;
        const itemsByDate: TabsProps['items'] = Object.entries(workControlEventsByDateByTypeOfDay).map(([key, workControlEventsByTypeOfDay]) => {
            typeOfDayForDay = cloneDeep(typeOfDay);
            let nbEvents = 0;
            itemsByTypeOfDay = Object.entries(workControlEventsByTypeOfDay).map(([key, workControlEvents]) => {
                if (filters.users.length > 0 || filters.groups.length > 0) {
                    workControlEvents = workControlEvents.filter(event => filters.users.includes(event.user.id) || usersInSelectedGroups.includes(event.user.id));
                    hasAtLeastOneFilter = true;
                }

                const typeOfDayId = parseInt(key);
                [typeOfDayFound, typeOfDayForDay] = findByEmptying(typeOfDayForDay, t => t.id === typeOfDayId);
                typeOfDayTitle = key;
                workControlTypeOfDayColumns = [];
                if (typeOfDayFound) {
                    if (typeOfDayFound.title) typeOfDayTitle = typeOfDayFound.title;
                    if (typeOfDayFound.workItems && typeOfDayFound.workItems.length > 0) {
                        workControlTypeOfDayColumns = typeOfDayFound.workItems.map((workItem) => {
                            return {
                                title: <FAIcon title={workItem.title} prefix={"fad"} name={workItem.icon.split(' ')[1]} />,
                                key: `workItem-${workItem.id}`,
                                className: '__width_80 __centered-text',
                                render: (event: IWorkControlEvent) => {
                                    const isEditing = eventEditing && eventEditing.id === event.id;
                                    let workRecords = isEditing ? eventEditing.workRecords : event.workRecords;
                                    let workRecordFound: IWorkControlWorkRecord | null = null;
                                    if (workRecords) {
                                        [workRecordFound, workRecords] = findByEmptying(workRecords, t => t.workItemId === workItem.id);
                                    }
                                    if (workRecordFound === null) {
                                        workRecordFound = {
                                            id: -1,
                                            workItemId: workItem.id,
                                            amount: 0,
                                        }
                                        if (isEditing && eventEditing) {
                                            eventEditing.workRecords = (eventEditing.workRecords ?? []).concat([workRecordFound]);
                                        }
                                    }
                                    return (
                                        isEditing ?
                                            <InputNumber
                                                style={{ maxWidth: 'auto', width: 'auto', minWidth: 'auto' }}
                                                size="small"
                                                min={0} max={999}
                                                value={workRecordFound?.amount ?? -1}
                                                onChange={(value) => {
                                                    if (workRecordFound) {
                                                        workRecordFound.amount = value ?? 0;
                                                        this.setState({ eventEditing });
                                                    }
                                                }}

                                            />
                                            : workRecordFound ?
                                                <p>{workRecordFound.amount}</p>
                                                : null
                                    );

                                }
                            };
                        })
                    }
                }
                workControlDateColumn = [];
                if (!splitByDays) {
                    workControlDateColumn = [{
                        title: <FormattedMessage defaultMessage={'Date'} />,
                        className: `${isSmartphone ? '__width_120' : '__width_160'}`,
                        key: 'date',
                        dataIndex: 'startDate',
                        sorter: (a: IWorkControlEvent, b: IWorkControlEvent) => moment.utc(a.startDate).diff(moment.utc(b.startDate)),
                        render: (startDate: string) => {
                            const dateFormat = isSmartphone ? getFormat('DATE_SHORT') : getFormat('DATE');
                            return moment.utc(startDate).format(dateFormat);
                        }
                    }];
                }


                const workControlEventColumns: ColumnsType<IWorkControlEvent> = [
                    {
                        title: <FormattedMessage defaultMessage={'Employee'} />,
                        key: 'employee',
                        fixed: isSmartphone ? undefined : 'left',
                        sorter: (a: IWorkControlEvent, b: IWorkControlEvent) => !a.user.lastName ? -1 : !b.user.lastName ? 1 : a.user.lastName < b.user.lastName ? -1 : 1,
                        className: isSmartphone ? '__min-width-150' : '__min-width-300',
                        render: (event: IWorkControlEvent) => (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                {isSmartphone ? null : <Avatar key={`avatar-object-${event.user.id}`} size={50} icon={<FAIcon prefix='fad' name='user' />} src={`${event.user.avatar != null ? event.user.avatar : ''}`} style={{ backgroundColor: 'var(--primary-color)' }} />}
                                <p title={`${event.user.lastName} ${event.user.firstName}`} style={{ maxWidth: `calc(${isSmartphone ? 150 : 300}px - 20px)`, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{this.props.getFullName({ ...event.user, first_name: event.user.firstName, last_name: event.user.lastName })}</p>
                            </div >
                        )
                    },
                    ...workControlTypeOfDayColumns,
                    ...workControlDateColumn,
                    {
                        title: <FormattedMessage defaultMessage={'Event'} />,
                        key: 'event',
                        sorter: (a: IWorkControlEvent, b: IWorkControlEvent) => !a.user.lastName ? -1 : !b.user.lastName ? 1 : a.user.lastName < b.user.lastName ? -1 : 1,
                        className: '__width_200',
                        render: (event: IWorkControlEvent) => (
                            <Event
                                displayNbBreaktimes
                                key={`event-${event.id}`}
                                event={{
                                    ...event,
                                    title: event.title,
                                    startDate: moment.utc(event.startDate),
                                    endDate: moment.utc(event.endDate),
                                } as PlanningEvent}
                                source={EventSource.TIMECONTROL}
                                displayIcons
                                displayTimes />
                        )
                    },

                    {
                        title: <FormattedMessage defaultMessage={'Actions'} />,
                        key: 'actions',
                        className: `${isSmartphone ? '__width_100' : '__width_180'} __centered-text`,
                        fixed: isSmartphone ? undefined : 'right',
                        render: (event: IWorkControlEvent) => {
                            const isEditing = eventEditing && eventEditing.id === event.id;
                            let dialButtons: React.ReactNode[] = [];

                            if (isEditing) {
                                dialButtons = dialButtons.concat([
                                    <CircleButton
                                        withoutTooltip={true}
                                        key={`action-circlebutton-object-${event.id}-confirm`}
                                        icon={<FAIcon prefix='fad' name='floppy-disk' />}
                                        loading={loadingAccept === event.id}
                                        disabled={loadingAcceptOrRefuse}
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Save' })}
                                        onClick={() => this.saveWorkControlEvent()}
                                    />,
                                    <CircleButton
                                        type='dashed'
                                        withoutTooltip
                                        key={`action-circlebutton-object-${event.id}-cancel`}
                                        icon={<FAIcon prefix='far' name='xmark' />}
                                        small
                                        // loading={this.state.isButtonsLoading}
                                        title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                        onClick={() => this.setState({ eventEditing: undefined })}
                                    />
                                ]);
                            } else {
                                dialButtons = dialButtons.concat([
                                    <CircleButton
                                        withoutTooltip={true}
                                        key={`action-circlebutton-object-${event.id}-confirm`}
                                        icon={<FAIcon prefix='far' name='check' />}
                                        loading={loadingAccept === event.id}
                                        disabled={loadingAcceptOrRefuse}
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Accept' })}
                                        onClick={() => this.acceptWorkControlEvent(event.id)}
                                    />,
                                    <CircleButton
                                        withoutTooltip
                                        key={`action-circlebutton-object-${event.id}-refuse`}
                                        icon={<FAIcon prefix='far' name='xmark' />}
                                        loading={loadingRefuse === event.id}
                                        disabled={loadingAcceptOrRefuse}
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Reject' })}
                                        onClick={() => this.refuseWorkControlEvent(event.id)}
                                    />,
                                    <CircleButton
                                        type='dashed'
                                        withoutTooltip
                                        // disabled={!event.clocked && !event.endDate.isBefore(moment())}
                                        key={`action-circlebutton-object-${event.id}-edit`}
                                        icon={<FAIcon prefix='fad' name='pencil' />}
                                        small
                                        // loading={this.state.isButtonsLoading}
                                        title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                        onClick={() => this.setState({ eventEditing: cloneDeep(event) })}
                                    />
                                ]);
                            }
                            return (
                                isSmartphone ?
                                    <SpeedDial
                                        title={"Plus d'actions"}
                                        icon={<MoreOutlined />}
                                        openIcon={<DashOutlined />}
                                        buttons={dialButtons} />
                                    :
                                    <Space>{dialButtons}</Space>
                            );
                        }
                    }
                ];
                const nbSubEvents = workControlEvents.length;
                nbEvents += nbSubEvents;
                const badgeTitle = `${intl.formatMessage({ defaultMessage: 'Total events : {total}' }, { total: nbSubEvents })}`;
                let bagdeColor = GREEN_COLOR;
                let countBadge: number | JSX.Element = <FAIcon prefix='fad' name='circle-check' title={hasAtLeastOneFilter ? intl.formatMessage({ defaultMessage: 'Warning: No more events to process according to the active filters' }) : ''} fontSize={20} color={hasAtLeastOneFilter ? ORANGE_COLOR : GREEN_COLOR} />;
                let dotBadge = false;

                if (nbSubEvents > 0) {
                    bagdeColor = RED_COLOR;
                    countBadge = nbSubEvents;
                    dotBadge = false;
                }
                return {
                    label: (
                        <div style={{ marginRight: '15px' }}>
                            <Badge
                                dot={dotBadge}
                                offset={nbSubEvents < 10 ? [7, -1] : nbSubEvents < 100 ? [11, -1] : [16, -1]}
                                count={countBadge}
                                color={bagdeColor}
                                title={badgeTitle}
                                overflowCount={999}
                            >
                                <p>{typeOfDayTitle}</p>
                            </Badge>
                        </div>
                    ),
                    key: `wc-tod-${key}`,
                    children: (
                        <div>
                            <VirtualTable
                                rowClassName={(record: IWorkControlEvent) => {
                                    return eventEditing && record.id !== eventEditing.id ? 'disabled-row' : '';
                                }}
                                className='__basic-table'
                                size='small'
                                rowKey={(i: IWorkControlEvent) => i.id}
                                rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedEvents, selectedRowKeys: selectedWorkControlEventIds }}
                                dataSource={workControlEvents}
                                scroll={{ x: true, y: tableHeight }}
                                columns={workControlEventColumns}
                                pagination={false}
                            />
                            <ActionsToolbar
                                title={<FormattedMessage defaultMessage={'{count, plural, one {1 event} other {{count} events}}'} values={{ count: nbSelected }} />}
                                open={nbSelected > 0}
                                close={() => this.setState({ selectedWorkControlEventIds: [] })}
                                actions={
                                    <>
                                        <Popconfirm
                                            disabled={disabled}
                                            title={<FormattedMessage defaultMessage={'{count, plural, one {1 event is about to be accepted. Would you like to continue?} other {{count} events are about to be accepted. Would you like to continue?}}'} values={{ count: nbSelected }} />}
                                            okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                            icon={<FAIcon prefix={'fad'} name="question" />}
                                            placement={"top"}
                                            onConfirm={() => this.acceptWorkControlEvents(selectedWorkControlEventIds)}
                                        >
                                            <CircleButton
                                                small
                                                title={intl.formatMessage({ defaultMessage: 'Accept ({count})' }, { count: nbSelected })}
                                                loading={loadingAccept === 0}
                                                disabled={disabled}
                                                icon={<FAIcon prefix='far' name='check' />}
                                            />
                                        </Popconfirm>
                                        <Popconfirm
                                            disabled={disabled}
                                            key="eventclocked-popup-button-reject"
                                            title={<FormattedMessage defaultMessage={'{count, plural, one {1 event is about to be rejected. Would you like to continue?} other {{count} events are about to be rejected. Would you like to continue?}}'} values={{ count: nbSelected }} />}
                                            okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                            icon={<FAIcon prefix={'fad'} name="question" />}
                                            placement={"top"}
                                            onConfirm={() => this.refuseWorkControlEvents(selectedWorkControlEventIds)}
                                        >
                                            <CircleButton
                                                small
                                                title={intl.formatMessage({ defaultMessage: 'Reject ({count})' }, { count: nbSelected })}
                                                loading={loadingRefuse === 0}
                                                disabled={disabled}
                                                icon={<FAIcon prefix='far' name='xmark' />}
                                            />
                                        </Popconfirm>
                                    </>
                                }
                            />
                        </div >
                    ),
                }
            });

            const day = moment(key);
            const badgeTitle = `${intl.formatMessage({ defaultMessage: 'Total events : {total}' }, { total: nbEvents })}`;
            let countBadge: number | JSX.Element = <FAIcon prefix='fad' name='circle-check' title={hasAtLeastOneFilter ? intl.formatMessage({ defaultMessage: 'Warning: No more events to process according to the active filters' }) : ''} fontSize={20} color={hasAtLeastOneFilter ? ORANGE_COLOR : GREEN_COLOR} />;
            let bagdeColor = GREEN_COLOR;
            let dotBadge = false;
            nbTotalEvents += nbEvents
            if (nbEvents > 0) {
                bagdeColor = RED_COLOR;
                countBadge = nbEvents;
                dotBadge = false;
            }

            tabsByTypeOfDay = (
                <Tabs
                    tabBarExtraContent={{
                        left: (
                            splitByDays ?
                                null
                                :
                                <FAIcon title={intl.formatMessage({ defaultMessage: 'Group by day' })} size='sm' prefix='fad' name='chevrons-right' style={{ cursor: 'pointer' }} onClick={() => this.refreshWorkControlEvents(true)} />
                        )
                    }}
                    onChange={() => this.setState({ selectedWorkControlEventIds: [] })}
                    className='vertical-tab-full-width children'
                    tabPosition={"left"}
                    items={itemsByTypeOfDay}
                />
            )

            return {
                label: (
                    <div style={{ marginRight: '15px' }}>
                        <Badge
                            dot={dotBadge}
                            offset={nbEvents < 10 ? [7, -1] : nbEvents < 100 ? [11, -1] : [16, -1]}
                            count={countBadge}
                            color={bagdeColor}
                            title={badgeTitle}
                            overflowCount={999}
                        >
                            <div style={{ display: 'inline-grid', gridTemplateColumns: '40px 1fr', gridColumnGap: '3px' }}>
                                <p style={{ marginTop: 'auto', marginBottom: 'auto', textTransform: 'capitalize' }}>{day.format(getFormat('DAY_MEDIUM'))}</p>
                                <p>{day.format(getFormat('DAY_SHORT'))}</p>
                            </div>
                        </Badge>
                    </div>
                ),
                key: `wc-date-${key}`,
                children: tabsByTypeOfDay,
            }
        });
        return (
            <Spin spinning={loading} size="large" indicator={< FAIcon prefix='fas' name='spinner-third' spin />} wrapperClassName={"container-tabs-spinner-content"}>
                {
                    firstLoad ?
                        null
                        : itemsByDate.length === 0 || nbTotalEvents === 0 ?
                            <WorkDone
                                subTitle={<FormattedMessage defaultMessage={'All the work performed for the selected period has been validated.'} />}
                                extra={[
                                    <Button type="primary" key={'update-data'} onClick={() => this.refreshWorkControlEvents()}>
                                        <FormattedMessage defaultMessage={'Refresh'} />
                                    </Button>,
                                ]}
                            />
                            : itemsByDate.length === 1 && (pickerType === undefined || !splitByDays) ?
                                tabsByTypeOfDay
                                :
                                <Tabs
                                    tabBarExtraContent={{
                                        left: (
                                            splitByDays ?
                                                <FAIcon title={intl.formatMessage({ defaultMessage: 'Show all data' })} size='sm' prefix='fad' name='chevrons-left' style={{ cursor: 'pointer' }} onClick={() => this.refreshWorkControlEvents(false)} />
                                                : null
                                        )
                                    }}
                                    onChange={() => this.setState({ selectedWorkControlEventIds: [] })}
                                    className='vertical-tab-full-width parent'
                                    tabPosition={"left"}
                                    items={itemsByDate}
                                />
                }
            </Spin >
        )






    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    isSmartphone: state.window.isSmartphone,
    height: state.window.height,
    typeOfDay: state.configurations.typesOfDay
});


const connector = connect(mapStateToProps)

export default withRouter(connector(injectIntl(withFullName(ProductivityTracking))));